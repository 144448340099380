import { Component, Input,EventEmitter,Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  standalone: true,
  imports: [CommonModule,FormsModule],
  selector: 'app-custom-input-password-login',
  templateUrl: './custom-input-password-login.component.html',
  styleUrls: ['./custom-input-password-login.component.scss']
})
export class CustomInputPasswordLoginComponent {
  @Input() placeholderText: string = '';
  @Input() label: string = '';
  @Input() width: string = 'auto'; 
  @Input() value: string = '';
  @Output() valueChange = new EventEmitter<string>();
  @Input() isErrorCustomInputLogin: boolean = false; 
  @Input() errorMessage: string = '';

  onValueChange(value: string) {
    this.value = value;
    this.valueChange.emit(this.value);
  }
}
