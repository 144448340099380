
<app-header [headerText]="'Usuarios'"  [showImage]="false"></app-header>

<div class="layout-container">
  <div class="contenedor-filtros">
    <div class="form-group filtro-3">
      <label class="titulo-filtro">Nombre</label>
      <app-custom-input [(value)]="nameUser" [maxlength]="max"></app-custom-input>
    </div>

    <app-custom-button-color 
    text="limpiar" (action)="clear()"></app-custom-button-color>
    <app-custom-button
    text="buscar" (action)="seach()"></app-custom-button>

    <div class="filtro-inputs">
    
    </div>
    <div class="filtro-botones">
      
    </div>
  </div>

  <div class="contenedor-filtros">
    <div class="filtro-inputs">
      <div class="resultados">
        <!-- <label for="" class="label-resultado">Resultado de Búsqueda</label> -->
      </div> 
    </div>
    <div class="filtro-botones">
      <div class="btn-add-usuario">
        <app-custom-button text="AGREGAR USUARIO"  iconUrl="assets/svg/add-user.svg"  (action)="addUsuario('add')"></app-custom-button>
      </div>
    </div>  
  </div>

  <div class="tabla-responsive">
    <app-custom-table-paginator [columnNames]="columnNames" [data]="usersData" actionButtonText="VER" (onEditRow)="editUser($event)"></app-custom-table-paginator>
  </div>

</div>

<app-footer></app-footer>

<loading *ngIf="loading"></loading>


