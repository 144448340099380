<div class="custom-input-container">
  <input #myInput [type]="type" class="custom-input readonly" 
       [placeholder]="placeholderText" 
       [(ngModel)]="value" 
       [required]="isRequired" 
       [disabled]="isDisabled"
       [readonly]="isReadonly"
       [ngClass]="{'error': isError, 'readonly': isReadonly}"
       [maxlength]="maxlength"  
       [minlength]="minlength"
       (ngModelChange)="onValueChange($event)" 
       oninput="this.type == 'tel' ? this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1') : ''"
       >
  <img *ngIf="isError" src="assets/alerta.svg" class="alert-icon"  />

</div>
