import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { TokenModel } from "../models/tokenModel";
import { AppConstants } from "app/shared/constants/app-constants";

@Injectable({
    providedIn: 'root'
  })
  export class AuthService {
  
    constructor(
      private httpClient: HttpClient,
      private router: Router
    ) { }
  
    getAuthTokenAsync(username: string, password: string, token: string): Observable<TokenModel> {
        const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
        })
        };

        return this.httpClient.post<TokenModel>(environment.identityApiEndpoint,
        `client_id=apibanbifpva&grant_type=mysqldb&username=${username}&password=${password}&secret=${token}`,
        httpOptions);
    }
    
    logout() {
      sessionStorage.clear();
      this.router.navigate(['/login'], { queryParams: { returnUrl: '' } });
    }

    getUserProfile(): string | null {
      return sessionStorage.getItem(AppConstants.Session.USERROLID);
    }
  }
  