import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from '../../components/loading/loading.component';
import { AccountNumberPipe } from 'app/pipes/account-number-pipe';
import { DateFormatPipe } from 'app/pipes/dateformat-pipe';


@NgModule({
  declarations: [
    LoadingComponent,
    AccountNumberPipe,
    DateFormatPipe
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    LoadingComponent,
    AccountNumberPipe,
    DateFormatPipe
  ]
})
export class SharedModule { }
