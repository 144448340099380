import { Component, Input, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule],
  selector: 'app-custom-input-switch',
  templateUrl: './custom-input-switch.component.html',
  styleUrls: ['./custom-input-switch.component.scss']
})
export class CustomInputSwitchComponent {

  isChecked = false;

  toggleSwitch() {
    this.isChecked = !this.isChecked;
   
  }
}
