import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = "customer";
  private BASE_URL: string = environment.customerApiEndpoint;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  postAdmissionCustomer(model: any): Observable<any> {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/validation/policies/admission/execution`, model);
  }

  postRetrieveId(model: any): Observable<any> {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/customer-id/retrieve`, model);
  }

  postCustomerInformationLegacyRetrieve(model: any): Observable<any> {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/customer/legacy/v2/retrieve`, model);
  }

  postNoCustomerInformationRetrieve(model: any): Observable<any> {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/no-customer/retrieve`, model);
  }

  postAdmissionCustomerTransitory(model: any): Observable<any> {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/validation/policies/admission/transitory/execution`, model);
  }

}
