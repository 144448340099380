import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminUsuariosAccionesRoutingModule } from './admin-usuarios-acciones-routing.module';
import { CustomTablePaginatorComponent,CustomButtonComponent, CustomInputComponent,CustomButtonColorComponent, 
  HeaderComponent,CustomInpuntSelectComponent,CustomTableSimulatorComponent,FooterComponent,LineDividerComponent,StepperComponent, InfoModalComponent, CustomDatepickerComponent, CustomInputLoginComponent, 
  CustomInputFlexComponent,
  CustomInputSwitchComponent,
  CustomInputPasswordComponent} from 'app/components';
import { CustomButtonCircleComponent } from 'app/components/custom-button-circle/custom-button-circle.component';
import { AdminUsuariosAccionesComponent } from './admin-usuarios-acciones.component';
import { SharedModule } from 'app/shared/modules/shared/shared.module';

@NgModule({
  declarations: [AdminUsuariosAccionesComponent],
  imports: [
    CommonModule,
    AdminUsuariosAccionesRoutingModule,
    CustomTablePaginatorComponent,
    CustomButtonComponent,
    CustomButtonColorComponent,
    CustomInpuntSelectComponent,
    HeaderComponent,
    FooterComponent,
    CustomInputComponent,
    CustomTableSimulatorComponent,
    LineDividerComponent,
    StepperComponent,
    CustomButtonCircleComponent,
    InfoModalComponent,
    CustomDatepickerComponent,
    CustomInputLoginComponent,
    CustomInputFlexComponent,
    CustomInputSwitchComponent,
    CustomInputPasswordComponent,
    SharedModule
  ]
})
export class AdminUsuariosAccionesModule { }
