<div class="modal-overlay" (click)="onOverlayClick()">
  <div class="modal-content" [ngStyle]="{'width.px': width, 'height.px': height}" (click)="onModalContentClick($event)" @fadeInUp>
    <div class="modal-header">
      <img *ngIf="imagenUrl" [src]="imagenUrl" alt="Imagen">
      <h2>{{ titulo }}</h2>
    </div>
    <div class="modal-body">
      <ng-content></ng-content>
    </div>
  </div>
</div>
