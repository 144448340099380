<div class="header-container">
  <div class="header-body">
    <img *ngIf="showImage" [src]="headerImageSrc" alt="Registro" class="header-image">
    <span class="header-text">{{ headerText }}</span>
  </div>
  <div class="header-line">
    <img src="assets/line.png" alt="Linea" class="header-image-line">
  </div>

</div>
