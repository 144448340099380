import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomButtonColorComponent } from '../custom-button-color';
import { CustomButtonComponent } from '../custom-button';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [CommonModule, CustomButtonColorComponent, CustomButtonComponent],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  @Input() width: number = 600;
  @Input() height: number = 400; 
  @Output() onClose = new EventEmitter<void>(); // Emite un evento cuando el modal debe cerrarse
  @Input() imageUrl: string ="";
  @Input() titleText: string="";      
  @Input() subtitleText: string="";   
  @Input() paragraphText: string="";
  @Input() linkText: string="";
  @Input() showCustomButtons: boolean = false;
  @Output() linkAction = new EventEmitter<void>();
  @Output() onSave = new EventEmitter<void>(); 

  close() {
    this.onClose.emit(); // Emite el evento onClose cuando se llama al método close
  }

  onLinkClick() {
    this.linkAction.emit();
  }

  save() {
    this.onSave.emit();
  }
}
