import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApplicationService } from 'app/services/application.service';
import { ParameterService } from 'app/services/parameter.service';
import { AppConstants } from 'app/shared/constants/app-constants';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';
import { environment } from 'environments/environment';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-validacion-facial',
  templateUrl: './validacion-facial.component.html',
  styleUrl: './validacion-facial.component.scss'
})
export class ValidacionFacialComponent {

  @Input() cellphoneInput: string = '';

  applicationId: string = sessionStorage.getItem(AppConstants.Session.APPLICATION_ID) ?? '';
  usuario: string = sessionStorage.getItem(AppConstants.Session.USERID) ?? '';
  application: any = {};

  cellphone: string = '';
  loading: boolean = false;

  @Output() validacionCompletada = new EventEmitter<void>();
  showValidacionCompletada = false;

  typeInputNumber: string = 'tel';
  max: number = 9;

  isErrorCellphone: boolean = false;
  validationIncomplete = true;
  validationInProgress = false;
  validationCompleted = false;

  siteKey = environment.reCaptchaKey;
  recaptchaLoaded: boolean = false;
  validationSpouse: boolean = false;
  cellphoneSpouse: string = '';
  isErrorCellphoneSpouse: boolean = false;

  matrizValidationSpouse: any = [];
  
  constructor(
    private applicationService: ApplicationService,
    private alertService: AlertService,
    private parameterService: ParameterService) 
  {  
  }

  async ngOnInit() {
    this.loadRecaptchaScript();
    await this.getParameters([
      AppConstants.ParameterCode.SCORE_AUTENTICATION_SPOUSE,
    ]);
    await this.retrieveApplication(this.applicationId);
    // await this.checkStatusAuthentication();
    await this.retriveBiometricNotification(this.applicationId);
  }

  resetErrorState() {
    this.isErrorCellphone = this.cellphone === null ? true : 
                            this.cellphone === undefined ? true : 
                            this.cellphone === '' ? true : 
                            this.cellphone.length === this.max ? false : true;

    this.isErrorCellphoneSpouse = this.cellphoneSpouse === null ? true : 
                            this.cellphoneSpouse === undefined ? true : 
                            this.cellphoneSpouse === '' ? true : 
                            this.cellphoneSpouse.length === this.max ? false : true;
  }

  enviarValidacion() {
    this.showSections(false, false, true);
    this.validacionCompletada.emit();   
  }

  async sendAuthFace() {
    this.resetErrorState();

    const requiredElements = [
      this.isErrorCellphone
    ];

    const requiredElementsConditional = [
      this.isErrorCellphoneSpouse,
    ];

    if (requiredElements.every(element => !element) 
      && (this.validationSpouse ? requiredElementsConditional.every(element => !element) : true)) {
      this.executeRecaptchaLogin();
    } else {
      this.alertService.showMessage("", AppConstants.MessageAlert.ERROR_FORM_REQUIRED, MessageSeverity.error);
    }    
  }

  async retrySend() {
    this.executeRecaptchaLogin();
  }

  changeNumber() {
    this.showSections(true, false, false);
  }

  showSections(incomplete : boolean, inprogress: boolean, completed: boolean) {
    this.validationIncomplete = incomplete;
    this.validationInProgress = inprogress;
    this.validationCompleted = completed;
  }

  async retriveBiometricNotification(applicationId: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getBiometricsNotification(applicationId)).then(async (response: any) => {      
      this.loading = false;
      if(response.length > 0) {
        this.showSections(false, true, false);
        await this.checkStatusAuthentication();
      }else {
        this.showSections(true, false, false);
      }
    }).catch((error) => {
      this.loading = false;
      this.alertService.showMessage(AppConstants.TitleAlert.CONSULT_NOTIFICATION_TITLE, error.error.message, MessageSeverity.error);
      console.error(error);
    });  
  }

  async retrieveApplication(applicationId: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getApplication(applicationId)).then((response: any) => {      
      this.application  = response;
      this.cellphone = this.application.personalCellPhoneNumber;
      this.validateAutenticationSpouse();
      this.loading = false;
    }).catch((error) => {
      this.loading = false;
      // this.alertService.showMessage(AppConstants.TitleAlert.RETRIEVE_APPLICATION_TITLE, error.error.message, MessageSeverity.error);
      console.error(error);
    });  
  }

  async checkStatusAuthentication() {
    this.loading = true;

    let model = {
      applicationId: this.applicationId,
      documentType: this.application.documentType,
      documentNumber: this.application.documentNumber,
      userId: this.usuario
    };

    await lastValueFrom(this.applicationService.postCheckStatusAuthentication(model)).then(async (response: any) => {      
      this.loading = false;
      if(response.authenticatedCustomer === false) {
        this.alertService.showMessage(AppConstants.TitleAlert.PROCESS_AUTHENTICATION_TITLE, AppConstants.MessageAlert.CONSULT_AUTHENTICATION_TITLE, MessageSeverity.warn, 6000);
        this.showSections(false, true, false);
        // await this.retriveBiometricNotification(this.applicationId);
      } else {
        this.enviarValidacion();
      }
    }).catch((error) => {
      this.loading = false;
      this.alertService.showMessage(AppConstants.TitleAlert.CONSULT_AUTHENTICATION_TITLE, error.error.message, MessageSeverity.error);
      console.error(error);
    });  
  }

  async sendFaceNotification(token: string) {
    let model = {
      applicationId : this.applicationId,
      cellPhoneNumber: this.cellphone,
      secret: token,
      validateSpouse: this.validationSpouse,
      cellPhoneNumberSpouse: this.cellphoneSpouse
    };
    this.loading = true;
    await lastValueFrom(this.applicationService.postFaceAuthentication(model)).then((response: any) => {
      if(response.codigo === AppConstants.StatusZytrus.Success) {
        this.showSections(false, true, false);
        this.alertService.showMessage(AppConstants.TitleAlert.NOTIFICATION_TITLE, AppConstants.MessageAlert.NOTIFICATION_SUCCES, MessageSeverity.success);
      }
      else {
        this.alertService.showMessage(AppConstants.TitleAlert.NOTIFICATION_TITLE, response.error, MessageSeverity.error);  
      }
      this.loading = false;        
    }).catch((error) => {
      this.loading = false;
      this.alertService.showMessage(AppConstants.TitleAlert.NOTIFICATION_TITLE, error.error.message, MessageSeverity.error);
      console.error(error);
    });
  }

  loadRecaptchaScript(): void {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${this.siteKey}`;
    script.async = true;
    script.defer = true;
    script.onload = () => {
      this.recaptchaLoaded = true;
    };
    script.onerror = (error) => {
      console.error('Error al cargar el script de reCAPTCHA Enterprise', error);
      this.alertService.showMessage(AppConstants.TitleAlert.LOGIN_TITLE, AppConstants.MessageAlert.ERROR_GOOGLE_RECAPTCHA_ENTERPRISE, MessageSeverity.error);
    };
    document.head.appendChild(script);
  }

  executeRecaptchaLogin(): void {
    if (this.recaptchaLoaded && window.grecaptcha && window.grecaptcha.enterprise) {
      window.grecaptcha.enterprise.execute(this.siteKey, { action: AppConstants.RecaptchaEnterprise.Authface })
        .then((token: string) => {
          this.sendFaceNotification(token);
        });
    } else {
      this.alertService.showMessage(AppConstants.TitleAlert.LOGIN_TITLE, AppConstants.MessageAlert.ERROR_GOOGLE_RECAPTCHA_ENTERPRISE_OFF, MessageSeverity.error);
      console.error('reCAPTCHA Enterprise no está disponible.');
    }
  }  

  validateAutenticationSpouse() {
    this.validationSpouse = (this.application.maritalStatus === AppConstants.MaritalStatus.MARRIED &&
                              (this.matrizValidationSpouse
                              .filter((f: any) => f.parameterDetCode001 === AppConstants.Products.Loans && 
                                                  f.parameterDetCode002 === AppConstants.SubProducts.PLD_BCO &&
                                                  f.parameterDetCode003 <= this.application.applicationAmount &&
                                                  Array.isArray(f.parameterDetCode007) && f.parameterDetCode007.includes(this.application.score))).length > 0) ? true : false;
  }

  async getParameters(array: Array<number>) {
    this.loading = true;
    await lastValueFrom(this.parameterService.getParametersList(array)).then((response: any) => {
      this.setVariablesParameters(response);
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  setVariablesParameters(listParameters: any[]) {
    this.matrizValidationSpouse = listParameters
        .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.SCORE_AUTENTICATION_SPOUSE && f.state === AppConstants.StateFlag.Active);

    this.matrizValidationSpouse.forEach((element: any) => {
      let array = element?.parameterDetCode007?.split(',')?.map(Number)?.filter((num: any) => !isNaN(num));
      element.parameterDetCode007 = array;
    });
  }

}
