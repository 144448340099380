import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { AppConstants } from "../shared/constants/app-constants";
import { Injectable } from "@angular/core";
import { AuthService } from "app/services/auth.service";

@Injectable()
export class ProfileGuard {
  constructor(
    private router: Router,
    private authService: AuthService
) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    const pathProfiles = next.data[AppConstants.GuardMetaData.PROFILES];
    const userProfile = this.authService.getUserProfile();

    if (userProfile && pathProfiles?.includes(userProfile)) {
        return true;
    } else {
        this.router.navigate(['/access-denied']);
        return false;
    }

  }
}