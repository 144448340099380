import { trigger, transition, style, animate } from '@angular/animations';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomTableSimulatorComponent } from 'app/components';
import { LoanSimulationRequest } from 'app/models/loanSimulationRequest';
import { ClienteSession, ProductoSession } from 'app/models/sessionModel';
import { LoansimualtionService } from 'app/services/loansimualtion.service';
import { OptionalInsurancesService } from 'app/services/optional-insurances.service';
import { ParameterService } from 'app/services/parameter.service';
import { AppConstants } from 'app/shared/constants/app-constants';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';
import { lastValueFrom, forkJoin } from 'rxjs';

@Component({
  selector: 'app-simulador-oferta-pld-banco',
  templateUrl: './simulador-oferta-pld-banco.component.html',
  styleUrls: ['./simulador-oferta-pld-banco.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class SimuladorOfertaPldBancoComponent implements OnInit {
  @ViewChild('myTable', { static: false }) tableComponent!: CustomTableSimulatorComponent;
  simulationCount: number = 1;
  listaAbierta: boolean = false;

  client: ClienteSession = JSON.parse(sessionStorage.getItem(AppConstants.Session.CLIENT) ?? '{}');
  product: ProductoSession = JSON.parse(sessionStorage.getItem(AppConstants.Session.PRODUCT) ?? '{}');
  offers: any[] = JSON.parse(sessionStorage.getItem(AppConstants.Session.OFFERS) ?? '[]');
  dataCustomer: any = JSON.parse(sessionStorage.getItem(AppConstants.Session.DATA_CUSTOMER) ?? '{}');
  dataNoCustomer: any = JSON.parse(sessionStorage.getItem(AppConstants.Session.DATA_NO_CUSTOMER) ?? '{}');

  constructor(
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private loansimualtionService: LoansimualtionService,
    private optionalInsurancesService: OptionalInsurancesService,
    private alertService: AlertService,
    private router: Router,
    private parameterService: ParameterService,
  )  {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    this.disburmentDate = `${year}-${month}-${day}`;
    const data = Array.from({ length: this.product.installmentsMax - this.product.installments + 1 }, (_, i) => (i + this.product.installments).toString());
    this.options = data;
  }

  async ngOnInit() {

    let model = {
      insuranceType: "OPT",
      insuranceTax: "U",
      loanProduct: "PTMN",
      loanPath: "PCPM"
    };

    await this.obtenerSegurosOptativos(model);
    await this.getParameters([
      AppConstants.ParameterCode.PAY_DAYS,
      AppConstants.ParameterCode.DESGRAVAMEN_TYPE,
      AppConstants.ParameterCode.NUMBER_INSTALLMENTS,
    ]);    
  }

  ngAfterViewInit() {
    // Detecta los cambios después de la vista
    this.cdr.detectChanges();
  }
  
  private nextColumnToFill = 0;

  elementos: any = [];  
  diaPagoOptions: any = [];
  tipoDesgravameOptions: any = [];
  numeroCuotasOptions: any = [];
  arraySegurosOptativos: any = [];
  isSearchDisabled: boolean = true;
  
  loading: boolean = false;
  desgravamen: string = "005";
  disburmentDate!: string;
  firstExpiration!: string;
  typeInputNumber: string = 'tel';

  
  montoValue: string = this.product.amount+'';
  gracePerido: string = "0";
  paymentDay: string = "3";
  quotas: string = this.product.installments+'';
  clientMaritalStatus: string = "";
  clientAge: string = '0';
  amountMin = 1500;

  Simulado: boolean = false;

  arraySegurosOptativosSelecionados: any = [];

  options: string[] = [];
  optionalInsuranceResponse: any = {};

  onComponentReady() {
    this.fillNextColumn();
  }

  fillNextColumn(event?: Event): void {
    if (event) {
      event.preventDefault();
    }
    this.showSimulationTable();

    if (this.tableComponent) {
      
      const today = new Date();
      const day = String(today.getDate()).padStart(2, '0');
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const year = today.getFullYear();

      let model = new LoanSimulationRequest();
          model.applicationAmmount = parseFloat(this.montoValue).toFixed(2);
          model.gracePeriod = +this.gracePerido;
          model.paymentDay = +this.paymentDay;
          model.applicationQuota = +this.quotas;
          model.desgravamenCode = this.desgravamen;
          model.disbursementDate = +this.disburmentDate.replaceAll("-","");
          model.currency = this.product.currency;
          model.interes = parseFloat((+this.product.tea).toString()).toFixed(6);
          model.applicationDate = +`${year}${month}${day}`;
          model.productCode = this.product.offerProductCode;
          model.subProductCode = this.product.offerSubProductCode;

      if(model.productCode === AppConstants.SubProducts.PLD_DRACMA) {
        model.unemploymentInsurance = 0;
        model.lifeInsurance = 0;
        this.orquestacionSimulador(model);
      } else {
        console.log(this.arraySegurosOptativosSelecionados);

        var lifeInsuranceValue = 0;        
        this.arraySegurosOptativosSelecionados.forEach((element: any) => {
          switch (element.code) {
            case AppConstants.OptionalInsuracesCode.UNEMPLOYMENT_CHUBB:
              model.unemploymentInsurance = element.amountBruto;
              break;
            case AppConstants.OptionalInsuracesCode.HEALTH_PLUS:
              model.healthInsurance = element.amountBruto;
              break;
            case AppConstants.OptionalInsuracesCode.LIFE_MAPFRE:
              lifeInsuranceValue = element.amountBruto;
              break;
            default:
              break;
          }
        });
        model.lifeInsurance = lifeInsuranceValue;        
        const optionalInsurances = this.arraySegurosOptativosSelecionados?.reduce((acc: number, s: any) => acc + s.amountBruto, 0);
        model.applicationAmmount = parseFloat(+this.montoValue + optionalInsurances).toFixed(2);
        model.interes = this.arraySegurosOptativosSelecionados.length > 0 ? parseFloat((+this.product.priority).toString()).toFixed(6) : parseFloat((+this.product.tea).toString()).toFixed(6);
        this.callSimulationPLDBanco(model);
      }

    } else {
      console.error('Table component is not available yet.');
    }
  }

  simularPrestamo(model : LoanSimulationRequest, opcionalInsurances: any): void {
    this.loading = true;
    this.loansimualtionService.postGetSimulacion(model).subscribe({
      next: async (response: any) => {
        this.firstExpiration = response.firstExpiration;
        this.tableComponent.addSimulation(response,this.nextColumnToFill, opcionalInsurances);        
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.SIMULATION_TITLE, AppConstants.MessageAlert.SIMULATION_SUCCESS, MessageSeverity.success);
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.SIMULATION_TITLE, AppConstants.MessageAlert.SIMULATION_ERROR, MessageSeverity.error);
        console.log(error);
      }
    });
  }

  async obtenerSegurosOptativos(model: any)  {
    this.arraySegurosOptativos = [];
    this.loading = true;
    await lastValueFrom(this.optionalInsurancesService.postObtenerSegurosOptativos(model)).then((response: any) => {
      this.optionalInsuranceResponse = response;
      this.createOptionalInsurances(this.optionalInsuranceResponse);
      // const customer = this.validateOptionalInsurancesPLDBanco();
      // response.optionalInsurances.forEach((element: any) => {

      //   if(element.insuranceCode === AppConstants.OptionalInsuracesCode.UNEMPLOYMENT_CHUBB) {
      //     if(customer?.age >= 70) {
      //       if(customer?.age === 70 && customer?.days <= 354) {              
      //       }
      //       else {
      //         return;
      //       }
      //     }
      //   }

      //   if(element.insuranceCode === AppConstants.OptionalInsuracesCode.HEALTH_PLUS) {
      //     if(customer?.age >= 65) {
      //       if(customer?.age === 65 && customer?.days <= 364) {              
      //       }
      //       else {
      //         return;
      //       }
      //     }
      //   }

      //   let arrayPlanes: any = [
      //     {
      //       label: 'SIN SEGURO.',
      //       value: null,
      //       type: element.insuranceCode, 
      //       installments: null
      //     }
      //   ];

      //   let arrayInstallments: any = [];
      //   let groupedData: any = [];
      //   let groupsPlan: any = [];

      //   element.plans.forEach((plan: any) => {
      //     arrayInstallments.push({ 
      //       label: plan.letter,
      //       name: plan.planName,
      //       nameFormat: plan.termFormat,
      //       value: plan.planCode, 
      //       type: element.insuranceCode,
      //       letter: plan.letter,
      //       coverage: plan.coverage,
      //       endosable: element.flagEndosable,
      //       amountBruto: plan.amountBruto,
      //       amountNeto: plan.amountNeto,
      //       currency: plan.currency
      //     });
      //   });

      //   arrayInstallments.forEach((item: any) => {
      //     if(!groupedData[item.letter]) {
      //       groupedData[item.letter] = [];
      //     }
      //     groupedData[item.letter].push(item);
      //   });

      //   groupsPlan = Object.keys(groupedData).map(plan => ({
      //     label: plan,
      //     installments: groupedData[plan]
      //   }));

      //   groupsPlan.forEach((group: any) => {
      //     arrayPlanes.push({ 
      //       label: group.label,
      //       value: group.label,
      //       type: element.insuranceCode, 
      //       installments: group.installments,
      //       subOptions: null,
      //       selectedValue: null
      //     });          
      //   });        

      //   this.arraySegurosOptativos.push({
      //     label: element.insuranceName,
      //     code: element.insuranceCode,
      //     options: arrayPlanes,
      //     disabled: true,
      //     subOptions: [],
      //     selectecSubOption: null,
      //     selectedValue: null
      //   });

      // });
      this.loading = false;
      this.alertService.showMessage(AppConstants.TitleAlert.OPTIONAL_INSURENCES_TITLE, AppConstants.MessageAlert.OPTIONAL_INSURANCE_RETRIEVE_SUCCESS, MessageSeverity.success);
    },
    (error) => {
      this.loading = false;
      console.error(error);
      this.alertService.showMessage(AppConstants.TitleAlert.OPTIONAL_INSURENCES_TITLE, AppConstants.MessageAlert.OPTIONAL_INSURANCE_RETRIEVE_ERROR, MessageSeverity.error);
    });
  }

  onElementoChange(element: any) {
    const index = this.arraySegurosOptativosSelecionados.findIndex((e: any) => e.code === element.code);
    this.clearSimulation();

    if(index != -1 || element.selectedValue === 'null'){
      this.arraySegurosOptativosSelecionados.splice(index, 1);      
    }

    if(element.selectedValue != 'null'){
      this.showNotificationOptionalInsurances(element);
      let plan = element.options.find((p:  any) => p.type === element.code && p.value === element.selectedValue);
      this.arraySegurosOptativosSelecionados.push(
        {
          code: element.code,
          description: element.label,
          plan: element.selectedValue,
          planName: plan.label,
          endosable: plan.endosable        
        }
      );
    }
  }

  calcularPrimaSeguroOptativo(model: any): void {
    this.loading = true;
    this.optionalInsurancesService.postCalcularaPrimaSeguroOptativo(model).subscribe({
      next: async (response: any) => {
        this.loading = false;
        // this.alertService.showMessage(AppConstants.TitleAlert.OPTIONAL_INSURENCES_TITLE, AppConstants.MessageAlert.SIMULATION_SUCCESS, MessageSeverity.success);
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.OPTIONAL_INSURENCES_TITLE, AppConstants.MessageAlert.OPTIONAL_INSURANCE_CALCULATION, MessageSeverity.error);
        console.error(error);
      }
    });
  }

  orquestacionSimulador(model: LoanSimulationRequest): void {

    /*validacion*/
    if(this.quotas.length == 0) {
      this.alertService.showMessage(AppConstants.TitleAlert.SIMULATION_TITLE, `${AppConstants.MessageAlert.SIMULATION_NOT_QUOTA_ERROR}`, MessageSeverity.error, 5000);
      return;
    }
    
    if (!(+this.quotas >= this.product.installments && +this.quotas <= this.product.installmentsMax))  {
      this.alertService.showMessage(AppConstants.TitleAlert.SIMULATION_TITLE, `Las cuotas permitidas son de ${this.product.installments} a ${this.product.installmentsMax} meses.`, MessageSeverity.error, 5000);
      return;
    }
    if(+this.montoValue < this.amountMin) {
      this.alertService.showMessage(AppConstants.TitleAlert.SIMULATION_TITLE, `${AppConstants.MessageAlert.SIMULATION_VALIDATOR_AMOUNT_MIN_ERROR} ${this.product.currencySymbol} ${this.amountMin}`, MessageSeverity.warn, 5000);
      return;
    }
    if(+this.montoValue > this.product.amount) {
      this.alertService.showMessage(AppConstants.TitleAlert.SIMULATION_TITLE, `${AppConstants.MessageAlert.SIMULATION_VALIDATOR_AMOUNT_MAX_ERROR} ${this.product.currencySymbol} ${this.product.amount}`, MessageSeverity.warn, 5000);
      return;
    }
    /*dValidaciones*/

    this.clearSimulation();
    if(this.arraySegurosOptativosSelecionados.length > 0) {
      this.loading = true;
      const sources: any = [];
      this.arraySegurosOptativosSelecionados.forEach((element: any) => {

        let model = {
          clientAge: this.clientAge,
          maritalStatus: this.clientMaritalStatus,
          insuranceCode: element.code,
          planCode: element.plan,
          currency: this.product.currency,
          applicationAmmount: parseFloat(this.montoValue).toFixed(2),
          applicationQuota: +this.quotas
        };

        sources.push(this.optionalInsurancesService.postCalcularaPrimaSeguroOptativo(model));
      });

      forkJoin(sources)
      .subscribe({
        next: (response: any) => {
          for(let index = 0; index < response.length; index++) {
            const element = response[index];
            this.arraySegurosOptativosSelecionados.forEach((insurance: any) => {
              if(insurance.code === element.insuranceCode && insurance.plan === element.planCode) 
              {
                insurance.amountBruto = element.amountBruto;
                insurance.amountNeto = element.amountNeto;
                insurance.currency = element.currency;
              }
            });
            
          }      
          this.loading = false;
        },
        error: (e) => {
          console.error(e)
          this.loading = false;
          this.alertService.showMessage(AppConstants.TitleAlert.OPTIONAL_INSURENCES_TITLE, e.error.message, MessageSeverity.error);
        },
        complete: () => {
          this.loading = false;
          var lifeInsuranceValue = 0;

          this.arraySegurosOptativosSelecionados.forEach((element: any) => {
            if(element.code === AppConstants.OptionalInsuracesCode.UNEMPLOYMENT)
            {
              model.unemploymentInsurance = element.amountBruto;
            }
            if(element.code === AppConstants.OptionalInsuracesCode.LIFE)
            {
              lifeInsuranceValue = element.amountBruto;
            }
          });
          model.lifeInsurance = lifeInsuranceValue;

          this.simularPrestamo(model, this.arraySegurosOptativosSelecionados);
        }
      });
      
    }
    else {
      model.unemploymentInsurance = 0;
      model.lifeInsurance = 0;
      this.simularPrestamo(model, this.arraySegurosOptativosSelecionados);
    }
  }

  returnSearchOffers() {
    this.router.navigate(['home']);
  }

  onValueChange(value: string) {
    this.quotas = value;
    this.clearSimulation();
    // this.cdr.markForCheck();
  }

  async getParameters(array: Array<number>) {
    this.loading = true;
    await lastValueFrom(this.parameterService.getParametersList(array)).then((response: any) => {
      this.setVariablesParameters(response);
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  setVariablesParameters(listParameters: any[]) {

    let paydays = (listParameters.filter((f: any) => f.parameterCode == AppConstants.ParameterCode.PAY_DAYS && f.state == AppConstants.StateFlag.Active)).sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1));
        paydays.forEach((element: any) => {
          this.diaPagoOptions.push({ label: element.parameterDetCode002, value: element.parameterDetCode001 });
        });

    let degravamen = (listParameters.filter((f: any) => f.parameterCode == AppConstants.ParameterCode.DESGRAVAMEN_TYPE && f.state == AppConstants.StateFlag.Active)).sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1));
        degravamen.forEach((element: any) => {
          this.tipoDesgravameOptions.push({ label: element.parameterDetCode002, value: element.parameterDetCode001 });
        });

    let numberInstallments = (listParameters.filter((f: any) => f.parameterCode == AppConstants.ParameterCode.NUMBER_INSTALLMENTS && f.state == AppConstants.StateFlag.Active)).sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1));
        numberInstallments.forEach((element: any) => {
          this.numeroCuotasOptions.push({ label: element.parameterDetCode002, value: element.parameterDetCode001 });
        });

  }

  clearSimulation() {
    this.clearOptionalInsurances();
    this.hiddenSimulationTable();
    // this.tableComponent.clearSimulation(this.nextColumnToFill);    
  }

  showNotificationOptionalInsurances(data: any) {
    try {
      if(data.code === AppConstants.OptionalInsuracesCode.LIFE) {
        const ageageRange = data.selectedValue === 'DV01' ? ' 18 a 34 ' :
        data.selectedValue === 'DV02' ? '35 a 44' :
        data.selectedValue === 'DV03' ? '45 a 54' :
        data.selectedValue === 'DV04' ? '55 a 64' : '';
        this.alertService.showMessage(AppConstants.TitleAlert.OPTIONAL_INSURENCES_TITLE, `El plan seleccionado corresponde al rango de edad de ${ageageRange} años cumplidos.`, MessageSeverity.info);
      }
    } catch (error) {
      console.error(error);      
    }
  }

  toggleLista() {
    this.listaAbierta = !this.listaAbierta;
  }
  
  onPlanChangeOptionalInsurance(element: any) {
    const index = this.arraySegurosOptativosSelecionados.findIndex((e: any) => e.code === element.code);

    if(index != -1 || element.selectedValue === 'null'){
      this.arraySegurosOptativosSelecionados.splice(index, 1);      
    }

    if(element.selectedValue != 'null') {
      if(element.code === AppConstants.OptionalInsuracesCode.LIFE_MAPFRE) {        
        let insurance = this.optionalInsuranceResponse?.optionalInsurances.find((f: any) => f.insuranceCode === AppConstants.OptionalInsuracesCode.LIFE_MAPFRE && f.flagActive === true);
        let plans = insurance?.plans;

        if(plans.length > 0) {
          let optionalInsurance = plans[0];
          let calculatePrima = (+this.quotas * (optionalInsurance.amountNeto / optionalInsurance.amountBruto) * +this.montoValue).toFixed(2);
          let nameFormat = optionalInsurance.currency === "SOL" ? `S/ ${this.formatCurrency(calculatePrima)}` : `$ ${this.formatCurrency(calculatePrima)}`;

          let installments = 
          [
            {
              amountBruto: +calculatePrima,
              amountNeto: +calculatePrima,
              coverage: this.quotas,
              currency: optionalInsurance.currency,
              endosable: insurance.flagEndosable,
              label: optionalInsurance.letter,
              letter: optionalInsurance.letter,
              name: "SEG. VIDA PLAN SOLES",
              nameFormat: nameFormat,
              type: element.code,
              value: optionalInsurance.planCode
            }
          ];

          element.options.forEach((option: any) => {
            if(option.type === element.code && option.value === element.selectedValue) {
              option.installments = installments;
            }
          });

          const options = element?.options?.find((p : any) => p.label === element.selectedValue)?.installments
          .sort((a: any, b: any) => (a.coverage < b.coverage ? -1 : 1))
          .map((element: any) => ({ label: `${element.coverage} - ${element.nameFormat}`, value: element.value }));

          element.subOptions = options;
          element.disabled = !(element.subOptions.length > 0);
          element.selectecSubOption = element.disabled === true ? null : '';
        }

      } else {
        const options = element?.options?.find((p : any) => p.label === element.selectedValue)?.installments
        .sort((a: any, b: any) => (a.coverage < b.coverage ? -1 : 1))
        .map((element: any) => ({ label: `${element.coverage} - ${element.nameFormat}`, value: element.value }));
        element.subOptions = options;
        element.disabled = !(element.subOptions.length > 0);
        element.selectecSubOption = element.disabled === true ? null : '';
      }
    }
    else {
      element.subOptions = [];
      element.disabled = true;
      element.selectecSubOption = null;
    }
  }

  onPlazoChangeOptionalInsurance(element: any) {
    const index = this.arraySegurosOptativosSelecionados.findIndex((e: any) => e.code === element.code);

    if(index != -1 || element.selectedValue === 'null'){
      this.arraySegurosOptativosSelecionados.splice(index, 1);      
    }

    if(element.selectedValue != 'null'){ 
      let plan = element?.options?.find((p:  any) => p?.type === element?.code && p.value === element?.selectedValue)?.installments
                                  .find((d:  any) => d?.type === element?.code && d.value === element?.selectecSubOption);

      //Validate Permanence 
      const customer = this.validatePlanOptionalInsurancesPLDBanco(plan?.coverage);                                  

      if(plan?.type === AppConstants.OptionalInsuracesCode.UNEMPLOYMENT_CHUBB) {
        if(plan?.coverage > this.quotas) {
          this.alertService.showMessage("", AppConstants.MessageAlert.ERROR_AGE_PLAZO_OPTIONAL_INSURANCE, MessageSeverity.warn);
          element.selectecSubOption = '';
          return;
        }
        if(isNaN(customer?.age)) {
          this.alertService.showMessage("", AppConstants.MessageAlert.ERROR_AGE_INSURANCE, MessageSeverity.warn);
          element.selectecSubOption = '';
          return;
        }
        if(customer?.age >= 75) {
          if(customer?.age === 75 && customer?.days <= 364) {              
          }
          else {
            element.selectecSubOption = '';
            this.alertService.showMessage("", AppConstants.MessageAlert.ERROR_COVERAGE_PLAZO_OPTIONAL_INSURANCE, MessageSeverity.warn);
            return;
          }
        }
      }

      if(plan?.type === AppConstants.OptionalInsuracesCode.HEALTH_PLUS) {
        if(isNaN(customer?.age)) {
          this.alertService.showMessage("", AppConstants.MessageAlert.ERROR_AGE_INSURANCE, MessageSeverity.warn);
          element.selectecSubOption = '';
          return;
        }
        if(customer?.age >= 70) {
          if(customer?.age === 70 && customer?.days <= 364) {              
          }
          else {
            element.selectecSubOption = '';
            this.alertService.showMessage("", AppConstants.MessageAlert.ERROR_COVERAGE_PLAZO_OPTIONAL_INSURANCE, MessageSeverity.warn);
            return;
          }
        }
      }

      this.arraySegurosOptativosSelecionados.push(
        {
          code: element.code,
          description: element.label,
          plan: plan.value,
          planName: plan.name,
          endosable: plan.endosable,
          amountBruto: plan.amountBruto,
          amountNeto: plan.amountNeto,
          currency: plan.currency
        }
      );
    }
  }

  showSimulationTable() {
    this.Simulado = true;
    this.cdr.detectChanges();
  }

  validateOptionalInsurancesPLDBanco() {
    let birthDate = '1900-01-01';

    if(Object.keys(this.dataCustomer).length === 0) {
      birthDate = this.dataNoCustomer?.birthDateHuman;      
    } else {
      birthDate = this.dataCustomer?.birthDate;
    }

    console.log(this.calculateAgeAndDays(birthDate));

    return this.calculateAgeAndDays(birthDate);

  }

  validatePlanOptionalInsurancesPLDBanco(quotas: string) {
    let birthDate = '1900-01-01';

    if(Object.keys(this.dataCustomer).length === 0) {
      birthDate = this.dataNoCustomer?.birthDateHuman;      
    } else {
      birthDate = this.dataCustomer?.birthDate;
    }

    console.log(this.calculateAgeOptinalInsurances(birthDate, +quotas));

    return this.calculateAgeOptinalInsurances(birthDate, +quotas);

  }

  getCurrentDateTimeUTC5(): Date {
    const currentDateUTC = new Date();
    const utcMinus5Offset = 5 * 60 * 60 * 1000;
    const currentDateUTC5 = new Date(currentDateUTC.getTime() - utcMinus5Offset);  
    return currentDateUTC5;
  }

  calculateAgeAndDays(nacimiento: string): { age: number, days: number, birthdate: string } {
    const fechaNacimiento = new Date(nacimiento);
    const fechaHoy = this.getCurrentDateTimeUTC5();

    let edad = fechaHoy.getFullYear() - fechaNacimiento.getFullYear();
    const cumpleEsteAño = fechaHoy.getMonth() > fechaNacimiento.getMonth() || 
                          (fechaHoy.getMonth() === fechaNacimiento.getMonth() && fechaHoy.getDate() >= fechaNacimiento.getDate());

    if (!cumpleEsteAño) {
        edad--;
    }
    
    const cumpleUltimoAño = new Date(fechaHoy.getFullYear(), fechaNacimiento.getMonth(), fechaNacimiento.getDate());
    if (cumpleUltimoAño > fechaHoy) {
        cumpleUltimoAño.setFullYear(fechaHoy.getFullYear() - 1);
    }

    const diasTranscurridos = Math.floor((fechaHoy.getTime() - cumpleUltimoAño.getTime()) / (1000 * 60 * 60 * 24));

    return { age: edad, days: diasTranscurridos, birthdate: nacimiento};
  }

  calculateAgeOptinalInsurances(nacimiento: string, meses: number): { age: number, days: number, birthdate: string } {
      const fechaNacimiento = new Date(nacimiento);
      const fechaHoy = this.getCurrentDateTimeUTC5();

      // Sumar los meses a la fecha actual
      const fechaFutura = new Date(fechaHoy);
      fechaFutura.setMonth(fechaHoy.getMonth() + meses);

      let edad = fechaFutura.getFullYear() - fechaNacimiento.getFullYear();
      const cumpleEsteAño = fechaFutura.getMonth() > fechaNacimiento.getMonth() || 
                            (fechaFutura.getMonth() === fechaNacimiento.getMonth() && fechaFutura.getDate() >= fechaNacimiento.getDate());

      if (!cumpleEsteAño) {
          edad--;
      }
      
      const cumpleUltimoAño = new Date(fechaFutura.getFullYear(), fechaNacimiento.getMonth(), fechaNacimiento.getDate());
      if (cumpleUltimoAño > fechaFutura) {
          cumpleUltimoAño.setFullYear(fechaFutura.getFullYear() - 1);
      }

      const diasTranscurridos = Math.floor((fechaFutura.getTime() - cumpleUltimoAño.getTime()) / (1000 * 60 * 60 * 24));

      return { age: edad, days: diasTranscurridos, birthdate: nacimiento};
  }

  callSimulationPLDBanco(model: LoanSimulationRequest): void {
    /*validacion*/
    if(this.quotas.length == 0) {
      this.alertService.showMessage(AppConstants.TitleAlert.SIMULATION_TITLE, `${AppConstants.MessageAlert.SIMULATION_NOT_QUOTA_ERROR}`, MessageSeverity.error, 5000);
      return;
    }
    
    if (!(+this.quotas >= this.product.installments && +this.quotas <= this.product.installmentsMax))  {
      this.alertService.showMessage(AppConstants.TitleAlert.SIMULATION_TITLE, `Las cuotas permitidas son de ${this.product.installments} a ${this.product.installmentsMax} meses.`, MessageSeverity.error, 5000);
      return;
    }
    if(+this.montoValue < this.amountMin) {
      this.alertService.showMessage(AppConstants.TitleAlert.SIMULATION_TITLE, `${AppConstants.MessageAlert.SIMULATION_VALIDATOR_AMOUNT_MIN_ERROR} ${this.product.currencySymbol} ${this.amountMin}`, MessageSeverity.warn, 5000);
      return;
    }
    if(+this.montoValue > this.product.amount) {
      this.alertService.showMessage(AppConstants.TitleAlert.SIMULATION_TITLE, `${AppConstants.MessageAlert.SIMULATION_VALIDATOR_AMOUNT_MAX_ERROR} ${this.product.currencySymbol} ${this.product.amount}`, MessageSeverity.warn, 5000);
      return;
    }

    this.simularPrestamo(model, this.arraySegurosOptativosSelecionados);    
  }

  formatCurrency(input: string) {
    let result = (input == null || input == undefined || input == 'undefined') ? '0' : Number(input).toFixed(2);
    return result.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  
  clearOptionalInsurances() {
    this.arraySegurosOptativosSelecionados = [];
    this.createOptionalInsurances(this.optionalInsuranceResponse);    
  }

  createOptionalInsurances(response: any) {
    this.arraySegurosOptativos = [];
    const customer = this.validateOptionalInsurancesPLDBanco();
      response.optionalInsurances.forEach((element: any) => {

        if(element.insuranceCode === AppConstants.OptionalInsuracesCode.UNEMPLOYMENT_CHUBB) {
          if(customer?.age >= 70) {
            if(customer?.age === 70 && customer?.days <= 354) {              
            }
            else {
              return;
            }
          }
        }

        if(element.insuranceCode === AppConstants.OptionalInsuracesCode.HEALTH_PLUS) {
          if(customer?.age >= 65) {
            if(customer?.age === 65 && customer?.days <= 364) {              
            }
            else {
              return;
            }
          }
        }

        let arrayPlanes: any = [
          {
            label: 'SIN SEGURO.',
            value: null,
            type: element.insuranceCode, 
            installments: null
          }
        ];

        let arrayInstallments: any = [];
        let groupedData: any = [];
        let groupsPlan: any = [];

        element.plans.forEach((plan: any) => {
          arrayInstallments.push({ 
            label: plan.letter,
            name: plan.planName,
            nameFormat: plan.termFormat,
            value: plan.planCode, 
            type: element.insuranceCode,
            letter: plan.letter,
            coverage: plan.coverage,
            endosable: element.flagEndosable,
            amountBruto: plan.amountBruto,
            amountNeto: plan.amountNeto,
            currency: plan.currency
          });
        });

        arrayInstallments.forEach((item: any) => {
          if(!groupedData[item.letter]) {
            groupedData[item.letter] = [];
          }
          groupedData[item.letter].push(item);
        });

        groupsPlan = Object.keys(groupedData).map(plan => ({
          label: plan,
          installments: groupedData[plan]
        }));

        groupsPlan.forEach((group: any) => {
          arrayPlanes.push({ 
            label: group.label,
            value: group.label,
            type: element.insuranceCode, 
            installments: group.installments,
            subOptions: null,
            selectedValue: null
          });          
        });        

        this.arraySegurosOptativos.push({
          label: element.insuranceName,
          code: element.insuranceCode,
          options: arrayPlanes,
          disabled: true,
          subOptions: [],
          selectecSubOption: null,
          selectedValue: null
        });

      });
  }

  hiddenSimulationTable() {
    this.Simulado = false;
    this.cdr.detectChanges();
  }
  
}
