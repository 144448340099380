import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutenticacionPldBancoComponent } from './autenticacion-pld-banco.component';
import { ValidacionFacialCorreoComponent } from './components/validacion-facial-correo/validacion-facial-correo.component';

const routes: Routes = [
  { path: '', component: AutenticacionPldBancoComponent },
  {path: 'faceid-email', component: ValidacionFacialCorreoComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AutenticacionPldBancoRoutingModule { }
