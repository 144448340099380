<div class="layout-containter">
    <div class="infor-usario">
        <ul>
            <li><span>Usuario: </span><p>{{ usuario }}</p></li>
            <li><span>Agencia: </span><p>{{ agencia }}</p></li>
            <li><span>Código: </span><p>{{ codigo }}</p></li>
            <li><span>Canal de ventas: </span><p>{{ canalVenta }}</p></li>
        </ul>
        <app-custom-button-color text="CERRAR" (action)="close()"></app-custom-button-color>
    </div>
</div>