import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomTableSimulatorComponent, DynamicModalComponent } from 'app/components';
import { Router,ActivatedRoute} from '@angular/router';
import { HostListener } from '@angular/core';
import { UserService } from 'app/services/user.service';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';
import { AppConstants } from 'app/shared/constants/app-constants';
import { ParameterService } from 'app/services/parameter.service';
import { lastValueFrom } from 'rxjs';
import { EnterpriseService } from 'app/services/enterprise.service';
import { SaleschannelService } from 'app/services/saleschannel.service';
import { RoleService } from 'app/services/role.service';
import { Location as RutaLocal } from '@angular/common';

@Component({
  selector: 'app-admin-usuarios-acciones',
  templateUrl: './admin-usuarios-acciones.component.html',
  styleUrls: ['./admin-usuarios-acciones.component.scss']
})
export class AdminUsuariosAccionesComponent {

  usuario: string = sessionStorage.getItem(AppConstants.Session.USERID) ?? '';

  showModal = false;
  esMovil: boolean = false;
  isError: boolean = false;
  nroResultados: number = 12;
  textoCabecera: string="";

  userId: string = '';
  password: string = '';
  fullname: string = '';
  documentType: string = '';
  documentNumber: string = '';
  userIdCore: string = '';
  sellerCode: string = '';
  email: string = '';
  enterpriseId: string = '';
  roleId: string = '';
  countryCode: string = '';
  stateCode: string = '';
  agencyCode: string = '';
  salesChannelId: string = '';

  isErrorUserId: boolean = false;
  isErrorPassword: boolean = false;
  isErrorFullname: boolean = false;
  isErrorDocumentType: boolean = false;
  isErrorDocumentNumber: boolean = false;
  isErrorUserIdCore: boolean = false;
  isErrorSellerCode: boolean = false;
  isErrorEmail: boolean = false;
  isErrorEnterpriseId: boolean = false;
  isErrorRoleId: boolean = false;
  isErrorCountryCode: boolean = false;
  isErrorStateCode: boolean = false;
  isErrorAgencyCode: boolean = false;
  isErrorSalesChannelId: boolean = false;

  loading: boolean = false;

  documents: any = [];
  elementsReadOnly: any = [];
  enterprises: any = [];
  roles: any = [];
  agencies: any = [];
  salesChanels: any = [];

  states: any[] = [ 
    {
      label: 'ACTIVO',
      value: '1'
    },
    {
      label: 'INACTIVO',
      value: '0'
    }
  ];

  countries: any[] = [ 
    {
      label: 'Peru',
      value: '615'
    }
  ];

  modeCreate: boolean = false;
  valueMinInput: number = 4;


  @HostListener('window:resize', ['$event'])
  onResize() {
    this.esMovil = window.innerWidth <= 768;
  }

  constructor(
    private location: RutaLocal,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private alertService: AlertService,
    private parameterService: ParameterService,
    private enterpriseService: EnterpriseService,
    private saleschannelService: SaleschannelService,
    private roleService: RoleService
  ) { }

  async ngOnInit() {
    const module: any = this.location.getState();

    if(module?.metadata === undefined || module?.metadata=== null) {
      this.router.navigate(['access-denied']);
    }
    await this.setComponent(module);
    await this.getEnterprises();
    await this.getRoles();
    await this.getSalesChannels();
    await this.getParameters([
      AppConstants.ParameterCode.DOCUMENT_TYPE,
      AppConstants.ParameterCode.AGENCYS,
    ]);
  }

  async setComponent(module: any) {
    this.textoCabecera = module?.metadata?.title;
    this.modeCreate = module?.metadata?.create;
    if(!this.modeCreate) {
      await this.getUserById(module?.metadata?.data?.userId);
    }
  }

  cancel() {
    this.router.navigate(['admin-usuarios']);
  }

  save() {
    this.validateForm();
  }

  validateForm() {

    this.isErrorUserId = this.userId == null ? true : (this.userId.length >= this.valueMinInput ? false : true);
    this.isErrorPassword = this.password == null ? true : (this.password.length >= this.valueMinInput ? false : true);
    this.isErrorFullname = this.fullname == null ? true : (this.fullname.length >= this.valueMinInput ? false : true);
    this.isErrorDocumentType = !this.documentType;
    this.isErrorDocumentNumber = this.documentNumber == null ? true : (this.documentNumber.length >= this.valueMinInput ? false : true);
    // this.isErrorUserIdCore = this.userIdCore == null ? true : (this.userIdCore.length >= this.valueMinInput ? false : true);
    // this.isErrorSellerCode = this.sellerCode == null ? true : (this.sellerCode.length >= this.valueMinInput ?  false : true);
    this.isErrorEmail = !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.email);
    this.isErrorEnterpriseId = !this.enterpriseId;
    this.isErrorRoleId = !this.roleId;
    this.isErrorCountryCode = !this.countryCode;
    this.isErrorStateCode = !this.stateCode;
    this.isErrorSalesChannelId = !this.salesChannelId;
    this.isErrorAgencyCode = !this.agencyCode;

    const requiredElements = [
      this.isErrorUserId,
      this.isErrorPassword,
      this.isErrorFullname,
      this.isErrorDocumentType,
      this.isErrorDocumentNumber,
      // this.isErrorUserIdCore,
      // this.isErrorSellerCode,
      this.isErrorEmail,
      this.isErrorEnterpriseId,
      this.isErrorRoleId,
      this.isErrorCountryCode,
      this.isErrorStateCode,
      this.isErrorSalesChannelId,
      this.isErrorAgencyCode
    ];

    if (requiredElements.every(element => !element)) {
      if(this.modeCreate) {
        let model = {
          userId: this.userId,
          password: this.password,
          fullName: this.fullname,
          documentType: this.documentType,
          documentNumber: this.documentNumber,
          userIdCore: this.userIdCore,
          sellerCode: this.sellerCode,
          email: this.email,
          enterpriseId: +this.enterpriseId,
          roleId: this.roleId,
          countryCode: this.countryCode,
          status: +this.stateCode,
          agencyCode: this.agencyCode,
          salesChannelId: +this.salesChannelId,
          userCreation: this.usuario
        };
        this.addUser(model);
      }
      else {
        let model = {
          userId: this.userId,
          password: this.password,
          fullName: this.fullname,
          documentType: this.documentType,
          documentNumber: this.documentNumber,
          userIdCore: this.userIdCore,
          sellerCode: this.sellerCode,
          email: this.email,
          enterpriseId: +this.enterpriseId,
          roleId: this.roleId,
          countryCode: this.countryCode,
          status: +this.stateCode,
          agencyCode: this.agencyCode,
          salesChannelId: +this.salesChannelId,
          userCreation: this.usuario
        };
        this.updateUser(model);
      }
    }
    else {
      this.alertService.showMessage("", AppConstants.MessageAlert.ERROR_FORM_REQUIRED, MessageSeverity.error);
    }

  }
  
  resetErrorState(inputType: string) {
    if (inputType === 'userId') {
      this.isErrorUserId = this.userId === undefined || this.userId === null  ? true : (this.userId.length >= this.valueMinInput ? false : true);
    }else   if (inputType === 'password') {
      this.isErrorPassword = this.password === undefined || this.password === null  ? true : (this.password.length >= this.valueMinInput ? false : true);
    }else   if (inputType === 'fullname') {
      this.isErrorFullname = this.fullname === undefined || this.fullname === null  ? true : (this.fullname.length >= this.valueMinInput ? false : true);
    }
    // else   if (inputType === 'userIdCore') {
    //   this.isErrorUserIdCore = this.userIdCore === undefined || this.userIdCore === null  ? true : (this.userIdCore.length >= this.valueMinInput ? false : true);
    // }else   if (inputType === 'sellerCode') {
    //   this.isErrorSellerCode = this.sellerCode === undefined || this.sellerCode === null  ? true : (this.sellerCode.length >= this.valueMinInput ? false : true);
    // }
    
  }

  async getParameters(array: Array<number>) {
    this.loading = true;
    await lastValueFrom(this.parameterService.getParametersList(array)).then((response: any) => {
      this.setVariablesParameters(response);
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  async getEnterprises() {
    this.loading = true;
    let model = {
      name: ''
    };
    await lastValueFrom(this.enterpriseService.retrieveEnterprises(model)).then((response: any) => {
        this.enterprises = response
        .filter((f: any) => f.stateId === AppConstants.StateFlag.Active)
        .sort((a: any, b: any) => (a.id < b.id ? -1 : 1))
        .map((element: any) => ({ label: element.name, value: element.id }));

      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    }).catch((error) => {
      this.loading = false;
      console.error(error);
    });    
  }

  async getRoles() {
    this.loading = true;
    await lastValueFrom(this.roleService.retrieveRoles()).then((response: any) => {
        this.roles = response
        .filter((f: any) => f.stateId === AppConstants.StateFlag.Active)
        .sort((a: any, b: any) => (a.id < b.id ? -1 : 1))
        .map((element: any) => ({ label: element.name, value: element.roleId }));

      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    }).catch((error) => {
      this.loading = false;
      console.error(error);
    });    
  }

  async getSalesChannels() {
    this.loading = true;
    await lastValueFrom(this.saleschannelService.retrieveSalesChannels()).then((response: any) => {
        this.salesChanels = response
        .filter((f: any) => f.stateId === AppConstants.StateFlag.Active)
        .sort((a: any, b: any) => (a.id < b.id ? -1 : 1))
        .map((element: any) => ({ label: element.salesChannelName, value: element.salesChannelId }));
        
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    }).catch((error) => {
      this.loading = false;
      console.error(error);
    });    
  }

  addUser(model: any) {
    this.loading = true;
    this.userService.postCreateUser(model).subscribe({
      next: async (response: any) => {
        if(response?.success) {          
          this.alertService.showMessage(AppConstants.TitleAlert.USER_ADD_TITLE, AppConstants.MessageAlert.CREATE_USER_SUCCESS, MessageSeverity.success);
          this.cancel();
        }
        else {
          this.alertService.showMessage(AppConstants.TitleAlert.USER_ADD_TITLE, response.message, MessageSeverity.info);
        }
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.USER_ADD_TITLE, AppConstants.MessageAlert.CREATE_USER_ERROR, MessageSeverity.error);
        console.error(error);
      }
    });
  }

  updateUser(model: any) {
    this.loading = true;
    this.userService.postUpdateUser(model).subscribe({
      next: async (response: any) => {
        if(response?.success) {          
          this.alertService.showMessage(AppConstants.TitleAlert.USER_UPDATE_TITLE, AppConstants.MessageAlert.UPDATE_USER_SUCCESS, MessageSeverity.success);
          this.cancel();
        }
        else {
          this.alertService.showMessage(AppConstants.TitleAlert.USER_UPDATE_TITLE, response.message, MessageSeverity.info);
        }
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.USER_UPDATE_TITLE, AppConstants.MessageAlert.UPDATE_USER_ERROR, MessageSeverity.error);
        console.error(error);
      }
    });
  }

  async getUserById(userId: string) {
    this.loading = true;
    let model = {
      userId: userId
    };
    await lastValueFrom(this.userService.retrieveUser(model)).then((response: any) => {
      this.autocompleteForm(response);
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.USER_GET_TITLE, AppConstants.MessageAlert.GET_USER_ERROR, MessageSeverity.error);
        console.error(error);        
    }).catch((error) => {
      this.loading = false;
      this.alertService.showMessage(AppConstants.TitleAlert.USER_GET_TITLE, AppConstants.MessageAlert.GET_USER_ERROR, MessageSeverity.error);
      console.error(error);      
    });    
  }

  autocompleteForm(data : any) {
    this.elementsReadOnly = ['userId'];
    this.userId = data.userId;
    this.password = data.password;
    this.fullname= data.fullName;
    this.documentType = data.documentType;
    this.documentNumber = data.documentNumber;
    // this.userIdCore = data.userIdCore;
    // this.sellerCode = data.sellerCode;
    this.email = data.email;
    this.enterpriseId = data?.enterpriseId.toString();
    this.roleId = data.roleId;
    this.countryCode = data.countryCode;
    this.stateCode = data?.stateId.toString();
    this.agencyCode = data.agencyCode;
    this.salesChannelId = data?.salesChannelId.toString();
  }

  setVariablesParameters(listParameters: any[]) {

    this.documents = listParameters
        .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.DOCUMENT_TYPE && f.state === AppConstants.StateFlag.Active)
        .sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1))
        .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001, length: element.parameterDetCode003 }));
    
    // this.elementsReadOnly = listParameters
    // .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.ELEMENTS_BLOCKED && f.state === AppConstants.StateFlag.Active && f.parameterDetCode003 === AppConstants.StateFlag.Active)
    // .map((element: any) => element.parameterDetCode002);
    
    this.agencies = listParameters
      .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.AGENCYS && f.state === AppConstants.StateFlag.Active)
      .sort((a: any, b: any) => (a.parameterDetCode002 < b.parameterDetCode002 ? -1 : 1))
      .map((element: any) => ({ label: `${element.parameterDetCode002} - ${element.parameterDetCode006}`, value: element.parameterDetCode001 }));
  }

}
