import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from 'app/services/application.service';
import { AppConstants } from 'app/shared/constants/app-constants';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';

@Component({
  selector: 'app-autenticacion-pld-banco',
  templateUrl: './autenticacion-pld-banco.component.html',
  styleUrl: './autenticacion-pld-banco.component.scss'
})
export class AutenticacionPldBancoComponent {

  applicationId: string = sessionStorage.getItem(AppConstants.Session.APPLICATION_ID) ?? '';
  workflowId: string = sessionStorage.getItem(AppConstants.Session.WORKFLOW_ID) ?? '';

  rolName: string = sessionStorage.getItem(AppConstants.Session.USERROLNAME) ?? '';
  usuario: string = sessionStorage.getItem(AppConstants.Session.USERID) ?? '';
  userName: string = sessionStorage.getItem(AppConstants.Session.USERNAME) ?? '';
  agenciaName: string = sessionStorage.getItem(AppConstants.Session.AGENCYNAME) ?? '';
  agenciaCode: string = sessionStorage.getItem(AppConstants.Session.AGENCYCODE) ?? '';

  loading: boolean = false;

  constructor(
    private router: Router,
    private applicationService: ApplicationService,
    private alertService: AlertService
  ) { }

  fingerprintAuthentication() {
    this.router.navigate(['autenticacion-bco/dactilar']);
  }

  faceIdAuthentication() {
    this.router.navigate(['autenticacion-bco/faceid-email']);
  }

  sendApprovalHandler() {
    let model = {
      applicationId: this.applicationId,
      workflowInstanceId: this.workflowId,
      status: AppConstants.ApplicationStates.PendienteAprobacion,
      processId: AppConstants.Process.PLD_DRACMA_APROBACION,
      taskId: AppConstants.WorkflowTasks.CUSTOMER_VERIFICATION,
      channelId: AppConstants.Channels.DesktopPVA,
      taskInstanceRole: this.rolName,
      updatedBy: this.usuario,
      updatedByFullName: this.userName,
      agencyCode: this.agenciaCode,
      agencyName: this.agenciaName
    };

    this.updateStautsApplication(model);
  }

  updateStautsApplication(model: any) {
    this.loading = true;
    this.applicationService.postUpdationStatusApplication(model).subscribe({
      next: async (response: any) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.APPLICATION_UPDATE_TITLE, AppConstants.MessageAlert.UPDATE_APPLICATION_SUCCESS, MessageSeverity.success);
        this.router.navigate(['finalizacion']);
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.APPLICATION_UPDATE_TITLE, AppConstants.MessageAlert.UPDATE_APPLICATION_ERROR, MessageSeverity.error);
        console.error(error);
      }
    });
  }  

}
