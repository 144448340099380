import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomTableSimulatorComponent, DynamicModalComponent } from 'app/components';
import { Router } from '@angular/router';
import { HostListener } from '@angular/core';
import { EnterpriseService } from 'app/services/enterprise.service';
import { lastValueFrom } from 'rxjs';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';
import { AppConstants } from 'app/shared/constants/app-constants';

@Component({
  selector: 'app-admin-empresas',
  templateUrl: './admin-empresas.component.html',
  styleUrls: ['./admin-empresas.component.scss']
})
export class AdminEmpresasComponent {

  usuario: string = sessionStorage.getItem(AppConstants.Session.USERID) ?? '';

  showModal = false;
  esMovil: boolean = false;
  isError: boolean = false;
  nroResultados: number =5;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.esMovil = window.innerWidth <= 768;
  }

 
  
  columnNames = ['Id', 'Empresa', 'País', 'Estado ','Usuario Creación', 'Fecha Creación', 'Fecha Modificacion', 'Editar'];
  companies: any[] = [];
  modalWidth: number = 480; 
  modalHeight: number = 380;
  
  companyName: string = '';
  loading: boolean = false;
  titleModal: string = '';
  countrySelect: string = '';
  stateSelect: string = '';
  nameCompanyModel: string = '';

  isErrorCountry: boolean = false;
  isErrorState: boolean = false;
  isErrorCompanyName: boolean = false;
  nameCompanyMin: number = 3;
  modeCreate: boolean = false;
  enterpriseId!: number;
  
  constructor(
    private router: Router,
    private enterpriseService: EnterpriseService,
    private alertService: AlertService,
  ) { }

  async ngOnInit() {
    await this.getEnterprises();
  }

  handleCreateCompany() {
    this.clearForm();
    this.clearValidators();
    this.titleModal = "Agregar Empresa";
    this.showModal = true;
    this.modeCreate = true;
  }

  async clear() {
    this.companyName = '';
    await this.getEnterprises();
  }

  async seach() {
    await this.getEnterprises();
  }

  handleClose() {
    this.showModal = false;
  }

  sendCommentHandler() {
    this.validateForm();    
  }

  validateForm() {
    this.isErrorCountry = this.countrySelect === '' ? true : false;
    this.isErrorState = this.stateSelect === '' ? true : false;
    this.isErrorCompanyName = this.nameCompanyModel == null ? true : (this.nameCompanyModel.length >= this.nameCompanyMin ? false : true);

    const requiredElements = [
      this.isErrorCountry,
      this.isErrorState,
      this.isErrorCompanyName,
    ];

    if (requiredElements.every(element => !element)) { 
      if(this.modeCreate) {
        let model = {
          name: this.nameCompanyModel,
          status: +this.stateSelect,
          countryCode: this.countrySelect,
          userCreation: this.usuario
        };
        this.addEnterprise(model);
      }
      else {
        let model = {
          enterpriseId: this.enterpriseId,
          name: this.nameCompanyModel,
          status: +this.stateSelect,
          countryCode: this.countrySelect,
          updatedBy: this.usuario
        };
        this.updateEnterprise(model);
      }
    }
    else {
      this.alertService.showMessage("", AppConstants.MessageAlert.ERROR_FORM_REQUIRED, MessageSeverity.error);
    }

  }

  updateSelectState(value: string) {
    this.stateSelect = value;
  }

  updateSelectCountry(value: string) {
    this.countrySelect = value;
  }

  updateNameEnterprise(value: string) {
    this.nameCompanyModel = value;
  }

  clearForm() {
    this.enterpriseId = 0;
    this.nameCompanyModel = '';
    this.countrySelect = '';
    this.stateSelect = '';
  }

  clearValidators() {
    this.isErrorCountry = false;
    this.isErrorState = false;
    this.isErrorCompanyName = false;
  }

  editEnterprise(row: any) {
    this.showModalEdit();
    this.enterpriseId = row.enterpriseId;    
    this.nameCompanyModel = row.name;
    this.stateSelect = row.stateId;
    this.countrySelect = row.countryId;
  }

  showModalEdit() {
    this.clearForm();
    this.clearValidators();
    this.titleModal = "Actualizar Empresa";
    this.showModal = true;
    this.modeCreate = false;
  }

  async getEnterprises() {
    this.loading = true;
    let model = {
      name: this.companyName
    };
    await lastValueFrom(this.enterpriseService.retrieveEnterprises(model)).then((response: any) => {
      this.companies = response.map((element: any) => ({
          'Id': element.id,
          'Empresa': element.name,
          'País': element.countryDescription,
          'Estado ': element.stateDescription,
          'Usuario Creación': element.createdBy,
          'Fecha Creación': element.createdDateTimeFormat,
          'Fecha Modificacion': element.updatedDateTimeFormat,
          'Editar': "<a href='#'>Editar</a>",
          enterpriseId: element.id,
          name: element.name,
          stateId: element.stateId,
          countryId: element.countryId
        }));
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    }).catch((error) => {
      this.loading = false;
      console.error(error);
    });    
  }

  addUsuario(accion: string){
    this.router.navigate(['admin-empresas-acciones'], { queryParams: { parametro: accion } });
  }

  addEnterprise(model: any) {
    this.loading = true;
    this.enterpriseService.postCreateEnterprise(model).subscribe({
      next: async (response: any) => {
        this.loading = false;
        this.handleClose();
        this.alertService.showMessage(AppConstants.TitleAlert.ENTERPRISE_ADD_TITLE, AppConstants.MessageAlert.CREATE_ENTERPRISE_SUCCESS, MessageSeverity.success);
        this.clear();
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.ENTERPRISE_ADD_TITLE, AppConstants.MessageAlert.CREATE_ENTERPRISE_ERROR, MessageSeverity.error);
        console.error(error);
      }
    });
  }

  updateEnterprise(model: any) {
    this.loading = true;
    this.enterpriseService.postUpdateEnterprise(model).subscribe({
      next: async (response: any) => {
        this.loading = false;
        this.handleClose();
        this.alertService.showMessage(AppConstants.TitleAlert.ENTERPRISE_UPDATE_TITLE, AppConstants.MessageAlert.UPDATE_ENTERPRISE_SUCCESS, MessageSeverity.success);
        this.clear();
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.ENTERPRISE_UPDATE_TITLE, AppConstants.MessageAlert.UPDATE_ENTERPRISE_ERROR, MessageSeverity.error);
        console.error(error);
      }
    });
  }
  

}
