<app-header [headerText]="'1. CONSULTA DE OFERTA'" headerImageSrc="assets/busqueda.svg"></app-header>

<!-- Sección del Formulario -->
<div class="formulario-container">
    <!-- Contenedor Flex para los Elementos del Formulario -->
    <div class="form-flex-container">
        <!-- Texto de Instrucción -->
        <div class="titulo">
            <label class="form-instruction">Completa la información para realizar la búsqueda:</label>
        </div>

      <div class="container-busqueda">
        <div class="form-busqueda">
          <div class="form-group tipo-doc">
            <label>Tipo de Documento</label>
            <app-custom-inpunt-select 
                                      [options]="documents"
                                      [(selectedValue)]="selectedTypeDocument"
                                      (change)="typeOperation();">
            </app-custom-inpunt-select>
          </div>
      
          <div class="form-group nro-doc">
              <label>Nro. de Documento</label>
            <!-- <app-custom-input [(value)]="inputValue" [isError]="isError" [placeholderText]="miPlaceholder"></app-custom-input> -->

            <app-custom-input 
                  [(value)]="documentForm"
                  [validateNumber]="true"
                  [maxlength]="max"
                  [isError]="isError"
                  [type]="typeInputDocument"
                  [placeholderText]="miPlaceholder"
                  (input)="removeSpacesDocument();onlyNumber();searchOffer();clearformlength();"
                  (keyup.enter)="searchOffer()">
            </app-custom-input>
          </div>

          <div *ngIf="!showSpouse && !esMovil" class="form-group button-container search-desktop">
            <app-custom-button iconUrl="assets/img/buscar.svg" 
                              text="Buscar" 
                              (action)="searchOffer()">
            </app-custom-button>
          </div>

          <!--ESTADO CIVIL AÑADIDO-->
          <div *ngIf="!esMovil && showSpouse" class="form-group tipo-doc bloqueados" @fadeInDown>
            <label>Estado Civil</label>
            <app-custom-inpunt-select placeholder="Seleccionar"                                       
                                      [(selectedValue)]="estadoCivil"
                                      [isReadonly]="maritalStatusBlocked"
                                      [options]="opcionesEstadoCivil"
                                      (selectedValueChange)="handleSelectionChange($event, 'isErrorEstadoCivil')">
            </app-custom-inpunt-select>
          </div>
        </div>

        <div class="form-busqueda">
          <div *ngIf="!showSpouse && esMovil" class="form-group button-container">
            <app-custom-button iconUrl="assets/img/buscar.svg" 
                              text="Buscar" 
                              (action)="searchOffer()">
            </app-custom-button>
          </div>
        </div>

        <div *ngIf="esMovil && showSpouse" class="form-busqueda marital-mobile">
          <div class="form-group tipo-doc bloqueados" @fadeInDown>
            <label>Estado Civil</label>
            <app-custom-inpunt-select placeholder="Seleccionar" [(selectedValue)]="estadoCivil" [isReadonly]="maritalStatusBlocked"
              [isError]="isErrorEstadoCivil" [options]="opcionesEstadoCivil" 
              (selectedValueChange)="handleSelectionChange($event, 'isErrorEstadoCivil')">
            </app-custom-inpunt-select>
          </div>
        </div>

      </div>
    </div>

    <!-- SECCIÓN DEL CÓNYUGE-->
    <div *ngIf="showSpouse" class="form-flex-container" @fadeInDown>
      <!-- Texto de Instrucción -->
      <div class="titulo" style="padding-bottom: 0.5vw;">
          <img src="assets/img/dni_icon.png" style="width: 40px; height: auto; margin-right: 5px;">
          <label class="form-instruction">DOI del cónyuge</label>
      </div>

      <div class="container-busqueda">
        <div *ngIf="esMovil" class="form-busqueda">
          <div class="form-group tipo-doc bloqueados">
            <label>Tipo de Documento</label>
            <app-custom-inpunt-select 
                    [options]="documents"
                    [(selectedValue)]="documentTypeSpouse"
                    [isReadonly]="spouseDocumentTypeBlocked"
                    [isError]="isErrorDocumentTypeSpouse"
                    (change)="typeOperationSpouse();">
                </app-custom-inpunt-select>
          </div>
      
          <div class="form-group nro-doc bloqueados">
            <label>Nro. de Documento</label>
            <!-- <app-custom-input [(value)]="inputValue" [isError]="isError" [placeholderText]="miPlaceholder"></app-custom-input> -->

            <app-custom-input 
                    [(value)]="dniSpouse"
                    [validateNumber]="true"   
                    [maxlength]="maxSpouse"
                    [isError]="isErrorDocumentNumberSpouse"
                    [type]="typeInputDocumentSpouse"
                    [isReadonly]="spouseDocumentTypeBlocked">
                </app-custom-input>
          </div>
        </div>

        <div class="form-busqueda">
          <div *ngIf="!esMovil" class="form-group tipo-doc bloqueados">
            <label>Tipo de Documento</label>
            <app-custom-inpunt-select 
                    [options]="documents"
                    [(selectedValue)]="documentTypeSpouse"
                    [isError]="isErrorDocumentTypeSpouse"
                    [isReadonly]="spouseDocumentTypeBlocked"
                    (change)="typeOperationSpouse();">
                </app-custom-inpunt-select>
          </div>
      
          <div *ngIf="!esMovil" class="form-group nro-doc bloqueados">
            <label>Nro. de Documento</label>
            <!-- <app-custom-input [(value)]="inputValue" [isError]="isError" [placeholderText]="miPlaceholder"></app-custom-input> -->

            <app-custom-input 
                    [(value)]="dniSpouse"
                    [validateNumber]="true"   
                    [maxlength]="maxSpouse"
                    [isError]="isErrorDocumentNumberSpouse"
                    [type]="typeInputDocumentSpouse"
                    [isReadonly]="spouseDocumentTypeBlocked">
                </app-custom-input>
          </div>

          <div class="form-group button-container search-desktop">
            <app-custom-button iconUrl="assets/img/buscar.svg" 
                              text="Buscar" 
                              (action)="searchOffer()">
            </app-custom-button>
          </div>

        </div>
      </div>
    </div>
</div>

<div *ngIf="showCustomerInformation" class="contenedor-informacion">
  <div class="informacion-producto">
    <div class="cabecera-informacion">
      <h2 class="titulo-informacion">Información del Producto</h2>
      <div class="resultados">Resultados ({{ numeroResultados }})</div>
    </div>
    <div class="detalles-cliente">
      <span><span class="titulo-dni">{{ documentTypeDescription }}:</span> {{ dni }}</span>
      <span><span class="titulo-cliente">Cliente:</span> {{ nombreCliente }}</span>
    </div>  
  </div>
</div>

<div *ngIf="mostrarInformacion" class="contenedor-controles">
  <!-- Botón de filtros -->
  <div class="btn-filtros">
    <img src="assets/svg/filter_principal.svg" alt="Filtro" (click)="abrirFiltros()">
    <div class="filtros-container" *ngIf="filtrosVisibles" @fadeInDownReverse>
      <h3 class="titulo-filtros">Seleccionar</h3>
      <div class="filtros-lista">
        <label class="filtro-opcion" *ngFor="let filter of filters; let i = index">
          <input type="checkbox" [(ngModel)]="filter.seleccionado" (change)="onFilterChange(filter)">
          {{filter?.name}}
        </label>
      </div>
    </div>
  </div>


  <div *ngIf="!esMovil" class="toggle-container">
    <input type="radio" id="lista" name="toggle" checked>
    <label (click)="cambiarVista('lista')" for="lista" class="toggle-option">
      <img src="assets/svg/filter_list.svg" alt="Lista">
    </label>
  
    <input type="radio" id="tarjetas" name="toggle">
    <label (click)="cambiarVista('tarjetas')" for="tarjetas" class="toggle-option">
      <img src="assets/svg/filter_card.svg" alt="Tarjetas">
    </label>
  </div>  
</div>

<div *ngIf="mostrarInformacion" class="lista-desplegable">
  <div *ngFor="let nombre of (agrupadosPorNombreFiltrado | keyvalue)" class="lista-desplegable-particular">
    
    <div class="encabezado-lista" (click)="toggleLista(nombre.key)">
      <!-- <span class="custom-filter-header">{{ nombre.key }}</span> -->
      <span [ngClass]="isEnabled(nombre.value) ? 'custom-filter-header-enabled' : 'custom-filter-header-disabled'">
        {{ nombre.key }}
      </span>
      <span class="icono-desplegable" [ngClass]="{'abierto': listaAbierta[nombre.key]}">
        <i class="fa-solid fa-chevron-down"></i>
      </span>
    </div>

    <div class="contenido-lista" *ngIf="listaAbierta[nombre.key]">
      <table *ngIf="vistaActual === 'lista' && !esMovil" class="tabla-productos" @fade>
        <tbody>
          <tr *ngFor="let producto of nombre.value">
            <td><img [src]="producto.url" alt="Producto"></td>
            <td>{{producto.monedaSimbolo}} {{ producto.monto | number: '1.2-2' }}</td>
            <td>
              <strong class="producto-nombre">{{ producto.nombre }}</strong><br>
              <div class="detalles-producto">
                <strong>Vigencia de la Oferta:</strong> {{ producto.fechaInicio }} al {{ producto.fechaFin }} 
                <strong>TEA:</strong> {{ producto.tea }} %                   
                <strong>Plazo:</strong> {{ producto.plazoMinimo }} a {{ producto.plazoMaximo }} meses
              </div>
            </td>
            <td *ngIf="producto.enabled">
              <app-custom-button-color text="ESCOGER" (action)="selectProduct(producto)"></app-custom-button-color>
            </td>
          </tr>
        </tbody>
      </table>

      <div *ngIf="vistaActual === 'tarjetas' || esMovil" class="productos-tarjetas-container" @fade>
        <div class="productos-tarjetas">

          <div *ngFor="let producto of nombre.value" class="producto-tarjeta">
            <div *ngIf="!esMovil" class="top-producto">
              <img [src]="producto.url" alt="Producto">
              <strong>{{ producto.monedaSimbolo }} {{ producto.monto | number: '1.2-2' }}</strong>
              <strong class="producto-nombre">{{ producto.nombre }}</strong><br>
            </div>

            <div *ngIf="esMovil" class="top-producto">
              <img [src]="producto.url" alt="Producto">
              <div class="top-producto-mobile">
                <strong class="producto-nombre">{{ producto.nombre }}</strong>
                <strong>{{ producto.monedaSimbolo }} {{ producto.monto | number: '1.2-2' }}</strong>
              </div>
            </div>

            <div class="info-producto">
              <div><strong>Vigencia de la Oferta:</strong> {{ producto.fechaInicio }} al {{ producto.fechaFin }}</div>
              <div><strong>TEA:</strong> {{ producto.tea }} %</div>
              <div><strong>Plazo:</strong> {{ producto.plazoMinimo }} a {{ producto.plazoMaximo }} meses</div>
            </div>

            <app-custom-button-color text="ESCOGER" (action)="selectProduct(producto)" *ngIf="producto.enabled"></app-custom-button-color>
          </div>
        </div>
      </div>    
    </div>
  </div>
</div>

<div *ngIf="showRules" class="lista-desplegable">
  <div class="lista-desplegable-particular">
    <div class="encabezado-lista matriz" (click)="toggleLista(isValor)">
      <span>MATRIZ DE POLÍTICAS DE ADMISIÓN</span>
      <span class="icono-desplegable" [ngClass]="{'abierto': !listaAbierta[isValor]}">
        <i class="fa-solid fa-chevron-down"></i>
      </span>
    </div>
    <div class="contenido-lista" *ngIf="!listaAbierta[isValor]">
      <table *ngIf="!esMovil" class="tabla-productos matriz" @fade>
        <tbody>
          <tr *ngFor="let rule of admissionRules">
            <td>
              <strong>{{rule?.identifier}}</strong>
            </td>
            <td>
              <strong class="producto-nombre">{{rule?.message}}</strong><br>
              <div class="detalles-producto">
                <strong>{{rule?.label}}</strong>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div *ngIf="esMovil" class="producto-tarjeta">
        <div *ngFor="let rule of admissionRules">
          <div class="top-producto">
            <p class="producto-nombre"><strong>{{rule?.identifier}}</strong></p>
          </div>
  
          <div class="info-producto">
            <div>{{rule?.message}}</div>
            <div><strong>{{rule?.label}}</strong></div>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>

<div *ngIf="showMatriz" class="lista-desplegable">
  <div class="lista-desplegable-particular">
    <div class="encabezado-lista matriz" (click)="toggleLista(isValor);hiddenExportButton();">
      <span>MATRIZ DE VARIABLES DE EVALUACIÓN</span>
      <span class="icono-desplegable" [ngClass]="{'abierto': !listaAbierta[isValor]}">
        <i class="fa-solid fa-chevron-down"></i>
      </span>
    </div>
    <div class="contenido-lista" *ngIf="!listaAbierta[isValor]">
      <table *ngIf="!esMovil" class="tabla-risk matriz" @fade>
        <tbody>
          <tr *ngFor="let risk of riskAssessment">
            <td>
              <strong>{{risk?.name}}</strong>
            </td>
            <td>
              <strong class="producto-nombre">{{risk?.value}}</strong><br>
              <!-- <div class="detalles-producto">
                <strong>{{rule?.label}}</strong>
              </div> -->
            </td>
            <td>
              <strong>{{risk?.result}}</strong>
            </td>
          </tr>
        </tbody>
      </table>

      <div *ngIf="esMovil" class="producto-tarjeta">
        <div *ngFor="let risk of riskAssessment">
          <div class="top-producto">
            <p class="producto-nombre"><strong>{{risk?.name}}</strong></p>
          </div>
  
          <div class="info-producto">
            <div>{{risk?.value}}</div>
            <div><strong>{{risk?.result}}</strong></div>
          </div>
        </div>
      </div>

    </div>

    <div *ngIf="showButtonExport" class="button-export">
      <app-custom-button text="EXPORTAR"  iconUrl="assets/svg/pdf.svg"  (action)="downloadRiskAssessment()"></app-custom-button>
    </div>    
  </div>  
</div>


<div class="separador"></div>
<!-- <div *ngIf="mostrarInformacion" class="contenedor-informacion">
  <div class="enlace-atras">
    <a href="#" class="link-estilo">ATRÁS</a>
  </div>
</div> -->
<app-modal *ngIf="showModal" [width]="400" [height]="300" [imageUrl]="'assets/img/errorImg.svg'"
           [titleText]="tituloModal" [subtitleText]="subTituloModal"
           [paragraphText]="parrafo" [linkText]="textoEnlaceModal"
           [showCustomButtons]="false"
           (linkAction)="cerrarModal()">
</app-modal>


<button *ngIf="showScrollButton" class="scroll-button" (click)="scrollToPolicies()" @fadeInDownReverse>
  👇 <!-- Emoji de flecha hacia abajo -->
</button>


<app-footer></app-footer>
<loading *ngIf="loading"></loading>