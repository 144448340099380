import { Component, HostListener } from '@angular/core';
import { SidenavService } from 'app/services/sidenav.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

const seconds: number = 600;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Plataforma Venta Asistida';

  showSidenav$: Observable<boolean>;
  showSidenav: boolean = true;

  private homePath: string = "home";
  private loginPath: string = "login";

  loading: boolean = false;
  timeLeft: number = 0;
  interval: any;
  stop: boolean = false;
  mouseMove: number = 0;
  timeSeconds: number = seconds;

  constructor(private sidenavService: SidenavService,private router: Router) {
    this.showSidenav$ = this.sidenavService.getShowSidenav();
    this.showSidenav$.subscribe(show => this.showSidenav = show);
  }

  get isLoginRoute() {
    // Asume que tu ruta de login es algo como '/login'
    return this.router.url === '/login';
  }

  navigateTo($event: any, path: any) {
    if (path == this.homePath)
      this.loading = true;
    else if (path == this.loginPath)
      this.loading = false;

    this.stop = true;
    this.router.navigate([path]);
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: any) {
    clearInterval(this.interval)
    this.stop = false;
    this.timeLeft = this.timeSeconds;
    if (!this.stop) {
      this.interval = setInterval(() => {
        this.subtractTime();
      }, 1000)
    }
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    clearInterval(this.interval)
    this.stop = false;
    this.timeLeft = this.timeSeconds;
    if (!this.stop) {
      this.interval = setInterval(() => {
        this.subtractTime();
      }, 1000)
    }
  }

  subtractTime() {
    if (this.timeLeft > 0) {
      this.timeLeft--;
      this.stop = false;
    } else {
      this.timeLeft = this.timeSeconds;
      sessionStorage.clear();
      this.navigateTo('', this.loginPath);
    }
  }

}
