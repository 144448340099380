<div class="container-stepper">
  <img *ngIf="!esMovil" src="assets/img/01.svg" class="stepper">
  <img *ngIf="esMovil" src="assets/01.svg" class="stepper">
</div>

<div class="layout-container">
    <div class="container-datos-cliente">
      <app-datos-cliente (abrirModal)="openAttachmentModal()"></app-datos-cliente>
    </div>
    <div class="formulario-container">

        <div class="form-row">
          <div class="form-group">
            <label>Tipo de Documento</label>
            <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="documents" [isReadonly]="elementsReadOnly.includes('tipoDocumento')"
            [(selectedValue)]="tipoDocumento"  (selectedValueChange)="tipoDocumento=$event" [isError]="isErrorTipoDocumento">
            </app-custom-inpunt-select>
          </div>
        
          <div class="form-group">
            <label>Nro. de Documento</label>
            <app-custom-input [(value)]="nroDocInput" [isError]="isErrorNroDocInput" [isReadonly]="elementsReadOnly.includes('nroDocInput')"
            (keyup)="resetErrorState('nroDocInput')" ></app-custom-input>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group">
            <label>Primer Nombre</label>
            <app-custom-input [(value)]="primerNombre" [isError]="isErrorPrimerNombre" [isReadonly]="elementsReadOnly.includes('primerNombre')"
            (keyup)="resetErrorState('primerNombre')" [minlength]="primerNombreValMin" [maxlength]="primerNombreValMax"></app-custom-input>
          </div>

          <div class="form-group">
            <label>Segundo Nombre</label>
            <app-custom-input [(value)]="segundoNombre" [isError]="isErrorSegundoNombre" [isReadonly]="elementsReadOnly.includes('segundoNombre')" 
            (keyup)="resetErrorState('segundoNombre')" [minlength]="segundoNombreValMin" [maxlength]="segundoNombreValMax"></app-custom-input>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group">
            <label>Apellido Paterno</label>
            <app-custom-input [(value)]="apellidoPaterno" [isError]="isErrorApellidoPaterno" [isReadonly]="elementsReadOnly.includes('apellidoPaterno')"
            (keyup)="resetErrorState('apellidoPaterno')" [minlength]="apellidoPaternoValMin" [maxlength]="apellidoPaternoValMax"></app-custom-input>
          </div>

          <div class="form-group">
            <label>Apellido Materno</label>
            <app-custom-input [(value)]="apellidoMaterno" [isError]="isErrorApellidoMaterno" [isReadonly]="elementsReadOnly.includes('apellidoMaterno')"
            (keyup)="resetErrorState('apellidoMaterno')" [minlength]="apellidoMaternoValMin" [maxlength]="apellidoMaternoValMax"></app-custom-input>
          </div>
        </div>

        <div class="form-row">
            <div class="form-group">
              <label>Fecha de Nacimiento</label>
              <div class="custom-input-container">
                <app-custom-datepicker [(value)]="fechaNacimiento" [isError]="isErrorFechaNacimiento" (keyup)="resetErrorState('fechaNacimiento')" [validateYears]="true"></app-custom-datepicker>
              </div>
              
            </div>

            <div class="form-group">
              <label>Nacionalidad</label>
                <app-custom-inpunt-select placeholder="Seleccionar" [(selectedValue)]="nacionalidad" [isReadonly]="elementsReadOnly.includes('nacionalidad')"
                [isError]="isErrorNationality" [width]="'190px'" [options]="opcionesPaisDomicilio" (selectedValueChange)="nacionalidad=$event">
                </app-custom-inpunt-select>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group">
              <label>Género</label>
                <app-custom-inpunt-select placeholder="Seleccionar" [(selectedValue)]="genero" [isReadonly]="elementsReadOnly.includes('genero')"
                [isError]="isErrorGenero" [width]="'190px'" [options]="opcionesGenero" (selectedValueChange)="genero=$event">
              </app-custom-inpunt-select>
            </div>

            <div class="form-group">
              <label>País de domicilio</label>
              <app-custom-inpunt-select placeholder="Seleccionar" [(selectedValue)]="countryDomicile" (selectedValueChange)="countryDomicile=$event" [isReadonly]="elementsReadOnly.includes('countryDomicile')"
              [isError]="isErrorCountryDomicile" [width]="'190px'" [options]="opcionesPaisDomicilio">
                </app-custom-inpunt-select>
            </div>
        </div>

        <div class="form-row">
          <div class="form-group">
            <label>Situación de Vivienda</label>
            <app-custom-inpunt-select placeholder="Seleccionar" [(selectedValue)]="housingType" (selectedValueChange)="housingType=$event" [isReadonly]="elementsReadOnly.includes('housingType')"
            [isError]="isErrorHousingType" [width]="'190px'" [options]="opcionesTipoVienda"> 
            </app-custom-inpunt-select>
          </div>

          <div class="form-group">
            <label>Relación Laboral</label>
            <app-custom-inpunt-select placeholder="Seleccionar" [(selectedValue)]="employmentRelationship" (selectedValueChange)="employmentRelationship=$event" [isReadonly]="elementsReadOnly.includes('employmentRelationship')"
            [isError]="isErrorEmploymentRelationship" [width]="'190px'" [options]="opcionesRelacionLaboral" >
                </app-custom-inpunt-select>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group">
            <label>Estado Civil</label>
            <app-custom-inpunt-select placeholder="Seleccionar" [(selectedValue)]="estadoCivil" [isReadonly]="elementsReadOnly.includes('estadoCivil')"
              [isError]="isErrorEstadoCivil" [width]="'190px'" [options]="opcionesEstadoCivil" 
              (selectedValueChange)="handleSelectionChange($event, 'isErrorEstadoCivil')">
            </app-custom-inpunt-select>
          </div>

          <div class="form-group">
            <label>Número Celular</label>
            <app-custom-input [(value)]="cellphone" [isError]="isErrorCellphone" [type]="typeInputNumber" [maxlength]="max" [validatemaxlength]="true" [isReadonly]="elementsReadOnly.includes('cellphone')"
            (keyup)="resetErrorState('cellphone')">
            </app-custom-input>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group correo">
            <label>Correo Electrónico</label>
            <app-custom-input [(value)]="email" [isError]="isErrorEmail" [validateEmail]="true" [isReadonly]="elementsReadOnly.includes('email')"
            (keyup)="resetErrorState('email')" [maxlength]="emailValMax"></app-custom-input>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group correo">
            <label>Cliente PEP</label> 
            <label class="switch">
              <input type="checkbox" id="recordar" name="recordar" [(ngModel)]="clientPep">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        

    </div>

</div>

<div *ngIf="showDatosConyugue"  class="layout-container">  
  <div class="formulario-container-adicional">
    <label class="titulo-conyugue">Datos del Cónyuge</label>
      <div class="form-row-telefono">
        <div class="form-group-educacion container-educacion">
          <div class="tipo-doc-conyugue">
            <label>Tipo de Documento</label>
            <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="documents" [isReadonly]="elementsReadOnly.includes('conyugueDocumentType')"
            [(selectedValue)]="conyugueDocumentType"  (selectedValueChange)="conyugueDocumentType=$event" [isError]="isErrorDocumenTypeSpouse">
            </app-custom-inpunt-select>
          </div>
          <div class="nro-doc-conyuge">
            <label>N° documento</label>
            <app-custom-input [(value)]="conyugueDocumentNumber" [isError]="isErrorDocumenNumberSpouse" [isReadonly]="elementsReadOnly.includes('conyugueDocumentNumber')"
            (keyup)="resetErrorState('conyugueDocumentNumber')" ></app-custom-input>
          </div>
        </div>

        <div class="form-group-educacion container-educacion">
          <div class="nombre-conyugue">
            <label>Nombres</label>
            <app-custom-input [(value)]="conyugueNames" [isError]="isErrorSpouseNames" [isReadonly]="elementsReadOnly.includes('conyugueNames')"
            (keyup)="resetErrorState('conyugueNames')" ></app-custom-input>
          </div>
          <div class="ap-paterno-conyugue">
            <label>Apellido Paterno</label>
            <app-custom-input [(value)]="conyugueLastName" [isError]="isErrorSpouseLastName" [isReadonly]="elementsReadOnly.includes('conyugueLastName')"
            (keyup)="resetErrorState('conyugueLastName')" ></app-custom-input>
          </div>
        </div>
      </div>  
      
      <div class="form-row-telefono">
        <div class="form-group-educacion container-educacion">
          <div class="ap-materno-conyugue">
            <label>Apellido Materno</label>
            <app-custom-input [(value)]="conyugueSecondLastName" [isError]="isErrorSpouseSecondLastName" [isReadonly]="elementsReadOnly.includes('conyugueSecondLastName')"
            (keyup)="resetErrorState('conyugueSecondLastName')" ></app-custom-input>
          </div>
        </div>
      </div>

  </div>
</div>
<!-- <div *ngIf="esMovil" class="formulario-container-left">
  <div class="form-row-left">
    <div class="form-group">
      <label>País de domicilio</label>
      <app-custom-inpunt-select placeholder="Seleccionar" [width]="'190px'"></app-custom-inpunt-select>
    </div>
  </div>
</div> -->
<div class="container-buttons">
  <div class="footer-buttons">
    <a href="#" class="link-atras">ATRÁS</a>
    <app-custom-button text="Siguiente" (action)="validarYCambiarComponente()"></app-custom-button>
</div>
</div>

<app-adjunto-modal *ngIf="showModal" 
  (onClose)="handleClose()" 
  (onFileSelectedAction)="handleSelectFileAction($event)" 
  (onFileDownloadAction)="handleFileDownload($event)" 
  (onFileDeleteAction)="handleFileDelete($event)" 
  [applicationFiles]="filesList"
  [(value)]="comment"
  (onSave)="sendCommentHandler()">
</app-adjunto-modal>

<loading *ngIf="loading"></loading>