export class ClienteSession {
    documentTypeDesciption!: string;
    documentType!: string;
    documentNumber!: string;
    fullName!: string;
    userCreation!: string;
    offersearchdate!: Date;
    maritalStatus!: string;
    documentTypeSpouse!: string;
    documentNumberSpouse!: string;
    score!: number;
    riskAssessment!: RiskAssessment;
    admissionIdentifier!: string;
}

export class ProductoSession {
    image!: string;
    offerid!: string;
    campaignCode!: string;
    amount!: number;
    product!: string;
    subproductCode!: string;
    name!: string;
    nameBase!: string;
    validityStart!: string;
    validityEnd!: string;
    tea!: string;
    installments!: number;
    installmentsMax!: number;
    currency!: string;
    currencySymbol!: string;
    priority!: string;
    type!: string;
    offerProductCode!: string;
    offerSubProductCode!: string;
}

export class RiskAssessment {
    order!: number;
    name!: string;
    value!: string;
    result!: string;
}