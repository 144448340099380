<app-header [headerText]="'Inicio '" headerImageSrc="assets/svg/inicio.svg"></app-header>

<div class="layout-container">
  <div class="img-inicio">
    <img src="assets/fondo_inicio.svg">
  </div>

  <div class="texto-bienvenida">
    <p><label class="texto-bienvenidos">BIENVENIDOS</label> a la plataforma de venta asistida del banco 
      <label class="texto-banbif">BanBif</label></p>
    
  </div>
</div>

<app-footer></app-footer>