<div class="modal-overlay" (click)="close()">
  <div class="modal-content" [ngStyle]="{'width.px': width, 'height.px': 'auto'}">
    <div class="modal-header">
      <img [src]="imageUrl" alt="Modal Image" class="modal-image">
    </div>
    <div class="modal-body">
      <h2 class="modal-title">{{ titleText }}</h2>
      <h3 class="modal-subtitle">{{ subtitleText }}</h3>
      <p class="modal-paragraph">{{ paragraphText }}</p>
      <div *ngIf="!showCustomButtons">
        <a class="modal-link" (click)="onLinkClick()">{{ linkText }}</a>
      </div>      
    </div>
    <div *ngIf="showCustomButtons" class="modal-footer">
      <app-custom-button-color text="CERRAR" (action)="close()"></app-custom-button-color>
      <app-custom-button text="ACEPTAR" (action)="save()"></app-custom-button>
    </div>   
  </div>
</div>
