import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CustomButtonComponent } from '../custom-button';
import { CustomButtonColorComponent } from '../custom-button-color';
import { Router } from '@angular/router';
import { AppConstants } from 'app/shared/constants/app-constants';
import { ApplicationService } from 'app/services/application.service';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';
import { SharedModule } from 'app/shared/modules/shared/shared.module';
import { ClienteSession, ProductoSession } from 'app/models/sessionModel';
import { PdfmakeService } from 'app/services/pdfmake.service';
import { lastValueFrom } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, CustomButtonComponent, CustomButtonColorComponent, SharedModule],
  selector: 'app-custom-table-simulator',
  templateUrl: './custom-table-simulator.component.html',
  styleUrls: ['./custom-table-simulator.component.scss']
})
export class CustomTableSimulatorComponent implements OnInit {
  @Input() simulationCount: number = 3;  // Valor por defecto es 1

  client: ClienteSession = JSON.parse(sessionStorage.getItem(AppConstants.Session.CLIENT) ?? '{}');
  product: ProductoSession = JSON.parse(sessionStorage.getItem(AppConstants.Session.PRODUCT) ?? '{}');
  usuario: string = sessionStorage.getItem(AppConstants.Session.USERID) ?? '';
  agenciaName: string = sessionStorage.getItem(AppConstants.Session.AGENCYNAME) ?? '';
  agenciaCode: string = sessionStorage.getItem(AppConstants.Session.AGENCYCODE) ?? '';
  canalVenta: string = sessionStorage.getItem(AppConstants.Session.SALES_CHANNEL_DESCRIPTION) ?? '';
  rolName: string = sessionStorage.getItem(AppConstants.Session.USERROLNAME) ?? '';
  rolCode: string = sessionStorage.getItem(AppConstants.Session.USERROLID) ?? '';
  userName: string = sessionStorage.getItem(AppConstants.Session.USERNAME) ?? '';
  salesChannel: string = sessionStorage.getItem(AppConstants.Session.SALESCHANNEL) ?? '';
  salesSubChannel: string = sessionStorage.getItem(AppConstants.Session.SALESSUBCHANNEL) ?? '';
  offers: any[] = JSON.parse(sessionStorage.getItem(AppConstants.Session.OFFERS) ?? '[]');
  enterpriseId: string = sessionStorage.getItem(AppConstants.Session.ENTERPRISE) ?? '0';

  constructor(
    private router: Router,
    private applicationService: ApplicationService,
    private alertService: AlertService,
    private pdfmakeService: PdfmakeService
  ) {
    
  }
  showButton: boolean[] = [];
  showDownloadButton: boolean = false;
  loading: boolean = false;

  tableData = {
    header: [],
    rows: [
      ['N° de Cuotas'],
      ['Monto del Préstamo'],
      ['Fecha de Desembolso'],
      ['Día de Pago'],
      ['Fecha Primer Vencimiento'],
      ['Días de Gracia'],
      ['TEA'],
      ['TCEA'],
      ['Seguro Desgravamen'],
      ['S. Vida'],
      ['S. Desempleo'],
      ['S. Salud'],
      ['Monto a Financiar'],
      ['Cuota a pagar'],
      ['Total Intereses']
    ]
  };

  arraySimulador: any [] = [];
  arrayOptionalInsurances: any [] = [];
  simulation: any;

  ngOnInit() {
    this.initializeTableData();
  }

  initializeTableData() {
    for (let i = 1; i <= this.simulationCount; i++) {
      this.tableData.rows.forEach(row => row.push(''));
    }
    this.showButton = new Array(this.simulationCount).fill(false);
  }

  fillColumn(columnIndex: number) {
    if (columnIndex >= 1 && columnIndex <= this.simulationCount) {
      for (let i = 0; i < this.tableData.rows.length; i++) {
        let simulatedValue = '';
        if (i === 0) {
          const cuotasOptions = [12, 18, 36];
          simulatedValue = cuotasOptions[Math.floor(Math.random() * cuotasOptions.length)].toString();
        } else {
          const monto = (Math.random() * 1000 + 100).toFixed(2);
          const prefixOrSuffix = Math.random();
          if (prefixOrSuffix < 0.33) {
            simulatedValue = 'S/' + monto;
          } else if (prefixOrSuffix < 0.66) {
            simulatedValue = monto + '%';
          } else {
            simulatedValue = monto;
          }
        }
        this.tableData.rows[i][columnIndex] = simulatedValue;
      }
      this.showButton[columnIndex - 1] = true;
      this.showDownloadButton = true;  // Mostrar el botón "Descargar Cronograma"
    }
  }

  addSimulation(rows: any, columnIndex: number, opcionalInsurances: any) {
    this.simulation = rows;
    // rows.loanSimulationDatailModel = null;
    this.arraySimulador[columnIndex] = rows;
    this.arrayOptionalInsurances[columnIndex] = opcionalInsurances;
    
    for (let i = 0; i < this.tableData.rows.length; i++) {
      let simulatedValue = '';

      switch (i) {
        case 0:
          simulatedValue = rows.numberQuotas.toString();
          break;
        case 1:
            simulatedValue = rows.loanAmountFormat.toString();
            break;                
        case 2:
          simulatedValue = rows.disbursementDate;
          break;
        case 3:
          simulatedValue = rows.paymentDay.toString();
          break;
        case 4:
          simulatedValue = rows.firstExpiration;
          break;
        case 5:
          simulatedValue = rows.daysGrace.toString();
          break;
        case 6:
          simulatedValue = rows.teaFormat;
          break;
        case 7:
          simulatedValue = rows.tceaFormat;
          break;
        case 8:
          simulatedValue = rows.desgravamenFormat;
          break;
        case 9:
          simulatedValue = rows.lifeInsuranceFormat;
          break;
        case 10:
          simulatedValue = rows.unemploymentInsuranceFormat;
          break;
        case 11:
          simulatedValue = rows.healthInsuranceFormat;
          break;
        case 12:
          simulatedValue = rows.amountFinancedFormat;
          break;
        case 13:
            simulatedValue = rows.quotaAmountFormat;
            break;
        case 14:
          simulatedValue = rows.totalInterest;
          break;                   
        default:
          simulatedValue = '';
          break;
      }

      this.tableData.rows[i][columnIndex+1] = simulatedValue;
    }
    this.showButton[columnIndex] = true;
    this.showDownloadButton = true;
  }

  clearSimulation(columnIndex: number) {
    this.arraySimulador = [];
    this.arrayOptionalInsurances = [];
    
    for (let i = 0; i < this.tableData.rows.length; i++) {
      this.tableData.rows[i][columnIndex+1] = '';
    }

    this.showButton[columnIndex] = false;
    this.showDownloadButton = false; 
  }

  redireccionarAutenticacion(columnIndex: number) {
    sessionStorage.setItem(AppConstants.Session.SIMULACION, JSON.stringify(this.arraySimulador[(columnIndex - 1)]));
    sessionStorage.setItem(AppConstants.Session.SIMULACION_OPTIONALA_INSURANCES, JSON.stringify(this.arrayOptionalInsurances[(columnIndex - 1)]));

    let simulationSelect: any = this.arraySimulador[(columnIndex - 1)];
    let optionalInsurancesSelect: any = this.arrayOptionalInsurances[(columnIndex - 1)];

    let model = {
      countryCode: "604",
      enterpriseId: this.addLeadingZeros(+this.enterpriseId),
      currencyCode: "SOL",
      applicationAmount: simulationSelect.loanAmount,
      numberInstallments: simulationSelect.numberQuotas,
      gracePeriod: 0,
      payday: simulationSelect.paymentDay,
      firstaPaymentDate: simulationSelect.firstExpiration.split('/').reverse().join('-'),
      monthlyFee: simulationSelect.quotaAmount,
      tea: parseFloat(simulationSelect.tea.toString()).toFixed(6),
      tcea: parseFloat(simulationSelect.tcea.toString()).toFixed(6),
      channelId: AppConstants.Channels.DesktopPVA,
      salesChannelId: +this.salesChannel,
      salesSubChannelId: this.salesSubChannel === 'null' ? null : this.salesSubChannel,
      disbursementDate: simulationSelect.disbursementDate.split('/').reverse().join('-'),
      offerSearchDate: this.client.offersearchdate,
      offerId: this.product.offerid,
      offerValidityFrom: this.product.validityStart,
      offerValidityUntil: this.product.validityEnd,
      offerProductCode: this.product.offerProductCode,
      offerSubProductCode: this.product.offerSubProductCode,
      degravamenCode: simulationSelect.desgravamenCode,
      degravamen: simulationSelect.desgravamen,
      documentType: this.client.documentType,
      documentNumber: this.client.documentNumber,
      processId: AppConstants.Process.PLD_DRACMA_APROBACION,
      taskId: AppConstants.WorkflowTasks.PRODUCT_EVALUATION,
      taskInstanceRole: this.rolName,
      createdby: this.usuario,
      createdbyFullName: this.userName,
      agencyCode: this.agenciaCode,
      agencyName: this.agenciaName,
      optionalInsurancesContracted: optionalInsurancesSelect,
      campaignOffers: this.getOfferIdsString(),
      offerAmountMax: this.product.amount,
      offerTermMax: this.product.installmentsMax,
      offerTermMin: this.product.installments,
      score: this.client.score,
      admissionIdentifier: this.client.admissionIdentifier,
      riskAssessment: this.client.riskAssessment
    };

    let modelValidation = {
      documentType: this.client.documentType,
      documentNumber: this.client.documentNumber,
      userId: this.usuario,
      product: AppConstants.Products.Loans
    };

    this.validateApplicationInProgress(modelValidation, model);
    // this.addRegisterApplication(model);

  }

  descargarCronograma() {
    console.log('Descargando cronograma...');
    this.pdfmakeService.openSchedule(this.simulation);
  }

  addRegisterApplication(model: any) {
    this.loading = true;
    this.applicationService.postRegisterApplication(model).subscribe({
      next: async (response: any) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.APPLICATION_TITLE, AppConstants.MessageAlert.REGISTER_APPLICATION_SUCCESS, MessageSeverity.success);
        sessionStorage.setItem(AppConstants.Session.APPLICATION_ID, response.applicationId);
        sessionStorage.setItem(AppConstants.Session.WORKFLOW_ID, response.workflowIntanceId);
        await this.uploadAutoCronograma(response.applicationId, `${this.client.documentNumber}-Cronograma-Pago.pdf`);
        this.router.navigate(['datos-personales']);
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.APPLICATION_TITLE, AppConstants.MessageAlert.REGISTER_APPLICATION_ERROR, MessageSeverity.error);
        console.error(error);
      }
    });
  }

  addLeadingZeros(num: number, length: number = 3): string {
    return num.toString().padStart(length, '0');
  }

  getOfferIdsString(): string {
    let offersId = '';
    try {
      offersId = this.offers.map((element: any) => element.offerid).filter((id): id is string => id != null).join(';');
    } catch (error) {
      console.log(error);      
    }
    return offersId;
  }

  async uploadAutoCronograma(applicationId: string, fileName: string) {
    try {

      var keyValue = this.uuidv4() + '.pdf';

      let model = {
        applicationId: applicationId,
        file: {
          name: fileName,
          type: AppConstants.AttachmentMode.Customer,
          fileId: keyValue,
          fileBase64: await this.pdfmakeService.getFile(this.simulation),
          contenType: 'application/pdf'
        }
      };

      await this.uploadFile(model);

    } catch (error) {
      console.error(error);
      this.loading = false;
      this.alertService.showMessage(AppConstants.TitleAlert.UPLOAD_FILES, AppConstants.MessageAlert.UPLOAD_FILES_CRONOGRAMA_MESSAGE_ERROR, MessageSeverity.warn, 8000);
    }
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
  }

  async uploadFile(model: any) {
    this.loading = true;
    await lastValueFrom(this.applicationService.postUploadFile(model)).then((response: any) => {
      this.loading = false;
      this.alertService.showMessage(AppConstants.TitleAlert.UPLOAD_FILES, AppConstants.MessageAlert.UPLOAD_FILES_CRONOGRAMA_MESSAGE_OK, MessageSeverity.success, 8000);
    },
    (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.UPLOAD_FILES, AppConstants.MessageAlert.UPLOAD_FILES_CRONOGRAMA_MESSAGE_ERROR, MessageSeverity.error, 8000);
        console.error(error);
    });
  }

  validateApplicationInProgress(modelValidation: any, model: any) {
    this.loading = true;
    this.applicationService.returnApplication(modelValidation).subscribe({
      next: (response: HttpResponse<any>) => {
        if (response.status === 204) {
          this.addRegisterApplication(model);
        } else {
          this.loading = false;
          this.alertService.showMessage('Error', 'La solicitud '+ response.body.applicationId +' ya esta siendo tomada por el usuario ' + response.body.createdBy, MessageSeverity.error);
        }
      },
      error: (error: HttpErrorResponse) => {
        this.loading = false;
        console.log('Error:', error);
        this.alertService.showMessage(AppConstants.TitleAlert.RETRIEVE_APPLICATION_TITLE, AppConstants.MessageAlert.RETRIEVE_APPLICATION_MESSAGE, MessageSeverity.error);
      },
      complete: () => {
      }
    });
  }

}
