import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SimuladorOfertaPldBancoRoutingModule } from './simulador-oferta-pld-banco-routing.module';
import { SimuladorOfertaPldBancoComponent } from './simulador-oferta-pld-banco.component';
import { CustomButtonComponent, CustomButtonColorComponent, CustomInpuntSelectComponent, HeaderComponent, FooterComponent, CustomInputComponent, CustomTableSimulatorComponent, LineDividerComponent, BackLinkComponent, CustomDatepickerComponent } from 'app/components';
import { CustomInputAutocompleteComponent } from 'app/components/custom-input-autocomplete';
import { InputMaterialComponent } from 'app/components/input-material';
import { SharedModule } from 'app/shared/modules/shared/shared.module';


@NgModule({
  declarations: [
    SimuladorOfertaPldBancoComponent
  ],
  imports: [
    CommonModule,
    SimuladorOfertaPldBancoRoutingModule,
    CustomButtonComponent,
    CustomButtonColorComponent,
    CustomInpuntSelectComponent,
    HeaderComponent,
    FooterComponent,
    CustomInputComponent,
    CustomTableSimulatorComponent,
    LineDividerComponent,
    BackLinkComponent,
    CustomDatepickerComponent,
    InputMaterialComponent,
    CustomInputAutocompleteComponent,
    SharedModule
  ]
})
export class SimuladorOfertaPldBancoModule { }
