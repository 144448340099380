<app-header [headerText]="' Visualizando producto'" headerImageSrc="assets/envio.svg"></app-header>
<div class="contenedor-informacion">

  <div class="informacion-producto">
    <div class="container-izquierdo">
      <div class="lado-izquierdo">
        <span class="punto"></span>
        <span class="texto-producto">Producto</span>
      </div>
    </div>
  </div>

  <div class="informacion-producto">
    <div class="caja-redondeada-sombreada">
      <div class="contenido-caja">
        <div class="contenido-caja-container">
          <div class="seccion-imagen">
            <img src="{{customerApplication.productNamImage}}" alt="Descripción de la imagen">
            <div class="detalle-titulo">
              <span class="titulo-producto"> {{customerApplication.productName}}</span>
              <p class="valor-numerico">
                {{ customer?.applicationAmount | currency: (customer?.currencyCode === 'SOL' ? 'PEN' : 'USD'): (customer?.currencyCode === 'SOL' ? 'S/ ' : '$') }}
              </p>
            </div>
          </div>
          <div class="container-detalles">
            <div class="seccion-detalles">
              <div class="detalle detalle-productos">
                <p>
                  <span class="titulo">Valor de cuota:
                  </span>
                  {{ customer?.monthlyFee | currency: (customer?.currencyCode === 'SOL' ? 'PEN' : 'USD'): (customer?.currencyCode === 'SOL' ? 'S/' : '$') }}
                </p>
                <p><span class="titulo">TEA:</span> {{ customer?.tea }} %</p>
                <p><span class="titulo">{{ customer?.desgravamenCode === '005' ? 'Seguro Desgravamen Básico:' : customer?.desgravamenCode === '008' ? 'Seguro Desgravamen con Devolución:' : 'Desgravamen:' }}</span> {{ customer?.desgravamen }} %</p>
                <p><span class="titulo">Primer pago:</span> {{ firstPaymentDateFormat | dateFormat }} </p>
                <p><span class="titulo">Fecha de Desembolso:</span> {{ customer?.disbursementDate | dateFormat }}</p>
              </div>
              <div class="detalle detalle-productos">
                <p><span class="titulo">Número de cuotas:</span> {{ customer?.numberInstallments }}</p>
                <p><span class="titulo">TCEA:</span> {{ customer?.tcea }} %</p>              
              </div>
            </div>
            <div *ngIf="optionalInsurancesContracted.length > 0">
              <hr class="linea-separacion">

              <div class="seccion-detalles">
                <div class="detalle detalle-productos" *ngFor="let seguro of optionalInsurancesContracted">
                  <p class="titulo-seguro">{{ seguro.insuranceName }}</p>
                  <p class="nombre-plan">{{ seguro.planName }}</p>
                  <p><span class="titulo">Monto bruto prima única</span> {{ seguro?.amountBruto | currency: (seguro?.currencyCode === 'SOL' ? 'PEN' : 'USD'): (seguro?.currencyCode === 'SOL' ? 'S/' : '$') }}</p>
                  <p><span class="titulo">Monto neto prima única</span>  {{ seguro?.amountNeto | currency: (seguro?.currencyCode === 'SOL' ? 'PEN' : 'USD'): (seguro?.currencyCode === 'SOL' ? 'S/' : '$') }}</p>
                </div>
              </div>
            </div>  
          </div>

          
        </div>

      </div>
    </div>
    
  </div>

  <div class="informacion-producto">
    <div class="detalles-personales">
      <div class="producto-container">
        <div class="titulo-detalle">
          <img src="assets/img/circulo.svg" alt="Descripción de la imagen" class="img-circulo"><p class="valor-numerico">Datos Personales</p>
        </div>
        <div class="inf-detalle">
          <p><span class="titulo">Nombre:</span> {{ customer?.name }} {{ customer?.middleName}} {{ customer?.lastname }} {{ customer?.secondLastname}}</p>
          <p><span class="titulo">DNI:</span> {{ customer?.documentNumber }}</p>
        </div>
      </div>
      <div class="boton-editar">
        <div class="detalle-boton" *ngIf="!onlyView">
          <app-custom-button-color text="EDITAR" (click)="openModal(); $event.preventDefault()"></app-custom-button-color>
        </div>
        <div class="detalle-boton" *ngIf="onlyView">
          <img src="assets/ojoColor.svg" class="img-ojo">
          <a href="#" class="link-editar" (click)="openModal(); $event.preventDefault()">VER</a>
        </div>
      </div>
    </div>
  </div>

  <div class="informacion-producto">
    <div class="detalles-personales">
      <div class="producto-container">
        <div class="titulo-detalle">
          <img src="assets/img/circulo.svg" alt="Descripción de la imagen" class="img-circulo"><p class="valor-numerico">Datos de Dirección</p>
        </div>
        <div class="inf-detalle">
          <p> {{ customer?.customerStreetName }} {{ customer?.customerStreetNumber }}</p>    
        </div>
      </div>
      <div class="boton-editar">
        <div class="detalle-boton" *ngIf="!onlyView">
          <app-custom-button-color text="EDITAR" (click)="openModalDireccion(); $event.preventDefault()"></app-custom-button-color>
        </div>
        <div class="detalle-boton" *ngIf="onlyView">
          <img src="assets/ojoColor.svg" class="img-ojo">
          <a href="#" class="link-editar" (click)="openModalDireccion(); $event.preventDefault()">VER</a>
        </div>
      </div>
    </div>
  </div>

  <div class="informacion-producto">
    <div class="detalles-personales">
      <div class="producto-container">
        <div class="titulo-detalle">
          <img src="assets/img/circulo.svg" alt="Descripción de la imagen" class="img-circulo"><p class="valor-numerico">Otros Datos</p>
        </div>
        <div class="inf-detalle">
          <p> Datos del Empleador:</p>          
        </div>
        <div class="inf-detalle">
          <p><span class="titulo">RUC Empresa:</span> {{ customer?.companyCode }}</p>
          <p><span class="titulo">Nombre Empresa:</span> {{ customer?.companyName }}</p>
        </div>
      </div>
      <div class="boton-editar">
        <div class="detalle-boton" *ngIf="!onlyView">
          <app-custom-button-color text="EDITAR" (click)="openModalOtros(); $event.preventDefault()"></app-custom-button-color>
        </div>        
        <div class="detalle-boton" *ngIf="onlyView">
          <img src="assets/ojoColor.svg" class="img-ojo">
          <a href="#" class="link-editar" (click)="openModalOtros(); $event.preventDefault()">VER</a>
        </div>
      </div>
    </div>
  
  </div>

  <div class="informacion-producto">
    <div class="detalles-personales">
      <div class="producto-container">
        <div class="titulo-detalle">
          <img src="assets/img/circulo.svg" alt="Descripción de la imagen" class="img-circulo"><p class="valor-numerico">Modo de Desembolso</p>
        </div>
        <div class="inf-detalle">
          <p> {{ disbusementName }}</p>
          <div *ngIf="customer?.disbursementType==2">
            <p><span class="titulo">Numero de Cuenta:</span> {{ customer?.currentAccountNumber}}</p>
          </div>
          <div *ngIf="customer?.disbursementType==3">
            <p><span class="titulo">CCI:</span> {{ customer?.interbankAccountNumber}}</p>
          </div>
        </div>
        <div class="inf-detalle" *ngIf="customer?.disbursementType==3">
          <p><span class="titulo">Banco Destino:</span> {{ customer.destinationBankName}}</p>
        </div>
      </div>
      <div class="boton-editar">
        <div class="detalle-boton" *ngIf="!onlyView">
          <app-custom-button-color text="EDITAR" (click)="openModalDesembolso(); $event.preventDefault()"></app-custom-button-color>
        </div>          
        <div class="detalle-boton" *ngIf="onlyView">
          <img src="assets/ojoColor.svg" class="img-ojo">
          <a href="#" class="link-editar" (click)="openModalDesembolso(); $event.preventDefault()">VER</a>
        </div>
      </div>
    </div>
  </div>

  <div class="informacion-producto">
    <div class="detalles-personales">
      <div class="producto-container">
        <div class="titulo-detalle">
          <img src="assets/img/circulo.svg" alt="Descripción de la imagen" class="img-circulo"><p class="valor-numerico">Archivos Adjuntos</p>
        </div>
        <div *ngFor="let file of filesList" class="inf-detalle">
          <p>{{ file.name }}</p>
        </div>
      </div>
      <div class="boton-editar">
        <div class="detalle-boton" *ngIf="!onlyView">
          <app-custom-button-color text="EDITAR" (click)="openModalAdjuntos(); $event.preventDefault()"></app-custom-button-color>
        </div>
        <div class="detalle-boton" *ngIf="onlyView">
          <img src="assets/ojoColor.svg" class="img-ojo">
          <a href="#" class="link-editar" (click)="openModalAdjuntos(); $event.preventDefault()">VER</a>
        </div>
      </div>
    </div>
  </div>

  <div class="informacion-producto">
    <div class="detalles-personales">
      <div class="producto-container">
        <div class="titulo-detalle">
          <img src="assets/img/circulo.svg" alt="Descripción de la imagen" class="img-circulo"><p class="valor-numerico">Documentos Contractuales</p>
        </div>
        <div class="inf-detalle">
          <label>F90000-059.pdf</label>
          <span class="icon-container">
            <img src=".././assets/descargar.svg" class="download-icon" (click)="download('F90000-059')" title="F90000-059">
          </span>
        </div>
        <div class="inf-detalle">
          <label>F90000-060.pdf</label>
          <span class="icon-container">
            <img src=".././assets/descargar.svg" class="download-icon" (click)="download('F90000-060')" title="F90000-060">
          </span>
        </div>
        <div class="inf-detalle">
          <label>F90000-320.pdf</label>
          <span class="icon-container">
            <img src=".././assets/descargar.svg" class="download-icon" (click)="download('F90000-320')" title="F90000-0320">
          </span>
        </div>
      </div>
      <div class="boton-editar">
        <div class="detalle-boton" *ngIf="!onlyView">
          <app-custom-button-color text="EDITAR" (click)="openModalAdjuntos(); $event.preventDefault()"></app-custom-button-color>
        </div>
        <div class="detalle-boton" *ngIf="onlyView">
          <img src="assets/ojoColor.svg" class="img-ojo">
          <a href="#" class="link-editar" (click)="openModalAdjuntos(); $event.preventDefault()">VER</a>
        </div>
      </div>
    </div>
  </div>

  <div class="informacion-comentario aprobar-solicitud">
    <div class="contenedor-confirmacion-comentario">
        <p class="titulo-confirmacion">
            <span class="icon-comment"></span> Historial de Comentarios
        </p>
        <div class="comments-section">
            <div class="comment" *ngFor="let comment of comments">
                <div class="comment-header">
                    <span class="date">{{ comment?.date }}</span>
                    <span class="user">Por {{ comment?.user }}</span>
                </div>
                <div class="comment-body">
                    <p class="title">{{ comment?.reason }}</p>
                    <p class="status">{{ comment?.message }}</p>
                </div>
            </div>
        </div>
        <div *ngIf="!onlyView" class="datos-solicitud" style="margin-top: 2rem;">
          <app-custom-text-area [(value)]="commentSeller"></app-custom-text-area>
        </div>
        <div *ngIf="!onlyView" class="modal-footer" style="margin-top: 2rem;">
          <app-custom-button text="AGREGAR COMENTARIO" (action)="sendCommentHandler()"></app-custom-button>
        </div> 
    </div>
  </div>


  <div class="informacion-producto aprobar-solicitud" *ngIf="approvingRole && (customerApplication.stateCode === 9 || customerApplication.stateCode === 4)">
    <div class="contenedor-confirmacion">
      <p class="titulo-confirmacion">¿Desea aprobar la solicitud?</p>
      <div class="contenedor-confirmacion-boton">
        <div class="contenedor-aceptado">
          <app-custom-button-color 
            [text]="'Aceptado'" 
            [iconUrl]="acceptedStyles.iconUrl"
            [buttonWidth]="'140px'"
            [backgroundColor]="acceptedStyles.backgroundColor"
            [textColor]="acceptedStyles.textColor"
            [isActive]="acceptedStyles.isActive"
            (action)="onAcceptedClicked()">
          </app-custom-button-color>
        
          <app-custom-button-color 
            [text]="'Observado'" 
            [iconUrl]="observedStyles.iconUrl"
            [buttonWidth]="'140px'"
            [backgroundColor]="observedStyles.backgroundColor"
            [textColor]="observedStyles.textColor"
            [isActive]="observedStyles.isActive"
            (action)="onObservedClicked()">
          </app-custom-button-color>
        </div>
      
        <div class="contenedor-aceptado">
          <app-custom-button-color 
            [text]="'Rechazado'" 
            [iconUrl]="rechazadoStyles.iconUrl"
            [buttonWidth]="'140px'"
            [backgroundColor]="rechazadoStyles.backgroundColor"
            [textColor]="rechazadoStyles.textColor"
            [isActive]="rechazadoStyles.isActive"
            (action)="onRechazadoClicked()">
          </app-custom-button-color>
        </div>
      </div>

      <div class="datos-solicitud">
        <app-custom-inpunt-select placeholder="Seleccionar" [width]="'190px'" [options]="filterReasonList"
            [(selectedValue)]="reasonSelect"
            (selectedValueChange)="changeReason()">
        </app-custom-inpunt-select>
        <app-custom-text-area [(value)]="comment"></app-custom-text-area>
      </div>
     
      <p class="titulo-confirmacion">Ingresa datos de Usuario</p>
      <div class="contenedor-supervisor">
        <div class="form-group">
          <label>Usuario</label>
          <app-custom-input [(value)]="username"></app-custom-input>
        </div>
        <div class="form-group">
          <label>Contraseña</label>
          <app-custom-input-password [(value)]="password"></app-custom-input-password>
        </div>
      </div>

      <div class="contenedor-boton-confirmacion">
        <a href="#" class="link-editar" (click)="returnTray(); $event.preventDefault()">CANCELAR</a>
        <app-custom-button text="Enviar" (action)="updateStateApplication()"></app-custom-button>
      </div>
    
    </div>
  </div>

  <div *ngIf="isObservado" class="contenedor-boton">
    <app-custom-button-color  [buttonWidth]="'160px'" text="CANCELAR" (action)="canelHandler()"></app-custom-button-color>
    <app-custom-button text="ENVIAR" (action)="sendHandler()"></app-custom-button>
  </div>

  <div *ngIf="supervisorRole && customerApplication.stateCode === 3" class="contenedor-boton">
    <app-custom-button-color  [buttonWidth]="'160px'" text="CANCELAR" (action)="canelHandler()"></app-custom-button-color>
    <app-custom-button text="ENVIAR A APROBACION" (action)="sendApprovalHandler()"></app-custom-button>
  </div>

 <div class="informacion-producto">
    <div class="contenedor-resumen">

    <div class="resumen-estado">
      <img src="assets/calendario.svg" alt="">
      <div class="texto-resumen">
        <span class="titulo">ESTADO</span>
        <p>{{ customerApplication.stateName }}</p>
      </div>
    </div>

    <div class="resumen-estado">
      <img src="assets/calendario.svg" alt="">
      <div class="texto-resumen">
        <span class="titulo">FECHA DE CREACIÓN</span>
        <p>{{ customerApplication.createdDateTimeFormat }}</p>
      </div>
    </div>

    <div class="resumen-estado">
      <img src="assets/calendario.svg" alt="">
      <div class="texto-resumen">
        <span class="titulo">FECHA ÚLTIMA MODIFICACIÓN</span>
        <p>{{ customerApplication.updatedDateTimeFormat }}</p>
      </div>
    </div>

    
    </div>
  </div>
</div>
<app-footer></app-footer>

<app-info-modal *ngIf="showModal" [titulo]="'Datos Personales'" [width]="modalWidth" [height]="modalHeight"  (onClose)="handleClose()">
  <app-datos-generales-modal-bandeja [onlyView]="onlyView" (onClose)="handleClose()" (linkAction)="handleLinkAction($event)"></app-datos-generales-modal-bandeja>
</app-info-modal>

<app-info-modal *ngIf="showModalDireccion" [titulo]="'Datos de Dirección'" [width]="modalWidthDireccion" [height]="modalHeightDireccion" (onClose)="handleClose()">
  <app-datos-direccion-modal-bandeja [onlyView]="onlyView" (onClose)="handleClose()" (linkAction)="handleLinkAction($event)"></app-datos-direccion-modal-bandeja>
</app-info-modal>
<app-info-modal *ngIf="showModalOtros" [titulo]="'Otros Datos'" [width]="modalWidthOtros" [height]="modalHeightOtros" (onClose)="handleClose()">
  <app-datos-otros-modal-bandeja [onlyView]="onlyView" (onClose)="handleClose()" (linkAction)="handleLinkAction($event)"></app-datos-otros-modal-bandeja>
</app-info-modal>
<app-info-modal *ngIf="showModalDesembolso" [titulo]="'Modo de Desembolso'" [width]="modalWidthDesembolso" [height]="modalHeightDesembolso" (onClose)="handleClose()">
  <app-datos-desembolso-modal-bandeja [onlyView]="onlyView" (onClose)="handleClose()" (linkAction)="handleLinkAction($event)"></app-datos-desembolso-modal-bandeja>
</app-info-modal>

<app-datos-adjuntos-modal-bandeja *ngIf="showModalAdjuntos" [onlyView]="onlyView" (onClose)="handleClose()" (linkAction)="handleLinkAttachmentAction($event)"></app-datos-adjuntos-modal-bandeja>

<!-- <app-info-modal *ngIf="showModalAdjuntos" [titulo]="'Adjuntos'" [width]="modalWidthAdjuntos" [height]="modalHeightAdjuntos" (onClose)="handleClose()">
  <app-datos-adjuntos-modal-bandeja (onClose)="handleClose()"></app-datos-adjuntos-modal-bandeja>
</app-info-modal> -->
<app-modal-resumen *ngIf="showModalResumen" [width]="450" [height]="430" (linkAction)="accionPersonalizada()"
           [titleText]="tituloModal" [body]="updateApplication" (onClose)="handleCloseAlta()">
</app-modal-resumen>

<loading *ngIf="loading"></loading>