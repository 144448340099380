import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HostListener } from '@angular/core';
import { AppConstants } from 'app/shared/constants/app-constants';
import { lastValueFrom } from 'rxjs';
import { ApplicationService } from 'app/services/application.service';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';
import { UserService } from 'app/services/user.service';
import { ParameterService } from 'app/services/parameter.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-bandeja-solicitudes-producto',
  templateUrl: './bandeja-solicitudes-producto.component.html',
  styleUrls: ['./bandeja-solicitudes-producto.component.scss']
})
export class BandejaSolicitudesProductoComponent {

  customerApplication: any = JSON.parse(sessionStorage.getItem(AppConstants.Session.CUSTOMER_TRAY) ?? '{}');
  rolCode: string = sessionStorage.getItem(AppConstants.Session.USERROLID) ?? '';

  rolName: string = sessionStorage.getItem(AppConstants.Session.USERROLNAME) ?? '';
  usuario: string = sessionStorage.getItem(AppConstants.Session.USERID) ?? '';
  userName: string = sessionStorage.getItem(AppConstants.Session.USERNAME) ?? '';
  agenciaName: string = sessionStorage.getItem(AppConstants.Session.AGENCYNAME) ?? '';
  agenciaCode: string = sessionStorage.getItem(AppConstants.Session.AGENCYCODE) ?? '';

  esMovil: boolean = false;
  acceptedStyles = {
    backgroundColor: '#fff',
    textColor: '#20A3FF',
    iconUrl: 'assets/aceptar.svg',
    isActive: false
  };

  observedStyles = {
    backgroundColor: '#fff',
    textColor: '#20A3FF',
    iconUrl: 'assets/ojoColor.svg',
    isActive: false
  };

  rechazadoStyles = {
    backgroundColor: '#fff',
    textColor: '#20A3FF',
    iconUrl: 'assets/rechazar.svg',
    isActive: false
  };

  showModal = false;
  showModalDireccion = false;
  showModalResumen = false;
  showModalOtros = false;
  showModalDesembolso = false;
  showModalAdjuntos = false;
  modalWidth: number = 650; 
  modalWidthDireccion: number = 700; 
  modalHeightDireccion: number = 470;
  modalWidthAdjuntos: number = 550; 
  modalHeightAdjuntos: number = 300; 
  modalWidthDesembolso: number = 520; 
  modalHeightDesembolso: number = 370; 
  modalWidthOtros: number = 720; 
  modalHeightOtros: number = 850; 
  modalHeight: number = 450; 
  tituloModal='Proceso de Alta';
  textoEnlaceModal='CERRAR';

  loading: boolean = false;
  customer: any;
  disbusementName: string = '';
  firstPaymentDateFormat: string = '';
  optionalInsurancesContracted: any = [];
  updateApplication: any = {};

  approvingRole: boolean = this.rolCode === AppConstants.Roles.ANALISTA || this.rolCode === AppConstants.Roles.APROBADOR_OFICINA ? true : false;
  supervisorRole: boolean = this.rolCode === AppConstants.Roles.SUPERVISOR_CALL ? true : false;


  conditionValidateStateApplication: boolean = false;
  optionState: boolean = false;
  username: string = '';
  password: string = '';

  onlyView: boolean = true;

  filesList: any = [];
  reasonList: any = [];
  filterReasonList: any = [];
  reasonSelect: string = '';
  comment: string = '';
  commentSeller: string = '';

  comments: any = [];

  isObservado: boolean = true;

  statesFiler = [
    AppConstants.ApplicationStates.EnProcesoEvaluacion,
    AppConstants.ApplicationStates.EnProcesoAutentificacion,
    AppConstants.ApplicationStates.PendienteAutorizacion
  ];

  statesFilerSeller = [
    AppConstants.ApplicationStates.EnProcesoEvaluacion,
    AppConstants.ApplicationStates.EnProcesoAutentificacion,
    AppConstants.ApplicationStates.PendienteAutorizacion,
    AppConstants.ApplicationStates.Observado
  ];

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.esMovil = window.innerWidth <= 768;

  }
  
  constructor(
    private router: Router, 
    private cdr: ChangeDetectorRef,
    private applicationService: ApplicationService,
    private alertService: AlertService,
    private userService: UserService,
    private parameterService: ParameterService) 
  {
    this.esMovil = window.innerWidth <= 768;
  }

  async ngOnInit() {
    await this.getApplication(this.customerApplication.applicationId);
    await this.getOptionalInsurances(this.customerApplication.applicationId);
    await this.getAttachmentFiles(this.customerApplication.applicationId);
    await this.getParameters([
      AppConstants.ParameterCode.REASONS
    ]);
    await this.retrieveApplicationComments(this.customerApplication.applicationId);
  }

  handleClose() {

    this.showModal = false;
    this.showModalDireccion = false;
    this.showModalOtros = false;
    this.showModalDesembolso = false;
    this.showModalAdjuntos=false;
  }
  
  openModal() {
    this.showModal = true;
    if(this.esMovil) 
    {
      this.modalWidth = 350; 
      this.modalHeight = 600;
    } 
    else  this.modalWidth = 630;
  }

  openModalDireccion() {
    this.showModalDireccion = true;
    if(this.esMovil) 
    {
      this.modalWidthDireccion = 370;
      this.modalHeightDireccion=570;
    } 
    else  this.modalWidthDireccion = 700;
  }

  openModalOtros() {
    this.showModalOtros = true;
    if(this.esMovil) 
    {
      this.modalWidthOtros = 365;
      this.modalHeightOtros=600;
    } 
    else {
      this.modalWidthOtros = 720;
      this.modalHeightOtros=730;
    } 
  }

  openModalDesembolso() {
    this.showModalDesembolso = true;
    if(this.esMovil) 
    {
      this.modalWidthDesembolso = 365;
    } 
    else  this.modalWidthDesembolso = 550;
  }

  openModalAdjuntos() {
    this.showModalAdjuntos = true;

    if(this.esMovil) 
    {
      this.modalWidthAdjuntos = 350;
      this.modalHeightAdjuntos = 350;
    } else{
      this.modalWidthAdjuntos = 550;
    }
  }
  redireccionarFinalizacion(){
    this.updateApplication.comment = this.comment;
    this.updateApplication.reason = this.getLabelByValue(this.reasonSelect, this.filterReasonList);
    this.updateApplication.status = AppConstants.ApplicationStates.Aprobado;
    this.updateApplication.processId = AppConstants.Process.PLD_DRACMA_APROBACION;
    this.updateApplication.taskId = AppConstants.WorkflowTasks.APPROVAL;
    this.updateApplication.channelId = AppConstants.Channels.DesktopPVA;
    this.updateApplication.productCode = AppConstants.Products.Loans;
    this.updateApplication.nextStep = "bandeja-solicitudes";
    this.showModalResumen = true;
   }

  handleCloseResumen() {
    this.showModalResumen = false;
  }

  accionPersonalizada(){
    this.showModalResumen = false;
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    // this.modalWidth = 350; 
    // this.modalHeight = 600;
    // this.modalWidthDireccion = 370;
    // this.modalHeightDireccion=540;
    // this.modalWidthOtros = 365;
    // this.modalHeightOtros=600;
    // this.modalWidthDesembolso = 365;
    // this.modalWidthAdjuntos = 350;
    // this.modalHeightAdjuntos = 350;
  }
  onAcceptedClicked(): void {
    this.optionState = true;
    this.acceptedStyles.backgroundColor = '#BED700';
    this.acceptedStyles.textColor = '#FFF';
    this.acceptedStyles.iconUrl = 'assets/aceptarActivo.svg';
    this.acceptedStyles.isActive = true;

    this.observedStyles = {
      backgroundColor: '#fff',
      textColor: '#20A3FF',
      iconUrl: 'assets/ojoColor.svg',
      isActive: false
    };

    this.rechazadoStyles = {
      backgroundColor: '#fff',
      textColor: '#20A3FF',
      iconUrl: 'assets/rechazar.svg',
      isActive: false
    };

    this.filterReasonList = this.reasonList.filter((f: any) => f.type === AppConstants.OptionsAnalista.APROBADO);

    if (this.filterReasonList.length > 0) {
      this.reasonSelect = this.filterReasonList[0].value;
    } 
  }

  onObservedClicked(): void {
    this.optionState = true;
    this.observedStyles.backgroundColor = '#EDAB00';
    this.observedStyles.textColor = '#FFF';
    this.observedStyles.iconUrl = 'assets/ojoActivo.svg';
    this.observedStyles.isActive = true;

    this.acceptedStyles = {
      backgroundColor: '#fff',
      textColor: '#20A3FF',
      iconUrl: 'assets/aceptar.svg',
      isActive: false
    };

    this.rechazadoStyles = {
      backgroundColor: '#fff',
      textColor: '#20A3FF',
      iconUrl: 'assets/rechazar.svg',
      isActive: false
    };

    this.filterReasonList = this.reasonList.filter((f: any) => f.type === AppConstants.OptionsAnalista.OBSERVADO);

    if (this.filterReasonList.length > 0) {
      this.reasonSelect = this.filterReasonList[0].value;
    } 
  }

  onRechazadoClicked(): void {
    this.optionState = true;
    this.rechazadoStyles.backgroundColor = '#ED3900';
    this.rechazadoStyles.textColor = '#FFF';
    this.rechazadoStyles.iconUrl = 'assets/rechazarActivo.svg';
    this.rechazadoStyles.isActive = true;

    this.acceptedStyles = {
      backgroundColor: '#fff',
      textColor: '#20A3FF',
      iconUrl: 'assets/aceptar.svg',
      isActive: false
    };

    this.observedStyles = {
      backgroundColor: '#fff',
      textColor: '#20A3FF',
      iconUrl: 'assets/ojoColor.svg',
      isActive: false
    };

    this.filterReasonList = this.reasonList.filter((f: any) => f.type === AppConstants.OptionsAnalista.RECHAZADO);

    if (this.filterReasonList.length > 0) {
      this.reasonSelect = this.filterReasonList[0].value;
    } 
  }

  returnTray() {
    this.router.navigate(['bandeja-solicitudes']);
  }

  async getApplication(model: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getApplication(model)).then((response: any) => {
      this.firstPaymentDateFormat = response.firstaPaymentDate;
      this.customer = response;  
      this.onlyView = this.statesFilerSeller.filter(f => f == this.customer.state ).length > 0 && this.customer.createdBy === this.usuario ? false : 
                      this.statesFiler.filter(f => f == this.customer.state ).length > 0 && this.rolCode === AppConstants.Roles.SUPERVISOR_CALL ? false : true;
      this.isObservado = this.customer.state === AppConstants.ApplicationStates.Observado && this.customer.createdBy === this.usuario ? true : false;
      // this.onlyView = this.customer.state === AppConstants.ApplicationStates.Observado && this.customer.createdBy === this.usuario ? false : true;
      switch(response.disbursementType) {
        case 0:
          this.disbusementName = AppConstants.DisbursementName.WithoutDisbursement;
          break;
        case 1:
          this.disbusementName = AppConstants.DisbursementName.NewAccount;
          break;
        case 2:
          this.disbusementName = AppConstants.DisbursementName.ExistingAccount;
          break;
        case 3:
          this.disbusementName = AppConstants.DisbursementName.ImmediateInterbankTransfer;
          break;
        default:
      }
      
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  async getOptionalInsurances(model: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getOptionalInsurances(model)).then((response: any) => {
      this.optionalInsurancesContracted = response;
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  async updateStateApplication() {
    this.updateApplication = this.customerApplication;

    if(this.optionState) {
      if(this.username == '' || this.password  == '') {
        this.alertService.showMessage(AppConstants.TitleAlert.VALIDATION_APPLICATION, AppConstants.MessageAlert.APPLICATION_NOT_CREDENTIALS, MessageSeverity.warn);
        return;
      }
      await this.checkStatusApplication(this.customerApplication.applicationId);
      if(this.conditionValidateStateApplication) {
        let model = {
          userId: this.username,
          password: this.password
        };
        await this.validateUser(model);
      }
      else 
      {
        this.alertService.showMessage(AppConstants.TitleAlert.VALIDATION_APPLICATION, AppConstants.MessageAlert.APPLICATION_ANNULLED, MessageSeverity.warn);
      }

    }
    else {
      this.alertService.showMessage(AppConstants.TitleAlert.VALIDATION_APPLICATION, AppConstants.MessageAlert.APPLICATION_UPDATE_STATE_ERROR, MessageSeverity.warn);
    }

    

  }

  callFinishApplication() {
    
  }

  async checkStatusApplication(applicationId: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getCheckStatusApplication(applicationId)).then((response: any) => {
      this.conditionValidateStateApplication = response.active;
      this.loading = false;
    }).catch((error) => {
      this.conditionValidateStateApplication = false
      this.loading = false;
      console.error(error);
    });
  }

  async validateUser(model: any) {
    this.loading = true;
    await lastValueFrom(this.userService.postValidatUser(model)).then((response: any) => {
      this.loading = false;

      if(response?.userId === undefined) {
        console.log('error');
        this.alertService.showMessage("", AppConstants.MessageAlert.PASSWORD_DOESNT_CORRECT, MessageSeverity.error);
        return;
      }

      if(response?.userId != undefined && response?.userId != sessionStorage.getItem(AppConstants.Session.USERID))
      {
        this.alertService.showMessage("", AppConstants.MessageAlert.ERROR_USER_DISTICT, MessageSeverity.error);
        return;        
      }

      if(response?.userId != undefined && response?.userId == sessionStorage.getItem(AppConstants.Session.USERID) && response.roleId != AppConstants.Roles.APROBADOR_OFICINA)
      {
        this.alertService.showMessage("", AppConstants.MessageAlert.ERROR_USER_NOT_AUTHORIZED, MessageSeverity.error);
        return;        
      }
      if(this.acceptedStyles.isActive) {
        this.redireccionarFinalizacion();
        return;
      }

      if(this.observedStyles.isActive) {
        this.handleSaveDeclineApplication(AppConstants.ApplicationStates.Observado);
        return;
      }

      if(this.rechazadoStyles.isActive) {
        this.handleSaveDeclineApplication(AppConstants.ApplicationStates.Rechazado);
        return;
      }
      
    }).catch((error) => {
      this.conditionValidateStateApplication = false
      this.loading = false;
      console.error(error);
    });
  }

  handleLinkAction(data: { updateSucess: boolean }) {
    try {
      if(data.updateSucess) {
        this.getApplication(this.customerApplication.applicationId);
      }
    } catch (error) {
      console.error(error);
    }    
  }

  async getAttachmentFiles(model: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getFilesApplication(model)).then((response: any) => {
      this.filesList = response;
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  async handleLinkAttachmentAction(data: { updateSucess: boolean }) {
    try {
      if(data.updateSucess) {
        await this.getAttachmentFiles(this.customerApplication.applicationId);
      }
    } catch (error) {
      console.error(error);
    }    
  }

  handleSaveDeclineApplication(status: number) {
    const keyIndex = this.reasonList.findIndex((item: any) => item.key === this.reasonSelect);
    let model = {
      applicationId: this.customer.applicationId,
      workflowInstanceId: this.customer.workflowInstanceId,
      processId: AppConstants.Process.PLD_DRACMA_APROBACION,
      taskId: AppConstants.WorkflowTasks.APPROVAL,
      channelId: AppConstants.Channels.DesktopPVA,
      TaskInstanceRole: this.rolName,
      UpdatedBy: this.usuario,
      UpdatedByFullName: this.userName,
      AgencyCode: this.agenciaCode,
      AgencyName: this.agenciaName,
      CommentKey: this.reasonSelect,
      Comment: this.comment,
      Status: status,
      Reason: this.getLabelByValue(this.reasonSelect, this.filterReasonList)
    };

    this.cancellationApplication(model);
  }

  cancellationApplication(model : any): void {
    this.loading = true;
    this.applicationService.postDeclineApplication(model).subscribe({
      next: async (response: any) => {
        this.alertService.showMessage(AppConstants.TitleAlert.APPLICATION_UPDATE_TITLE, AppConstants.MessageAlert.UPDATE_APPLICATION_SUCCES, MessageSeverity.success);
        this.router.navigate(['bandeja-solicitudes']);
      },
      error: (error) => {
        this.alertService.showMessage(AppConstants.TitleAlert.APPLICATION_UPDATE_TITLE, error.error.message, MessageSeverity.error);
        console.error(error);
        this.loading = false;
        this.router.navigate(['bandeja-solicitudes']);
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  async getParameters(array: Array<number>) {
    this.loading = true;
    await lastValueFrom(this.parameterService.getParametersList(array)).then((response: any) => {
      this.setVariablesParameters(response);
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  setVariablesParameters(listParameters: any[]) {
 
    this.reasonList = listParameters
        .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.REASONS && f.state === AppConstants.StateFlag.Active && f.parameterDetCode006 === this.customerApplication.product && f.parameterDetCode007 === this.customerApplication.productCode)
        .sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1))
        .map((element: any) => ({ 
          label: element.parameterDetCode002, 
          value: element.parameterDetCode001, 
          product: element.parameterDetCode006, 
          subproduct: element.parameterDetCode007,
          type: element.parameterDetCode008
         }));   
  }

  changeReason() {
    
  }

  getLabelByValue(value: string, list: any[]): string {
    const item = list.find((i) => i.value === value);
    return item ? item.label : '';
  }

  async retrieveApplicationComments(model: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getApplicationComments(model)).then((response: any) => {
      this.comments = response;
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  canelHandler() {
    this.router.navigate(['bandeja-solicitudes']);
  }

  sendHandler() {
    let model = {
      applicationId: this.customer.applicationId,
      workflowInstanceId: this.customer.workflowInstanceId,
      processId: AppConstants.Process.PLD_DRACMA_APROBACION,
      taskId: AppConstants.WorkflowTasks.APPROVAL,
      channelId: AppConstants.Channels.DesktopPVA,
      taskInstanceRole: this.rolName,
      updatedBy: this.usuario,
      updatedByFullName: this.userName,
      agencyCode: this.agenciaCode,
      agencyName: this.agenciaName,
      commentKey: this.generateRandomId(),
      comment: this.commentSeller,
      reason: ''
    };

    this.sendAprovalApplication(model);
  }

  generateRandomId(length: number = 5): string {
    const digits = '0123456789';
    let randomId = '';
    
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * digits.length);
        randomId += digits[randomIndex];
    }
    
    return randomId;
  }

  sendAprovalApplication(model : any): void {
    this.loading = true;
    this.applicationService.postSendApprovalApplication(model).subscribe({
      next: async (response: any) => {
        this.alertService.showMessage(AppConstants.TitleAlert.APPLICATION_UPDATE_TITLE, AppConstants.MessageAlert.UPDATE_APPLICATION_SUCCES, MessageSeverity.success);
        this.router.navigate(['bandeja-solicitudes']);
      },
      error: (error) => {
        this.alertService.showMessage(AppConstants.TitleAlert.APPLICATION_UPDATE_TITLE, error.error.message, MessageSeverity.error);
        console.error(error);
        this.loading = false;
        this.router.navigate(['bandeja-solicitudes']);
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  sendCommentHandler() {
    if(this.commentSeller == '') {
      this.alertService.showMessage("", AppConstants.MessageAlert.ADD_COMMENT_ERROR_EMPY_CREDENTIALS, MessageSeverity.warn);
    }
    else {
      let model = {
        applicationId: this.customer.applicationId,
        commentKey: this.generateRandomId(),
        comment: this.commentSeller,
        reason: '',
        updatedBy: this.usuario,
        updatedByFullName: this.userName
      };
  
      this.sendAddComentApplication(model);
    }
  }

  sendAddComentApplication(model : any): void {
    this.loading = true;
    this.applicationService.postSendAddComentApplication(model).subscribe({
      next: async (response: any) => {
        this.commentSeller = '';
        await this.retrieveApplicationComments(this.customerApplication.applicationId);
        this.alertService.showMessage("", AppConstants.MessageAlert.ADD_COMMENT_SUCCES_APPLICATION, MessageSeverity.success);
      },
      error: (error) => {
        this.alertService.showMessage("", AppConstants.MessageAlert.ADD_COMMENT_ERROR_APPLICATION, MessageSeverity.error);
        console.error(error);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  sendApprovalHandler() {
    let model = {
      applicationId: this.customerApplication.applicationId,
      workflowInstanceId: this.customer.workflowInstanceId,
      status: AppConstants.ApplicationStates.PendienteAprobacion,
      processId: AppConstants.Process.PLD_DRACMA_APROBACION,
      taskId: AppConstants.WorkflowTasks.CUSTOMER_VERIFICATION,
      channelId: AppConstants.Channels.DesktopPVA,
      taskInstanceRole: this.rolName,
      updatedBy: this.usuario,
      updatedByFullName: this.userName,
      agencyCode: this.agenciaCode,
      agencyName: this.agenciaName
    };

    this.updateStautsApplication(model);
  }

  updateStautsApplication(model: any) {
    this.loading = true;
    this.applicationService.postUpdationStatusApplication(model).subscribe({
      next: async (response: any) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.APPLICATION_UPDATE_TITLE, AppConstants.MessageAlert.UPDATE_APPLICATION_SUCCESS, MessageSeverity.success);
        this.router.navigate(['bandeja-solicitudes']);
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.APPLICATION_UPDATE_TITLE, AppConstants.MessageAlert.UPDATE_APPLICATION_ERROR, MessageSeverity.error);
        console.error(error);
      }
    });
  }

  handleCloseAlta() {
    this.showModalResumen = false;
  }

  download(file : string) {
    console.log('download', file);

    let model = {
      applicationId: this.customer.applicationId,
      documentName: file,
      name: `${file}-${this.customer.applicationId}.pdf`
    };

    this.downloadFile(model);
  }

  downloadFile(model: any): void {
    this.loading = true;
    this.applicationService.downloadFileAutoComplete(model).subscribe({
      next: (response: any) => {
        const data: Blob = new Blob([response], {
            type: response.type
        });

        FileSaver.saveAs(data, model.name);
      },
      error: (error: any) => {
        this.alertService.showMessage(AppConstants.TitleAlert.DOWNLOAD_FILE, AppConstants.MessageAlert.DOWNLOAD_FILES_MESSAGE_ERROR, MessageSeverity.error);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }  

}
