<div class="custom-input-container">
    <input #myInput [type]="type" class="custom-input" 
         [placeholder]="placeholderText" 
         [(ngModel)]="value" 
         [required]="isRequired" 
         [disabled]="isDisabled"
         [ngClass]="{'error': isError}"
         [maxlength]="maxlength"  
         (ngModelChange)="onValueChange($event)" 
         (input)="onInputChange($event)"
         (keydown)="onKeydown($event)"
         (blur)="onBlur()"
         oninput="this.type == 'tel' ? this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1') : ''"
         >
    <img *ngIf="isError" src="assets/alerta.svg" class="alert-icon" />

    <!-- <div class="autocomplete-option" *ngFor="let option of filteredOptions; let index = index" 
        [class.active]="selectedIndex === index"
        (click)="selectOption(option)">
        {{ option }}
    </div> -->

    <!-- <div class="autocomplete-options" *ngIf="showOptions">
        <div class="autocomplete-option" *ngFor="let option of filteredOptions; let index = index" 
            [class.active]="selectedIndex === index"
            (click)="selectOption(option)">
            {{ option }}
        </div>
    </div> -->
    <div class="autocomplete-options" *ngIf="showOptions">
        <div class="autocomplete-option" *ngFor="let option of filteredOptions; let index = index" 
             [class.active]="selectedIndex === index"
             (mousedown)="selectOption(option)">
          {{ option }}
        </div>
    </div>
  </div>