import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CustomButtonComponent } from '../custom-button';
import { CustomButtonBorderComponent } from '../custom-button-border';
import { CustomButtonColorComponent } from '../custom-button-color';
import { CustomInputComponent } from '../custom-input';
import { CustomInpuntSelectComponent } from '../custom-inpunt-select';

@Component({
  standalone: true,
  selector: 'app-company-modal',
  imports: [CommonModule, FormsModule,CustomButtonComponent,CustomButtonComponent,CustomButtonColorComponent,CustomButtonBorderComponent,CustomInputComponent, CustomInpuntSelectComponent],
  templateUrl: './company-modal.component.html',
  styleUrls: ['./company-modal.component.scss']
})
export class CompanyModalComponent {

  @Input() titulo: string = '';
  @Input() width: number = 400;
  @Input() height: number = 350; 
  @Output() onClose = new EventEmitter<void>();
  @Input() value: string = '';
  @Output() onSave = new EventEmitter<void>();
  @Input() country: string = '';
  @Input() state: string = '';
  @Input() isErrorCountry: boolean = false;
  @Input() isErrorState: boolean = false;
  @Input() isErrorCompanyName: boolean = false;
  @Input() companyName: string = '';
  

  @Output() onChangeState = new EventEmitter<string>();
  @Output() onChangeCountry = new EventEmitter<string>();
  @Output() onChangeCompanyName = new EventEmitter<string>();

  states: any[] = [ 
    {
      label: 'ACTIVO',
      value: '1'
    },
    {
      label: 'INACTIVO',
      value: '0'
    }
  ];

  countries: any[] = [ 
    {
      label: 'Peru',
      value: '615'
    }
  ];

 

  companyValMin: number = 3;
  companyValMax: number = 50;
  

  close() {
    this.onClose.emit(); 
  }

  save() {
    this.onSave.emit(); 
  }

  onselectedValueChangeState(value: string): void {
    this.onChangeState.emit(value);
  }

  onselectedValueChangeCountry(value: string): void {
    this.onChangeCountry.emit(value);
  }

  onChangeCompanyNameChange(value: string): void {
    this.onChangeCompanyName.emit(value);
  }

}
