<div class="header">
  <button class="hamburger-menu" (click)="toggleMenu(); $event.stopPropagation()">
    <img src="assets/img/hamburguesa.svg" alt="Menu">
  </button>
  <!-- Nueva imagen al otro extremo -->
  <app-custom-button-circle iconUrl="assets/iconUser.svg" (action)="openModal();"></app-custom-button-circle>
  <!-- <img src="assets/img/add_user.svg" class="header-image" alt="Descripción de la imagen"> -->
</div>

<div class="overlay" [class.active]="isMenuOpen" (click)="toggleMenu()"></div>

<nav class="navigation-menu" [class.active]="isMenuOpen" (click)="$event.stopPropagation()">
  <div class="logo">
    <img src="assets/logo.svg" alt="Banbif">
  </div>

  <div class="menu-items">
    <div *ngFor="let menu of arrayMenu">
      <a mat-list-item class="menu-item" (click)="goToRoute(menu.menuUrl)"
        [class.active-menu-item]="isRouteActive(menu.menuUrl)">
        <img src={{menu.menuImage}} alt="Banbif"><span>{{menu.menuName}}</span>
      </a> 
    </div>     
  </div>
  
</nav>

<app-info-modal *ngIf="showModal" [titulo]="'Información Usuario'" [width]="modalWidth" [height]="modalHeight" (onClose)="handleClose()">
  <app-info-usuario-modal (onClose)="handleClose()"></app-info-usuario-modal>
</app-info-modal>

<app-modal *ngIf="showModalAnular" [width]="400" [height]="300" [imageUrl]="'assets/img/anular_app.svg'"
           [titleText]="tituloModal" [subtitleText]="subTituloModal"
           [paragraphText]="parrafo" [linkText]="textoEnlaceModal"
           [showCustomButtons]="true"
           (linkAction)="cerrarModalAnular()"
           (onClose)="handleClose()"
           (onSave)="handleSaveCancellationApplication()">
</app-modal>

<loading *ngIf="loading"></loading>