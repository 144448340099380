import { Component, EventEmitter, Output } from '@angular/core';
import { CustomButtonColorComponent } from '../custom-button-color';
import { AppConstants } from 'app/shared/constants/app-constants';

@Component({
  standalone: true,
  imports: [CustomButtonColorComponent],
  selector: 'app-info-usuario-modal',
  templateUrl: './info-usuario-modal.component.html',
  styleUrls: ['./info-usuario-modal.component.scss']
})
export class InfoUsuarioModalComponent {
  @Output() onClose = new EventEmitter<void>();

  usuario: string = sessionStorage.getItem(AppConstants.Session.USERID) ?? '';
  agencia: string = sessionStorage.getItem(AppConstants.Session.AGENCYNAME) ?? '';
  codigo: string = sessionStorage.getItem(AppConstants.Session.AGENCYCODE) ?? '';
  canalVenta: string = sessionStorage.getItem(AppConstants.Session.SALES_CHANNEL_DESCRIPTION) ?? '';
  
  close() {
    this.onClose.emit(); 
  }
}
