import { Component, Input,EventEmitter,Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  standalone: true,
  imports: [CommonModule,FormsModule],
  selector: 'app-custom-text-area',
  templateUrl: './custom-text-area.component.html',
  styleUrls: ['./custom-text-area.component.scss']
})
export class CustomTextAreaComponent {
  @Input() placeholderText: string = '';
  @Input() label: string = '';
  @Input() width: string = 'auto'; 
  @Input() height = '80px';
  @Input() value: string = '';
  @Output() valueChange = new EventEmitter<string>();
  @Input() isRequired: boolean = false; 

  onValueChange(value: string): void {
    this.valueChange.emit(value);
  }

}
