import { Component, AfterViewInit, ElementRef, HostListener, EventEmitter, Output } from '@angular/core';
import { AppConstants } from 'app/shared/constants/app-constants';

@Component({
  standalone: true,
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements AfterViewInit {
  // Event emitter para emitir la altura del footer
  @Output() footerHeight = new EventEmitter<number>();

  usuario: string = sessionStorage.getItem(AppConstants.Session.USERID) ?? '';
  agencia: string = sessionStorage.getItem(AppConstants.Session.AGENCYNAME) ?? '';
  codigo: string = sessionStorage.getItem(AppConstants.Session.AGENCYCODE) ?? '';
  canalVenta: string = sessionStorage.getItem(AppConstants.Session.SALES_CHANNEL_DESCRIPTION) ?? '';

  constructor(private elRef: ElementRef) {}

  ngAfterViewInit() {
    // Emitir la altura una vez que el componente ha sido inicializado
    this.emitFooterHeight();
  }

  // Ajusta la altura cuando la ventana cambia de tamaño
  @HostListener('window:resize')
  onResize() {
    this.emitFooterHeight();
  }

  private emitFooterHeight() {
    const height = this.elRef.nativeElement.offsetHeight;
    this.footerHeight.emit(height);
  }
}
