import { Component, Input, Output, EventEmitter } from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-custom-button-border',
  standalone: true,
  imports: [MatToolbarModule, MatButtonModule, MatIconModule,CommonModule],
  templateUrl: './custom-button-border.component.html',
  styleUrls: ['./custom-button-border.component.scss']
})
export class CustomButtonBorderComponent {

  @Input() iconUrl?: string;
  @Input() text?: string;
  @Input() isDisabled: boolean = false;
  @Output() action = new EventEmitter<Event>();

  onClick(event: Event): void {
    event.preventDefault(); 
    event.stopPropagation();
    this.action.emit(event);
  }
}
