<div class="modal-overlay">
    <div class="modal-content"[ngStyle]="{'width.px': width, 'height.px': 'auto'}">
        <div class="modal-header">
            <label class="titulo">{{ titulo }}</label>
        </div>
        <div class="modal-body">
            <div class="subtitle">
                <img src="assets/svg/dot-green.svg" alt="dot" />
                <h2>Completar</h2>
            </div>  
        </div>

        <div class="modal-body">
            <div class="form-group fullsize">
                <label class="custom-input-label">Nombre de Empresa</label>
                <app-custom-input [(value)]="companyName" [isError]="isErrorCompanyName" 
                 [minlength]="companyValMin" [maxlength]="companyValMax" (valueChange)="onChangeCompanyNameChange($event)"></app-custom-input>
            </div>

            <div class="conteiner-group-selects">
                <div class="form-group full-container">
                    <label class="custom-input-label">Estado</label>
                    <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="states"
                     [(selectedValue)]="state" (selectedValueChange)="onselectedValueChangeState($event)" [isError]="isErrorState">
                    </app-custom-inpunt-select>
                </div>
                <div class="form-group full-container">
                    <label class="custom-input-label">País Domicilio</label>
                    <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="countries" 
                     [(selectedValue)]="country" (selectedValueChange)="onselectedValueChangeCountry($event)" [isError]="isErrorCountry">
                    </app-custom-inpunt-select>
                </div>
            </div>
            
        </div>

 
      <div class="modal-footer">
        <app-custom-button-color text="CERRAR" (action)="close()"></app-custom-button-color>
        <app-custom-button text="Guardar" (action)="save()"></app-custom-button>
      </div>
    </div>
  </div>
  