import { Component, EventEmitter, Input, Output,forwardRef  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  standalone:true,
  imports: [CommonModule,FormsModule],
  selector: 'app-custom-datepicker',
  templateUrl: './custom-datepicker.component.html',
  styleUrls: ['./custom-datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomDatepickerComponent),
      multi: true
    }
  ],
})
export class CustomDatepickerComponent {

  @Input() value: string = '';
  @Output() valueChange = new EventEmitter<string>();
  @Input() isError: boolean = false;
  @Input() isReadonly: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() validateYears: boolean = false;

  onValueChange(value: string): void {
    this.isError = !this.validateYearAndAge(value); // Reiniciar el estado de error
    this.valueChange.emit(value); // Emitir el valor incluso si hay un error, para permitir correcciones
  }
  focusInput(inputElement: HTMLInputElement): void {
    inputElement.focus();
  }

  validateYearAndAge(date: string): boolean {
    try {
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      if (!regex.test(date)) {
        return false;
      }

      const [year, month, day] = date.split('-').map(num => parseInt(num, 10));

      if (year <= 1900) {
        return false;
      }

      if(this.validateYears) {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        const currentDay = currentDate.getDate();

        let age = currentYear - year;

        if (currentMonth < month || (currentMonth === month && currentDay < day)) {
          age--;
        }

        if (age < 18) {
          return false;
        }
      }

      return true;
  
    } catch (error) {
      console.error("Error al validar la fecha:", error);
      return false;
    }
  }
}

