import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AutenticacionPldBancoRoutingModule } from './autenticacion-pld-banco-routing.module';
import { AutenticacionPldBancoComponent } from './autenticacion-pld-banco.component';
import { CustomButtonComponent, CustomButtonColorComponent, CustomInpuntSelectComponent, HeaderComponent, FooterComponent, CustomInputComponent, CustomTableSimulatorComponent, LineDividerComponent, StepperComponent, ModalResumenComponent, CustomInputLoginComponent, CustomTabComponent } from 'app/components';
import { SharedModule } from 'app/shared/modules/shared/shared.module';
import { ValidacionCorreoComponent } from './components/validacion-correo/validacion-correo.component';
import { ValidacionFacialComponent } from './components/validacion-facial/validacion-facial.component';
import { ValidacionFacialCorreoComponent } from './components/validacion-facial-correo/validacion-facial-correo.component';
import { CustomButtonBorderComponent } from 'app/components/custom-button-border';


@NgModule({
  declarations: [
    AutenticacionPldBancoComponent,
    ValidacionCorreoComponent,
    ValidacionFacialComponent,
    ValidacionFacialCorreoComponent
  ],
  imports: [
    CommonModule,
    AutenticacionPldBancoRoutingModule,
    CustomButtonComponent,
    CustomButtonColorComponent,
    CustomInpuntSelectComponent,
    HeaderComponent,
    CustomButtonBorderComponent,
    FooterComponent,
    CustomInputComponent,
    CustomTableSimulatorComponent,
    LineDividerComponent,
    StepperComponent,
    CustomInputLoginComponent,
    CustomTabComponent,
    ModalResumenComponent,
    SharedModule
  ]
})
export class AutenticacionPldBancoModule { }
