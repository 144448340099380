import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomInputComponent, CustomTableSimulatorComponent, DynamicModalComponent } from 'app/components';
import { Router } from '@angular/router';
import { HostListener } from '@angular/core';
import { CustomerService } from 'app/services/customer.service';
import { ParameterService } from 'app/services/parameter.service';
import { AppConstants } from 'app/shared/constants/app-constants';
import { ApplicationService } from 'app/services/application.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';
import { OffersService } from 'app/services/offers.service';
import { ClienteSession, ProductoSession } from 'app/models/sessionModel';
import { trigger, transition, style, animate } from '@angular/animations';
import { forkJoin, lastValueFrom } from 'rxjs';
import { environment } from 'environments/environment';
import { PdfmakeService } from 'app/services/pdfmake.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('fadeInDown', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-20px)' }),
        animate('350ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ])
    ]),
    trigger('fadeInDownReverse', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-20px)' }),
        animate('200ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        animate('100ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class HomeComponent implements OnInit{

  showModal = false;
  esMovil: boolean = false;
  isError: boolean = false;
  isValor: string = "valor";
  posts: any[] = [];
  loading: boolean = false;
  searchDate!: Date;

  //listas
  vistaActual = 'lista';
  agrupadosPorNombre: { [key: string]: any[] } = {};
  agrupadosPorNombreFiltrado: { [key: string]: any[] } = {};
  listaAbierta: { [key: string]: boolean } = {};


  @HostListener('window:resize', ['$event'])
  onResize() {
    this.esMovil = window.innerWidth <= 768;
  }

 
  @ViewChild('myTable') tableComponent!: CustomTableSimulatorComponent;
  @ViewChild('carrusel', { static: false }) carrusel!: ElementRef;

  constructor(
    public dialog: MatDialog, 
    private cdr: ChangeDetectorRef,
    private router: Router,
    private customerService: CustomerService,
    private parameterService: ParameterService,
    private applicationService: ApplicationService,
    private alertService: AlertService,
    private offersService: OffersService,
    private pdfmakeService: PdfmakeService
  ) {
    this.esMovil = window.innerWidth <= 768;
  }

 
  async ngOnInit() {
    // await this.getDocuments();
    await this.getParameters([
      AppConstants.ParameterCode.DOCUMENT_TYPE,
      AppConstants.ParameterCode.MARITAL_STATUS,
      AppConstants.ParameterCode.PRODUCTS_ROLE
    ]);
    this.productos = [];    
  }
  

  
  isSearchDisabled: boolean = true; 
  selectedTypeDocument = '1';
  mostrarInformacion = false;  
  dni = '';
  nombreCliente = '';
  numeroResultados = 0;
  
  tituloModal='';
  subTituloModal='';
  parrafo='';
  textoEnlaceModal='';


  miPlaceholder = "Número documento";

  documentTypeDescription: string = '';
  documents: any = [];
  documentForm: string = '';
  typeInputDocument: string = 'text';
  max: number = 8;
  valNumber: boolean = true;
  @ViewChild(CustomInputComponent) customInput!: CustomInputComponent;
  offersAvailable: boolean = false;
  offers: any = [];
  productos: any = [];
  offersFilter: any = [];

  usuario: string = sessionStorage.getItem(AppConstants.Session.USERID) ?? '';
  rolCode: string = sessionStorage.getItem(AppConstants.Session.USERROLID) ?? '';

  showScrollButton = false;
  //CÓNYUGE AÑADIDO
  showSpouse: boolean = false;
  statusMarital: string = ''; 
  dniSpouse: string = ''; 
  documentTypeSpouse: string = '1';
  filtrosVisibles: boolean = false;

  productsProfile: any = [];

  filters: any = [];

  availableProducts = ['PRECS','PRELDBCO'];
  customerCode: number = 0;
  customerInformation: any = {};
  noCustomerInformation: any = {};
  opcionesEstadoCivil: any = [];  
  maritalStatusBlocked: boolean = false;
  spouseDocumentTypeBlocked: boolean = false;
  spouseDocumentNumberBlocked: boolean = false;
  maxSpouse: number = 8;
  typeInputDocumentSpouse: string = 'text';
  isErrorDocumentNumberSpouse: boolean = false;
  admissionRules: any[] = [];
  showRules: boolean = false;
  showCustomerInformation = false;  
  validateSpouseAdmision = false;
  isErrorDocumentTypeSpouse = false;  
  riskAssessment: any[] = [];
  resultAdmisionTransitory: any = {};
  showMatriz: boolean = false;
  showButtonExport: boolean = false;
  noCustomerVaidated: boolean = false;


  estadoCivil: string = ''; 
  isErrorEstadoCivil: boolean = false;

  ngAfterViewInit() {
    this.cdr.detectChanges();

  }

  cerrarModal() {
    this.documentForm = '';
    this.showModal = false;
    this.customInput.focusInput();
  }

  accionPersonalizada(){
    this.showModal = false;
  }

  redireccionar(producto: any) {
    
    this.router.navigate(['simulador-oferta'], { queryParams: producto });
  }

  openModal(title: string, subtitle: string, description: string, labelButton: string) {
    this.showModal = true;
    this.tituloModal= title;
    this.subTituloModal= subtitle;
    this.parrafo = description;
    this.textoEnlaceModal= labelButton;
  }

  async getDocuments() {
    this.loading = true;
    let model = [AppConstants.ParameterCode.DOCUMENT_TYPE];
    this.parameterService.getParametersList(model).subscribe({
      next: async (response: any) => {
        let parameters = (response.filter((f: any) => f.state == AppConstants.StateFlag.Active)).sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1));
        parameters.forEach((element: any) => {
          this.documents.push({ label: element.parameterDetCode002, value: element.parameterDetCode001, length: element.parameterDetCode003 });
        });
        this.typeOperation();
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        console.log(error);
      }
    });
  }

  typeOperation(): void {
    let document = this.documents.find((f: any) => f.value === this.selectedTypeDocument);
    if (document === undefined) {
        this.max = 8;
        this.valNumber = true;
    } else {
        this.documentTypeDescription = document.label;
        this.max = document.length;
        this.documentForm = '';
        if (document.label.toUpperCase().includes('C.E')
            || document.label.toUpperCase().includes('CE')
            || document.label.toUpperCase().includes('CARNE')
            || document.label.toUpperCase().includes('EXTRANJERIA')) {
            this.valNumber = false;
            this.typeInputDocument = 'text';
        } else {
            this.valNumber = true;
            this.typeInputDocument = 'tel';
        }
    }
  }

  removeSpacesDocument() {
    this.documentForm = this.documentForm.replace(/ /g, ' ');
  }

  onlyNumber() {
    if (this.valNumber) {
    } else {
      this.documentForm = this.documentForm.replace(/[^a-zA-Z0-9_-]/g, '');
    }
  }

  searchOffer() {
    let document = this.documents.find((f: any) => f.value === this.selectedTypeDocument);
    if(this.documentForm.length == document.length) {
      let model = {
        "documentType": this.selectedTypeDocument,
        "documentNumber": this.documentForm,
        "userId": this.usuario,
        "product": AppConstants.Products.Loans
      }
      if(this.validateSpouseAdmision) {
        this.searchOfferWithSpouse();
      } else {
        this.validateApplicationInProgress(model);
      }      
    }
  }

  validateApplicationInProgress(model: any) {
    this.loading = true;   
    // this.callGetOffers(model);
    this.applicationService.returnApplication(model).subscribe({
      next: (response: HttpResponse<any>) => {
        if (response.status === 204) {
          this.callGetOffers(model);
        } else {
          if (response.body.createdBy === this.usuario) {
            sessionStorage.setItem(AppConstants.Session.APPLICATION_ID, response.body.applicationId);
            sessionStorage.setItem(AppConstants.Session.WORKFLOW_ID, response.body.instannceId);

            var client = new ClienteSession();
                client.documentTypeDesciption = response.body.documentTypeDescription;
                client.documentType = response.body.documentType;
                client.documentNumber = response.body.documentNumber;
                client.fullName = response.body.fullName;
                client.userCreation = this.usuario;


            var product = new ProductoSession();
                product.image = response.body.product.image;
                product.name = response.body.product.name;

            sessionStorage.setItem(AppConstants.Session.CLIENT, JSON.stringify(client));    
            sessionStorage.setItem(AppConstants.Session.PRODUCT, JSON.stringify(product));
            sessionStorage.setItem(AppConstants.Session.SIMULACION, JSON.stringify({ loanAmountFormat: response.body.applicationAmountFormat}));

            this.router.navigateByUrl(response.body.path);
            this.loading = false;
          }
          else {
            this.loading = false;
            this.alertService.showMessage('Error', 'La solicitud '+ response.body.applicationId +' ya esta siendo tomada por el usuario ' + response.body.createdBy, MessageSeverity.error);
          }
        }
      },
      error: (error: HttpErrorResponse) => {
        this.loading = false;
        console.log('Error:', error);
        this.alertService.showMessage(AppConstants.TitleAlert.RETRIEVE_APPLICATION_TITLE, AppConstants.MessageAlert.RETRIEVE_APPLICATION_MESSAGE, MessageSeverity.error);
      },
      complete: () => {
      }
    });
  }

  callGetOffers(model: any) {
    this.clear();
    this.loading = true;
    this.searchDate = this.getCurrentDateTimeUTC5();
    this.offersService.postObtenerOfertas(model).subscribe({
      next: async (response: any) => {
        try {
          const lisFilter = response.datos.length > 0 && response.meta.totalRegistros > 0 ?
                          response.datos.filter((item: any) => {
                            return Array.from(this.productsProfile).some(p => item?.subProducto?.codigo?.includes(p));
                          }).sort((a: any, b: any)  => {
                            if (b.monto !== a.monto) {
                              return b.monto - a.monto;
                            } else {
                              return b.plazo - a.plazo;
                            }
                          }) : [];

          if(lisFilter.length > 0) {            
            this.dni = lisFilter[0].cliente.documento.numero;
            this.nombreCliente = lisFilter[0].cliente.nombresCompletos;
            this.numeroResultados = lisFilter.length;            
            this.offers = lisFilter;

            if(this.noCustomerVaidated) {
              this.callComplianceClientTransitory(model);
            }
            else {
              this.orquestationCustomer();
            }

          }
          else {
            this.loading = false;
            this.openModal('SIN OFERTA', 'No presenta ofertas por el momento', `DNI: ${this.documentForm}`, 'CERRAR');
          }
          
        } catch (error) {
          console.error(error);
        }      
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.CONSULT_OFFERS_TITLE, AppConstants.MessageAlert.OFFERS_RETRIEVE_ERROR, MessageSeverity.error);
        console.error(error);
      },
      complete: () => {
      }
    });        
  }

  callGetOffers2(model: any) {    
    this.clear();
    this.loading = true;
    this.searchDate = this.getCurrentDateTimeUTC5();
    this.offersService.postObtenerOfertas(model).subscribe({
      next: async (response: any) => {
        try {
          const lisFilter = response.datos.length > 0 && response.meta.totalRegistros > 0 ?
                          response.datos.filter((item: any) => item.nombre.includes("PTMN") && item.nombre.includes("PS02")).sort((a: any, b: any)  => {
                            if (b.monto !== a.monto) {
                              return b.monto - a.monto;
                            } else {
                              return b.plazo - a.plazo;
                            }
                          }) : [];

          if(lisFilter.length > 0) {
            this.callComplianceClient(model);

            this.dni = lisFilter[0].cliente.documento.numero;
            this.nombreCliente = lisFilter[0].cliente.nombresCompletos;
            this.numeroResultados = lisFilter.length;
            
            this.offers = lisFilter;

            //this.alertService.showMessage(AppConstants.TitleAlert.CONSULT_OFFERS, AppConstants.MessageAlert.OFFERS_RETRIEVE_SUCCESS, MessageSeverity.success);
          }
          else {
            this.loading = false;
            this.openModal('SIN OFERTA', 'No presenta ofertas por el momento', `DNI: ${this.documentForm}`, 'CERRAR');
          }
          
        } catch (error) {
          console.error(error);
        }      
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.CONSULT_OFFERS_TITLE, AppConstants.MessageAlert.OFFERS_RETRIEVE_ERROR, MessageSeverity.error);
        console.error(error);
      },
      complete: () => {
        console.info('complete')
      }
    });
  }

  callComplianceClient(model: any) {
    this.loading = true;
    this.offersAvailable = false;
    this.customerService.postAdmissionCustomer(model).subscribe({
      next: async (response) => {
        this.loading = false;


        if (response.meta && response.meta.mensajes && response.meta.mensajes.length > 0) {
          let codigoMensaje = response.meta.mensajes[0].codigo;
          if (codigoMensaje === "0") {
            if(response.datos && response.datos.respuestaOperacion && response.datos.respuestaOperacion.flagOperacion == "1") {

              // const plazoMin = Math.min(...this.offers.map((oferta: any) => oferta.plazo));
              const plazoMax = Math.max(...this.offers.map((oferta: any) => oferta.plazo));
              
              // const montoMin = Math.min(...this.offers.map((oferta: any) => oferta.monto));
              // const montoMax = Math.max(...this.offers.map((oferta: any) => oferta.monto));

              for (let index = 0; index < this.offers.length; index++) {
                let offer = this.offers[index];

                let model = {
                  documentType: this.selectedTypeDocument,
                  documentNumber: offer.cliente.documento.numero,
                  fullName: offer.cliente.nombresCompletos,
                  offerid: offer.codigo,
                  campaignCode: offer.nombre,
                  monto: +offer.monto,
                  producto: offer.producto.descripcion,
                  codigoSubproducto: offer.subProducto.codigo,
                  nombre: offer.subProducto.descripcion,
                  nombreBase: offer.subProducto.descripcion,
                  fechaInicio: offer.fechaInicio.split(" ")[0],
                  fechaFin: offer.fechaFin.split(" ")[0],
                  tea: offer.tasa,
                  plazo: offer.plazo,
                  linkTexto: 'Escoger',
                  url: this.getImageByProductCode(offer.subProducto.codigo), //offer.subProducto.codigo === 'PRELD' ? 'assets/dinero.svg' : 'assets/alcancia.svg',
                  moneda: offer.moneda === 'USD' ? 'SOL' : offer.moneda,
                  monedaSimbolo: offer.moneda === 'USD' ? 'S/ ' : '$ ',
                  prioridad: offer.prioridad,
                  tipo: offer.tipo,
                  plazoMinimo: offer.plazo,
                  plazoMaximo: plazoMax
                };
                
                this.productos.push(model);
                this.offersFilter.push(model);
              }

              // Agrupamos los productos por nombre
              const allData = this.productos.reduce((acc: { [key: string]: any[] }, producto: { nombre: string }) => {
                if (!acc[producto.nombre]) {
                  acc[producto.nombre] = [];
                  this.listaAbierta[producto.nombre] = true;
                }
                acc[producto.nombre].push(producto);
                return acc;
              }, {});
              this.agrupadosPorNombre = allData;
              this.agrupadosPorNombreFiltrado = allData;

              this.mostrarInformacion = true;
              this.showScrollButton = true;
              this.createFilters(this.productos);
            }
            else {
              let title = response.datos.reglaOriginacion[0].mensaje;
              // let description = response.datos.reglaOriginacion[0].descripcion + ' - ' + response.datos.reglaOriginacion[0].id;
              let description = response.datos.reglaOriginacion[0].descripcion;
              this.openModal(title, description, '', 'CERRAR');
            }

          } else {
            this.alertService.showMessage(AppConstants.TitleAlert.COMPLIANCE_TITLE, AppConstants.MessageAlert.COMPLIANCE_RETRIEVE_ERROR, MessageSeverity.error);
          }
        } else {
          this.alertService.showMessage(AppConstants.TitleAlert.COMPLIANCE_TITLE, AppConstants.MessageAlert.COMPLIANCE_RETRIEVE_ERROR, MessageSeverity.error);
        }
  
      },
      error: (error) => {
        this.loading = false;
        console.log(error);
      }
    });
  }

  clear () {
    this.offers = [];
    this.productos = [];
    this.dni = '';
    this.nombreCliente = '';
    this.numeroResultados = 0;
    this.filters = [];
    this.agrupadosPorNombreFiltrado = {};
    this.agrupadosPorNombre = {};
    this.listaAbierta = {};
    this.mostrarInformacion = false;
    this.showScrollButton = false;
    this.showRules = false;
    this.showCustomerInformation = false;
    this.estadoCivil = '';
    this.documentTypeSpouse = '1';
    this.dniSpouse = '';
    this.validateSpouseAdmision = false;
    this.customerInformation = {};
    // this.noCustomerInformation = {};
    this.showControlsMarried(false, false, false, false);
    this.admissionRules = [];
    this.riskAssessment = [];
    this.resultAdmisionTransitory = {};
    this.showMatriz = false;
    this.showButtonExport = false;
  }

  async selectProduct(row: any) {    
    if(this.availableProducts.includes(row?.codigoSubproducto)) {
      //Validar Producto Permitido por Pefil
      if(await this.validateFlujoAcces()) {

        var client = new ClienteSession();
        client.documentTypeDesciption = this.documentTypeDescription;
        client.documentType = row.documentType;
        client.documentNumber = row.documentNumber;
        client.fullName = row.fullName;
        client.userCreation = this.usuario;
        client.offersearchdate = this.searchDate;
        client.maritalStatus = this.estadoCivil;
        client.documentTypeSpouse = this.documentTypeSpouse;
        client.documentNumberSpouse = this.dniSpouse;
        client.score = this.resultAdmisionTransitory?.score;
        client.riskAssessment = this.resultAdmisionTransitory?.riskAssessment;
        client.admissionIdentifier = this.resultAdmisionTransitory?.identifier;

        const parts = row.campaignCode.split("-");
        const producto = parts.length >= 2 ? parts[0] : '';
        const subproducto = parts.length >= 2 ? parts[1] : '';

        var product = new ProductoSession();
            product.image = row.url;
            product.offerid = row.offerid;
            product.campaignCode = row.campaignCode;
            product.amount = row.monto;
            product.product = row.producto;
            product.subproductCode = row.codigoSubproducto;
            product.name = row.nombre;
            product.nameBase = row.nombreBase;
            product.validityStart = row.fechaInicio;
            product.validityEnd = row.fechaFin;
            product.tea = row.tea;
            product.installments = row.plazo;
            product.installmentsMax = row.plazoMaximo;
            product.currency = row.moneda;
            product.currencySymbol = row.monedaSimbolo;
            product.priority = row.prioridad;
            product.type = row.tipo;
            product.offerProductCode = producto;
            product.offerSubProductCode = subproducto;

        sessionStorage.setItem(AppConstants.Session.CLIENT, JSON.stringify(client));    
        sessionStorage.setItem(AppConstants.Session.PRODUCT, JSON.stringify(product));
        sessionStorage.setItem(AppConstants.Session.OFFERS, JSON.stringify(this.offersFilter));
        sessionStorage.setItem(AppConstants.Session.DATA_CUSTOMER, JSON.stringify(this.customerInformation));
        sessionStorage.setItem(AppConstants.Session.DATA_NO_CUSTOMER, JSON.stringify(this.noCustomerInformation));
      }
      else {
        return;
      }

      if(row?.subProductCode === AppConstants.SubProducts.PLD_DRACMA) {
        this.router.navigate(['simulador-oferta']);
      } else { 
        this.router.navigate(['simulador-oferta-bco']);
      }
      
    }
    else {
      this.alertService.showMessage(AppConstants.TitleAlert.CONSULT_OFFERS_TITLE, AppConstants.MessageAlert.ERROR_PRODUCT_DISABLED, MessageSeverity.warn);
      window.open(environment.acrEndpoint, '_blank', 'noopener,noreferrer');
    }
  }

  async validateFlujoAcces() {
    return true;
  }

  getCurrentDateTimeUTC5(): Date {
    const currentDateUTC = new Date();
    const utcMinus5Offset = 5 * 60 * 60 * 1000;
    const currentDateUTC5 = new Date(currentDateUTC.getTime() - utcMinus5Offset);  
    return currentDateUTC5;
  }

  toggleLista(nombre: string) {
    this.listaAbierta[nombre] = !this.listaAbierta[nombre];
  }

  cambiarVista(modo: string) {
    this.vistaActual = modo;
  }

  abrirFiltros() {
    this.filtrosVisibles = !this.filtrosVisibles;
  }

  @HostListener('document:click', ['$event'])
  cerrarFiltros(event: Event) {
    const target = event.target as HTMLElement;
    const btnFiltros = document.querySelector('.btn-filtros');

    // Si el clic no fue en .btn-filtros ni en su interior, cierra los filtros
    if (btnFiltros && !btnFiltros.contains(target)) {
      this.filtrosVisibles = false;
    }
  }

  scrollToPolicies() {
    const policiesSection = document.querySelector('.encabezado-lista.matriz');
    if (policiesSection) {
      policiesSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
      this.showScrollButton = false;
    }
  }
  
  observePoliciesSection() {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.showScrollButton = false; 
        }
      });
    }, { threshold: 0.5 });
    
    const policiesSection = document.querySelector('.encabezado-lista.matriz');
    if (policiesSection) {
      observer.observe(policiesSection);
    }
  }

  onFilterChange(filtro: any): void {    
    let filters = this.filters.filter((f: any) => f.seleccionado).map((f: any) => f.name);

    this.agrupadosPorNombreFiltrado = Object.keys(this.agrupadosPorNombre)
    .filter(key => filters.includes(key))
    .reduce((obj, key) => {
      obj[key] = this.agrupadosPorNombre[key];
      return obj;
    }, {} as Record<string, any[]>);
  }

  createFilters(offers :any) {
    this.filters = offers.map((f : any) => ({
      name: f.nombre,
      code: f.codigoSubproducto,
      seleccionado: true
    }))
    .filter((value: any, index: number, self: any) =>
      self.findIndex((v: any) => v.code === value.code) === index
    );
  }

  getImageByProductCode(productCode: string) {
    let image: string;

    switch (productCode) {
      case 'TCVISBCO':
        image = 'assets/svg/tarjetas.svg';
        break;
      case 'PRECDBCO':
        image = 'assets/svg/compradeduda.svg';
        break;
      case 'PRELRBCO':
        image = 'assets/svg/reenganche.svg';
        break;
      case 'VEHICBCO':
        image = 'assets/svg/vehic.svg';
        break;
      case 'HIPOCBCO':
        image = 'assets/svg/hipot.svg';
        break;
      default:
        image = 'assets/dinero.svg';
        break;
    }

    return image;

  }

  orquestationCustomer() {
    this.loading = true;
    const modelCustomer = {
      documentType: this.selectedTypeDocument,
      documentNumber: this.documentForm,
      userId: this.usuario
    };

    const sources: any = [];
    sources.push(this.customerService.postCustomerInformationLegacyRetrieve(modelCustomer));
    forkJoin(sources)
    .subscribe({
      next: (response: any) => {
        for(let index = 0; index < response.length; index++) {
          const element = response[index];
          this.customerCode = element.ibsCode;
          this.customerInformation = element;
        }
        this.loading = false;
      },
      error: (e) => {
        console.error(e)
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.CUSTOMER_RETRIEVE_TITLE, e.error.message, MessageSeverity.error);
      },
      complete: async () => {
        this.loading = false;
        if(this.customerCode === 0 && this.customerInformation?.success === false) {
          await this.retriveNoCustomerInformation(modelCustomer);
        }
        else {
          if(this.customerInformation.maritalStatus === AppConstants.MaritalStatus.MARRIED) {
            this.estadoCivil = this.customerInformation.maritalStatus;
            if(this.customerInformation.spouse != null) {
              this.documentTypeSpouse = this.customerInformation.spouse?.documentType;
              this.dniSpouse = this.customerInformation.spouse?.documentNumber;
              this.showControlsMarried(true, true, true, true);

              let modelCustomerMarried = {
                documentType: this.selectedTypeDocument,
                documentNumber: this.documentForm,
                spouseDocumentType: this.documentTypeSpouse,
                spouseDocumentNumber: this.dniSpouse,
                userId: this.usuario
              };

              this.callComplianceClientTransitory(modelCustomerMarried);

            }
            else {
              this.validateSpouseAdmision = true;
              this.showControlsMarried(true, true, false, false);
            }
          }
          else {
            this.showControlsMarried(false, false, false, false);
            this.callComplianceClientTransitory(modelCustomer);            
          }
        }
      }
    });        
  }

  async retriveNoCustomerInformation(model: any) {
    this.loading = true;
    await lastValueFrom(this.customerService.postNoCustomerInformationRetrieve(model)).then((response: any) => {   
      this.noCustomerInformation = response;
      if(response.success) {        
        if(response.maritalStatusCode === AppConstants.MaritalStatus.MARRIED) {          
          this.estadoCivil = response.maritalStatusCode;
          this.validateSpouseAdmision = true;
          this.showControlsMarried(true, false, false, false);          
          this.loading = false;
        }
        else {          
          this.showControlsMarried(false, false, false, false);          
          this.callComplianceClientTransitory(model);
        }
      } else {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.COMPLIANCE_TITLE, AppConstants.MessageAlert.COMPLIANCE_RETRIEVE_DOCUMENT_ERROR, MessageSeverity.info);
      }
    },
    (error) => {
        this.loading = false;
        this.alertService.showMessage("", AppConstants.MessageAlert.CUSTOMER_ERROR_RETRIEVE, MessageSeverity.error);
        console.error(error);
    });
  }
  
  async getParameters(array: Array<number>) {
    this.loading = true;
    await lastValueFrom(this.parameterService.getParametersList(array)).then((response: any) => {
      this.setVariablesParameters(response);
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  setVariablesParameters(listParameters: any[]) {    
    this.documents = listParameters
        .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.DOCUMENT_TYPE && f.state === AppConstants.StateFlag.Active)
        .sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1))
        .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001, length: element.parameterDetCode003 }));

    this.opcionesEstadoCivil = listParameters
        .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.MARITAL_STATUS && f.state === AppConstants.StateFlag.Active)
        .sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1))
        .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 }));

    this.productsProfile = listParameters
        .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.PRODUCTS_ROLE && f.state === AppConstants.StateFlag.Active && f.parameterDetCode001 === this.rolCode)
        .sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1))
        .map((element: any) => (element.parameterDetCode002));

    this.typeOperation();
  }

  handleSelectionChange(value: string, errorField: string) {
    if(value === AppConstants.MaritalStatus.MARRIED) {
      this.showSpouse = true;
      this.validateSpouseAdmision = true;
      this.noCustomerVaidated = false;
    }
    else {      
      this.showSpouse = false
      this.validateSpouseAdmision = false;
      this.noCustomerVaidated = true;
    }
  }

  typeOperationSpouse(): void {
    let document = this.documents.find((f: any) => f.value === this.documentTypeSpouse);
    if (document === undefined) {
        this.maxSpouse = 8;
    } else { 
        this.maxSpouse = document.length;
        this.dniSpouse = '';
        if (document.label.toUpperCase().includes('C.E')
            || document.label.toUpperCase().includes('CE')
            || document.label.toUpperCase().includes('CARNE')
            || document.label.toUpperCase().includes('EXTRANJERIA')) {
            this.typeInputDocumentSpouse = 'text';
        } else {
            this.typeInputDocumentSpouse = 'tel';
        }
    }
  }

  callComplianceClientTransitory(model: any) {
    this.loading = true;
    this.offersAvailable = false;
    this.customerService.postAdmissionCustomerTransitory(model).subscribe({
      next: async (response) => {
        this.validationFiltersAdmissionService(response);
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.COMPLIANCE_TITLE, AppConstants.MessageAlert.COMPLIANCE_RETRIEVE_ERROR, MessageSeverity.error);
        console.log(error);
      }
    });
  }

  validationFiltersAdmissionService(response: any) {
    try {
      this.resultAdmisionTransitory = response;
      this.admissionRules = response.rules;
      this.riskAssessment = response.riskAssessment;
      this.noCustomerVaidated = false;
      if(response?.operationSuccess) {
        if(response?.numberMandatoryRules > 0) {
          this.mostrarInformacion = false;          
        } 
        else {

          //Creation Offers
          const plazoMax = Math.max(...this.offers.map((oferta: any) => oferta.plazo));

          for (let index = 0; index < this.offers.length; index++) {
            let offer = this.offers[index];
            const parts = offer.nombre.split("-");
            const producto = parts.length >= 2 ? parts[0] : '';
            const subproducto = parts.length >= 2 ? parts[1] : '';

            let model = {
              documentType: this.selectedTypeDocument,
              documentNumber: offer.cliente.documento.numero,
              fullName: offer.cliente.nombresCompletos,
              offerid: offer.codigo,
              campaignCode: offer.nombre,
              monto: +offer.monto,
              producto: offer.producto.descripcion,
              codigoSubproducto: offer.subProducto.codigo,
              nombre: offer.subProducto.descripcion,
              nombreBase: offer.subProducto.descripcion,
              fechaInicio: offer.fechaInicio.split(" ")[0],
              fechaFin: offer.fechaFin.split(" ")[0],
              tea: offer.tasa,
              plazo: offer.plazo,
              linkTexto: 'Escoger',
              url: this.getImageByProductCode(offer.subProducto.codigo),
              moneda: offer.moneda === 'USD' ? 'SOL' : offer.moneda,
              monedaSimbolo: offer.moneda === 'USD' ? 'S/ ' : '$ ',
              prioridad: offer.prioridad,
              tipo: offer.tipo,
              plazoMinimo: offer.plazo,
              plazoMaximo: plazoMax,
              productCode: producto,
              subProductCode: subproducto,
              enabled: response?.rules?.filter((f: any) => f.action === subproducto)?.length > 0 ? false : true
            };
            
            this.productos.push(model);
            this.offersFilter.push(model);
          }

          // Agrupamos los productos por nombre
          const dataOffersGroup = this.productos.reduce((acc: { [key: string]: any[] }, producto: { nombre: string }) => {
            if (!acc[producto.nombre]) {
              acc[producto.nombre] = [];
              this.listaAbierta[producto.nombre] = true;
            }
            acc[producto.nombre].push(producto);
            return acc;
          }, {});
          this.agrupadosPorNombre = dataOffersGroup;
          this.agrupadosPorNombreFiltrado = dataOffersGroup;

          this.mostrarInformacion = true;          
          this.createFilters(this.productos);
        }
        if(response?.numberMandatoryRules > 0 || response?.numberFilterRules) {
          this.showRules = true;
          this.showScrollButton = true;
        }
        this.showCustomerInformation = true;
        this.enabledMatriz();
      }
      else {
        this.mostrarInformacion = false;
        if(response?.numberMandatoryRules > 0 || response?.numberFilterRules) {
          this.showRules = true;
          this.showScrollButton = true;
          this.showCustomerInformation = true;
          this.enabledMatriz();
        } else {
          this.alertService.showMessage(AppConstants.TitleAlert.COMPLIANCE_TITLE, AppConstants.MessageAlert.COMPLIANCE_RETRIEVE_DOCUMENT_ERROR, MessageSeverity.info); 
        }
      }
      
    } catch (error) {
      this.alertService.showMessage(AppConstants.TitleAlert.COMPLIANCE_TITLE, AppConstants.MessageAlert.COMPLIANCE_APPLY_FILTERS_ERROR, MessageSeverity.error);
      console.log(error);
    }
  }

  isEnabled(items: any[]): boolean {
    return items.some(item => item?.enabled);
  }

  showControlsMarried(showSeccion: boolean, maritalStatus: boolean, spouseDoc: boolean, spouseDocNum: boolean) {
    this.showSpouse = showSeccion;
    this.maritalStatusBlocked = maritalStatus;
    this.spouseDocumentTypeBlocked = spouseDoc;
    this.spouseDocumentNumberBlocked = spouseDocNum;    
  }

  searchOfferWithSpouse() {
    if(this.estadoCivil === AppConstants.MaritalStatus.MARRIED) {
      this.validateFormMarried();
    }
    else {
      console.log("<========NO CASADO========>");
      this.searchOffer();
    }

  }

  validateFormMarried() {    
    this.isErrorDocumentTypeSpouse = this.documentTypeSpouse == null ? true : (this.documentTypeSpouse != '' ? false : true);
    this.isErrorDocumentNumberSpouse = this.dniSpouse == null ? true : (this.dniSpouse.length >= this.maxSpouse ? false : true);    

    const requiredElements = [
      this.isErrorDocumentNumberSpouse,
      this.isErrorDocumentTypeSpouse
    ];

    if (requiredElements.every(element => !element)) {

      let modelCustomerMarried = {
        documentType: this.selectedTypeDocument,
        documentNumber: this.documentForm,
        spouseDocumentType: this.documentTypeSpouse,
        spouseDocumentNumber: this.dniSpouse,
        userId: this.usuario,
        product: AppConstants.Products.Loans
      };

      this.validateApplicationInProgressWithSpouse(modelCustomerMarried);

    } else {
      this.alertService.showMessage("", AppConstants.MessageAlert.ERROR_FORM_REQUIRED, MessageSeverity.error);
      return;
    }
  }

  validateApplicationInProgressWithSpouse(model: any) {
    this.loading = true;
    this.applicationService.returnApplication(model).subscribe({
      next: (response: HttpResponse<any>) => {
        if (response.status === 204) {
          this.callGetOffersWithSpouse(model);
        } else {
          if (response.body.createdBy === this.usuario) {
            sessionStorage.setItem(AppConstants.Session.APPLICATION_ID, response.body.applicationId);
            sessionStorage.setItem(AppConstants.Session.WORKFLOW_ID, response.body.instannceId);

            var client = new ClienteSession();
                client.documentTypeDesciption = response.body.documentTypeDescription;
                client.documentType = response.body.documentType;
                client.documentNumber = response.body.documentNumber;
                client.fullName = response.body.fullName;
                client.userCreation = this.usuario;


            var product = new ProductoSession();
                product.image = response.body.product.image;
                product.name = response.body.product.name;

            sessionStorage.setItem(AppConstants.Session.CLIENT, JSON.stringify(client));    
            sessionStorage.setItem(AppConstants.Session.PRODUCT, JSON.stringify(product));
            sessionStorage.setItem(AppConstants.Session.SIMULACION, JSON.stringify({ loanAmountFormat: response.body.applicationAmountFormat}));

            this.router.navigateByUrl(response.body.path);
            this.loading = false;
          }
          else {
            this.loading = false;
            this.alertService.showMessage('Error', 'La solicitud '+ response.body.applicationId +' ya esta siendo tomada por el usuario ' + response.body.createdBy, MessageSeverity.error);
          }
        }
      },
      error: (error: HttpErrorResponse) => {
        this.loading = false;
        console.log('Error:', error);
        this.alertService.showMessage(AppConstants.TitleAlert.RETRIEVE_APPLICATION_TITLE, AppConstants.MessageAlert.RETRIEVE_APPLICATION_MESSAGE, MessageSeverity.error);
      },
      complete: () => {
      }
    });
  }

  callGetOffersWithSpouse(model: any) {
    this.clearWithSpouse();
    this.loading = true;
    this.searchDate = this.getCurrentDateTimeUTC5();
    this.offersService.postObtenerOfertas(model).subscribe({
      next: async (response: any) => {
        try {
          const lisFilter = response.datos.length > 0 && response.meta.totalRegistros > 0 ?
                          response.datos.filter((item: any) => {
                            return Array.from(this.productsProfile).some(p => item?.subProducto?.codigo?.includes(p));
                          }).sort((a: any, b: any)  => {
                            if (b.monto !== a.monto) {
                              return b.monto - a.monto;
                            } else {
                              return b.plazo - a.plazo;
                            }
                          }) : [];

          if(lisFilter.length > 0) {            
            this.dni = lisFilter[0].cliente.documento.numero;
            this.nombreCliente = lisFilter[0].cliente.nombresCompletos;
            this.numeroResultados = lisFilter.length;            
            this.offers = lisFilter;

            this.callComplianceClientTransitory(model);

          }
          else {
            this.loading = false;
            this.openModal('SIN OFERTA', 'No presenta ofertas por el momento', `DNI: ${this.documentForm}`, 'CERRAR');
          }
          
        } catch (error) {
          console.error(error);
        }      
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.CONSULT_OFFERS_TITLE, AppConstants.MessageAlert.OFFERS_RETRIEVE_ERROR, MessageSeverity.error);
        console.error(error);
      },
      complete: () => {
      }
    });        
  }

  clearformlength() {
    let document = this.documents.find((f: any) => f.value === this.selectedTypeDocument);
    if(this.documentForm.length < document.length) {
      this.clear();
    }    
  }

  clearWithSpouse() {
    this.offers = [];
    this.productos = [];
    this.dni = '';
    this.nombreCliente = '';
    this.numeroResultados = 0;
    this.filters = [];
    this.agrupadosPorNombreFiltrado = {};
    this.agrupadosPorNombre = {};
    this.listaAbierta = {};
    this.mostrarInformacion = false;
    this.showScrollButton = false;
    this.showRules = false;
    this.showCustomerInformation = false;
    // this.estadoCivil = '';
    // this.documentTypeSpouse = '1';
    // this.dniSpouse = '';
    // this.validateSpouseAdmision = false;
    // this.showControlsMarried(false, false, false, false);
    this.admissionRules = [];
    this.riskAssessment = [];
    this.resultAdmisionTransitory = {};
    this.showMatriz = false;
    this.showButtonExport = false;
  }

  downloadRiskAssessment() {
    let data: any = {
      date: this.getCurrentDateTimeUTC5Matrix(),
      user: this.usuario,
      documentType : this.resultAdmisionTransitory?.documentType === "1" ? "DNI" : this.resultAdmisionTransitory?.documentType,
      documentNumber: this.resultAdmisionTransitory?.documentNumber,
      FullName: this.nombreCliente,
      maritalStatusDescription: this.getLabelByValue(this.resultAdmisionTransitory?.maritalStatus, this.opcionesEstadoCivil),
      birthDate: this.resultAdmisionTransitory?.birthDate,
      age: this.resultAdmisionTransitory?.age,
      employmentStatus: this.resultAdmisionTransitory?.employmentStatus,
      rucCompany: this.resultAdmisionTransitory?.rucCompany,
      riskAssessment: this.riskAssessment
    };

    this.pdfmakeService.openRiskAssessment(data);

  }

  getLabelByValue(value: string, list: any[]): string {
    const item = list.find((i) => i.value === value);
    return item ? item.label : '';
  }

  getCurrentDateTimeUTC5Matrix(): string {
    const currentDateUTC = new Date(); 
    const utcMinus5Offset = 5 * 60 * 60 * 1000;

    const currentDateUTC5 = new Date(currentDateUTC.getTime() - utcMinus5Offset);

    const day = String(currentDateUTC5.getUTCDate()).padStart(2, '0');
    const month = String(currentDateUTC5.getUTCMonth() + 1).padStart(2, '0');
    const year = currentDateUTC5.getUTCFullYear();
    const hours = String(currentDateUTC5.getUTCHours()).padStart(2, '0');
    const minutes = String(currentDateUTC5.getUTCMinutes()).padStart(2, '0');
    const seconds = String(currentDateUTC5.getUTCSeconds()).padStart(2, '0');

    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  }

  hiddenExportButton() {
    this.showButtonExport = !this.showButtonExport;
  }

  enabledMatriz() {
    if(this.rolCode === AppConstants.Roles.ASESOR_VENTA_OFICINA) {
      this.showMatriz = true;
      this.showButtonExport = true;
    }
    else {
      this.showMatriz = false;
      this.showButtonExport = false;
    }
  }  

}
