<app-header [headerText]="'Validación Email'"></app-header>
<div class="contenedor-principal">
  <div class="contenedor-imagen">
    <img src="assets/img/pasivos_validacion_1.svg" alt="Descripción de la imagen">
  </div>
  <div class="contenedor-texto">
    <p >Se envió  un código de verificación <br>al email del cliente.</p>
    <p class="texto-validacion">ejemplo&#64;gmail.com</p>
    <p class="tiempo-validacion">00:00</p>
    <app-custom-input></app-custom-input>
    <div class="contenedor-botones">
      <app-custom-button-color text="Enviar a Creditos"></app-custom-button-color>
      <app-custom-button-color text="No Continuar esta solicitud"></app-custom-button-color>
    </div>
   
  </div>
</div>

<app-footer></app-footer>

