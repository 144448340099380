import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { AppConstants } from "../shared/constants/app-constants";
import { Injectable } from "@angular/core";

@Injectable()
export class AuthGuard {
  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (sessionStorage.getItem(AppConstants.Session.USERNAME)) {
      return true;
    }

    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}