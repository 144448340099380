<div class="custom-input-container">
    <input type="text" class="custom-input" 
      [placeholder]="placeholderText"
      [(ngModel)]="value" 
      [required]="isRequired"
      [disabled]="isDisabled"
      [ngClass]="{'error': isError}"  
      (ngModelChange)="onValueChange($event)"      
    >
    <img *ngIf="isError" src="assets/alerta.svg" class="alert-icon" />
  </div>
  