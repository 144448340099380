import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-custom-button-color',
  standalone: true,
  imports: [MatToolbarModule, MatButtonModule, MatIconModule, CommonModule],
  templateUrl: './custom-button-color.component.html',
  styleUrls: ['./custom-button-color.component.scss']
})
export class CustomButtonColorComponent {
  @Input() iconUrl?: string;
  @Input() text?: string;
  @Input() isDisabled: boolean = false;
  @Output() action = new EventEmitter<void>();  // Cambio: emitir sin el evento específico
  @Input() buttonWidth?: string;
  @Input() backgroundColor: string = '#fff';  // Valor inicial
  @Input() textColor: string = '#20A3FF'; 
  @Input() isActive: boolean = false;
  
  onClick(): void {
    this.action.emit();
  }
}
