import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomTableSimulatorComponent, DynamicModalComponent } from 'app/components';
import { Router } from '@angular/router';
import { HostListener } from '@angular/core';
import { UserService } from 'app/services/user.service';
import { AlertService } from 'app/shared/services/alert.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-admin-usuarios',
  templateUrl: './admin-usuarios.component.html',
  styleUrls: ['./admin-usuarios.component.scss']
})
export class AdminUsuariosComponent {

  showModal = false;
  esMovil: boolean = false;
  isError: boolean = false;
  nroResultados: number = 12;
  nameUser: string = '';
  max: number = 100;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.esMovil = window.innerWidth <= 768;
  }

 
  @ViewChild('myTable') tableComponent!: CustomTableSimulatorComponent;
  columnNames = ['Usuario', 'Nombre', 'Usuario Creación', 'Estado ', 'Fecha Creación', 'Fecha Modificacion', 'Editar'];

  showModalFiltros =false;
  modalWidth: number = 480; 
  modalHeight: number = 380;
  usersData: any[] = [];
  
  
  loading: boolean = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private alertService: AlertService,
  ) { }


  async ngOnInit() {
    await this.getUsers();
  }

  async getUsers() {
    this.loading = true;
    let model = {
      name: this.nameUser
    };
    await lastValueFrom(this.userService.retrieveUsers(model)).then((response: any) => {
      this.usersData = response.map((element: any) => ({
          'Usuario': element.userId,
          'Nombre': element.fullName,
          'Usuario Creación': element.createdBy,
          'Estado ': element.stateDescription,          
          'Fecha Creación': element.createdDateTimeFormat,
          'Fecha Modificacion': element.updatedDateTimeFormat,
          'Editar': "<a href='#'>Editar</a>",
          userId: element.userId,
          name: element.fullName,
          stateId: element.stateId
        }));
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    }).catch((error) => {
      this.loading = false;
      console.error(error);
    });    
  }  


  addUsuario(accion: string){
    const metadata = {
      title: "Agregando Usuario",
      create: true     
    };

    this.router.navigate(['admin-usuarios-acciones'], { state: { metadata } });
  }
  
  // navigateFromParent(row: any) {
  //   // Lógica de navegación personalizada aquí, por ejemplo:
  //   this.router.navigate(['bandeja-solicitudes-producto']);
  // }

  async clear() {
    this.nameUser = '';
    await this.getUsers();
  }

  async seach() {
    await this.getUsers();
  }

  editUser(row: any) {
    const metadata = {
      title: "Actualizar Usuario",
      create: false,
      data: row
    };

    this.router.navigate(['admin-usuarios-acciones'], { state: { metadata } });
  }

}
