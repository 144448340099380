import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { IndividualConfig, Toast, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private toastaService: ToastrService
  ) { }

  public showMessage(title: string, message: string, severity : number, timeout = 3000) {

    const toastOptions: IndividualConfig<any> = {
      disableTimeOut: false,
      timeOut: timeout,
      closeButton: true,
      extendedTimeOut: 1000,      
      progressBar: true,
      progressAnimation: 'decreasing',
      enableHtml: false,
      toastClass: 'ngx-toastr',
      positionClass: 'toast-top-right',
      titleClass: 'toast-title',
      messageClass: 'toast-message',
      easing: 'ease-in',
      easeTime: 300,
      tapToDismiss: true,
      toastComponent: Toast,
      onActivateTick: false,
      newestOnTop: true
    };
    

    if (severity == MessageSeverity.info)
      this.toastaService.info(message, title, toastOptions);
    else if (severity == MessageSeverity.success)
      this.toastaService.success(message, title, toastOptions);
    else if (severity == MessageSeverity.error)
      this.toastaService.error(message, title, toastOptions);
    else if (severity == MessageSeverity.warn)
      this.toastaService.warning(message, title, toastOptions);
    else if (severity == MessageSeverity.info)
      this.toastaService.info(message, title, toastOptions);
  }

}

export enum MessageSeverity {
  default,
  info,
  success,
  error,
  warn
}
