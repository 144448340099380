import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
  animations: [
    trigger('fadeInUp', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(15px)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ])
    ])
  ]
})
export class InfoModalComponent {
  @Input() titulo: string = '';
  @Input() imagenUrl: string = '';
  @Input() width: number = 600;
  @Input() height: number = 400; 
  @Output() onClose = new EventEmitter<void>();

  onOverlayClick(): void {
    this.onClose.emit(); // Emite el evento para cerrar el modal
  }

  onModalContentClick(event: MouseEvent): void {
    event.stopPropagation(); // Previene que el evento de clic se propague al overlay
  }
}
