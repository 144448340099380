
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidenavComponent } from './components';
import { CustomButtonComponent } from './components/custom-button/custom-button.component';
import { DynamicModalComponent } from './components/dynamic-modal/dynamic-modal.component';
import { CustomInputComponent } from './components/custom-input/custom-input.component';
import { CustomInpuntSelectComponent } from './components/custom-inpunt-select/custom-inpunt-select.component';
import { CustomTableSimulatorComponent } from './components/custom-table-simulator/custom-table-simulator.component';
import { FooterComponent } from './components/footer/footer.component';
import { CustomButtonColorComponent } from './components/custom-button-color/custom-button-color.component';
import { LineDividerComponent } from './components/line-divider/line-divider.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { ModalComponent } from './components/modal/modal.component';
import { CustomTablePaginatorComponent } from './components/custom-table-paginator/custom-table-paginator.component';
import { InfoModalComponent } from './components/info-modal/info-modal.component';
import { BackLinkComponent } from './components/back-link/back-link.component';
import { AdjuntoModalComponent } from './components/adjunto-modal/adjunto-modal.component';
import { CustomButtonBorderComponent } from './components/custom-button-border/custom-button-border.component';
import { CustomInputPasswordComponent } from './components/custom-input-password/custom-input-password.component';
import { ModalResumenComponent } from './components/modal-resumen/modal-resumen.component';
import { CustomDatepickerComponent } from './components/custom-datepicker/custom-datepicker.component';
import { CustomButtonCircleComponent } from './components/custom-button-circle/custom-button-circle.component';
import { CustomTextAreaComponent } from './components/custom-text-area/custom-text-area.component';
import { CustomInputLoginComponent } from './components/custom-input-login/custom-input-login.component';
import { CustomInputPasswordLoginComponent } from './components/custom-input-password-login/custom-input-password-login.component';
import { CustomButtonLinkComponent } from './components/custom-button-link/custom-button-link.component';
import { CustomInputFlexComponent } from './components/custom-input-flex/custom-input-flex.component';
import { CustomInputSwitchComponent } from './components/custom-input-switch/custom-input-switch.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { HttpClientModule } from '@angular/common/http';
import { InputMaterialComponent } from './components/input-material/input-material.component';
import { InfoUsuarioModalComponent } from './components/info-usuario-modal/info-usuario-modal.component';
import { CustomTabComponent } from './components/custom-tab/custom-tab.component';
import { ToastrModule } from 'ngx-toastr';
import { AuthGuard } from './guards/auth.guard';
import { CustomInputAutocompleteComponent } from './components/custom-input-autocomplete/custom-input-autocomplete.component';
import { ProfileGuard } from './guards/profile.guard';


@NgModule({
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AuthGuard,
    ProfileGuard
  ],
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SidenavComponent,
    CustomButtonComponent,
    DynamicModalComponent,
    CustomInputComponent,
    CustomButtonColorComponent,
    CustomInpuntSelectComponent,
    CustomTableSimulatorComponent,
    BrowserModule,
    FooterComponent,
    LineDividerComponent,
    StepperComponent,
    ModalComponent,
    CustomTablePaginatorComponent,
    InfoModalComponent,
    BackLinkComponent,
    AdjuntoModalComponent,
    CustomButtonBorderComponent,
    CustomInputPasswordComponent,
    ModalResumenComponent,
    CustomDatepickerComponent,
    CustomButtonCircleComponent,
    CustomTextAreaComponent,
    CustomInputLoginComponent,
    CustomInputPasswordLoginComponent, 
    CustomButtonLinkComponent, 
    CustomInputFlexComponent,
    CustomInputSwitchComponent,
    InputMaterialComponent,
    InfoUsuarioModalComponent,
    CustomTabComponent,
    CustomInputAutocompleteComponent,
    ToastrModule.forRoot()
  ],
  
  bootstrap: [AppComponent]
})
export class AppModule { }
