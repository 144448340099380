<div class="tab-container">
  <div *ngFor="let tab of tabs" class="tab" [class.active]="tab.active" (click)="selectTab(tab)">
    <button>
      {{ tab.title }}
      <img *ngIf="tab.completed" src="assets/img/check_finalizacion.svg" alt="Completed" class="check-icon">
    </button>
  </div>
</div>
<div class="content-container">
  <ng-content></ng-content>
</div>
