import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CustomButtonComponent } from '../custom-button';
import { CustomButtonColorComponent } from '../custom-button-color';
import { CustomInputComponent } from '../custom-input';
import { CustomButtonBorderComponent } from '../custom-button-border';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  standalone: true,
  selector: 'app-adjunto-modal',
  imports: [CommonModule, FormsModule,CustomButtonComponent,CustomButtonComponent,CustomButtonColorComponent,CustomButtonBorderComponent,CustomInputComponent],
  templateUrl: './adjunto-modal.component.html',
  styleUrls: ['./adjunto-modal.component.scss'],
  animations: [
    trigger('fadeInUp', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(15px)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ])
    ])
  ]
})
export class AdjuntoModalComponent {

  files: any[] = [];
  @Input() applicationFiles: any[] = [];
  @Input() width: number = 400;
  @Input() height: number = 350; 
  // @Output() onCancel = new EventEmitter<void>();
  @Output() onClose = new EventEmitter<void>();
  // @Output() linkAction = new EventEmitter<void>();
  @Output() onFileSelectedAction = new EventEmitter<{ file: any }>();
  @Output() onFileDownloadAction = new EventEmitter<{ file: any }>();
  @Output() onFileDeleteAction = new EventEmitter<{ file: any }>();
  @Input() value: string = '';
  @Output() valueChange = new EventEmitter<string>();
  @Input() isRequired: boolean = false; 
  @Output() onSave = new EventEmitter<void>();

  close() {
    this.onClose.emit(); 
  }

  // onLinkClick() {
  //   this.linkAction.emit();
  // }

  save() {
    this.onSave.emit(); 
  }

  onFileSelected(event: Event) {
    this.onFileSelectedAction.emit({ file: event });
  }

  onFileDownload(event: Event) {
    this.onFileDownloadAction.emit({ file: event });
  }

  onFileDelete(event: Event) {
    this.onFileDeleteAction.emit({ file: event });
  }

  onValueChange(value: string): void {
    this.valueChange.emit(value);
  }
  
}
