import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomButtonComponent } from '../custom-button';
import { Router } from '@angular/router';
import { CustomButtonColorComponent } from '../custom-button-color';
import { ApplicationService } from 'app/services/application.service';
import { lastValueFrom } from 'rxjs';
import { AppConstants } from 'app/shared/constants/app-constants';

@Component({
  standalone: true,
  imports: [CommonModule,CustomButtonComponent,CustomButtonColorComponent],
  selector: 'app-modal-resumen',
  templateUrl: './modal-resumen.component.html',
  styleUrls: ['./modal-resumen.component.scss']
})
export class ModalResumenComponent {

  usuario: string = sessionStorage.getItem(AppConstants.Session.USERID) ?? '';
  agenciaName: string = sessionStorage.getItem(AppConstants.Session.AGENCYNAME) ?? '';
  agenciaCode: string = sessionStorage.getItem(AppConstants.Session.AGENCYCODE) ?? '';
  canalVenta: string = sessionStorage.getItem(AppConstants.Session.SALES_CHANNEL_DESCRIPTION) ?? '';
  rolName: string = sessionStorage.getItem(AppConstants.Session.USERROLNAME) ?? '';
  rolCode: string = sessionStorage.getItem(AppConstants.Session.USERROLID) ?? '';
  userName: string = sessionStorage.getItem(AppConstants.Session.USERNAME) ?? '';
  salesChannel: string = sessionStorage.getItem(AppConstants.Session.SALESCHANNEL) ?? '';

  @Input() width: number = 750;
  @Input() height: number = 800; 
  @Output() onClose = new EventEmitter<void>(); // Emite un evento cuando el modal debe cerrarse
  @Input() imageUrl: string ="";
  @Input() titleText: string="";      
  @Input() subtitleText: string="";   
  @Input() paragraphText: string="";
  @Input() linkText: string="";
  @Input() body: any;
  @Output() linkAction = new EventEmitter<void>();

  arrayMessages: any = [];
  isLoadingProcess = false;
  messagestatusResult = '';
  classmessage = '';
  errorProcess = false;
  maxAttempts = false;
  maxiError = 0;
  validatedOperation = false;

  constructor(
    private router: Router,
    private applicationService: ApplicationService) 
  {
  }

  ngOnInit() {
    this.isLoadingProcess = true;
    this.callFinish();
  }

  

  close() {
    this.onClose.emit();
  }

  onLinkClick() {
    this.linkAction.emit();
  }

  redireccionar(){
    this.router.navigate(['finalizacion']);
  }

  returnTray() {
    this.router.navigate(['bandeja-solicitudes']);
  }

  async finishApplication(model: any) 
  {
    await lastValueFrom(this.applicationService.postFinishApplication(model)).then((response: any) => {
      this.arrayMessages = [...response.messages.filter((f: any) => f.status !== 'NA')];
      console.log(this.arrayMessages);
      this.maxAttempts = response.maxAttempts;
      this.maxiError = 0;

      if (response.code === 400) {
        this.errorProcess = true;
        this.isLoadingProcess = false;
        this.classmessage = 'modal-texto-error';
        this.messagestatusResult = response.errorMessage != "" ? response.errorMessage : (this.maxAttempts === true ? 'Usted superó el número de reintentos, favor comunicarse con el área de soporte.' : 'Finalizado con Error, porfavor vuelva a intentar');
      } else if (response.pending && !response.finish) {
        this.validatedOperation = response.validatedOperation;
        this.errorProcess = false;
        this.callFinish();
      } else if (!response.pending && response.finish) {
        this.errorProcess = false;
        this.classmessage = 'modal-texto-exito';
        this.messagestatusResult = 'FINALIZADO CON EXITO';
        this.isLoadingProcess = false;
        // this.returnTray();
      }

    }).catch((error) => {
      console.error(error);
      this.isLoadingProcess = false;
      this.errorProcess = true;
      this.maxiError = this.maxiError +1;
      if(this.maxiError == 3)
      {
          this.maxAttempts = true;
      }
    });
  }

  callFinish() 
  {

    let model = {
      applicationId: this.body.applicationId,
      workflowInstanceId: this.body.workflowInstanceId,
      status: AppConstants.ApplicationStates.Aprobado,
      processId: AppConstants.Process.PLD_DRACMA_APROBACION,
      taskId: AppConstants.WorkflowTasks.APPROVAL,
      channelId: AppConstants.Channels.DesktopPVA,
      TaskInstanceRole: this.rolName,
      UpdatedBy: this.usuario,
      UpdatedByFullName: this.userName,
      AgencyCode: this.agenciaCode,
      AgencyName: this.agenciaName,
      commentKey: AppConstants.ApplicationComment.DISBURSEMENT_USER_CODE,
      comment: this.body.comment,
      commentReason: this.body.reason,
      validatedOperation: this.validatedOperation
    };

    this.finishApplication(model);
  }

  retryFinish() 
  {
    this.isLoadingProcess = true;
    this.errorProcess = false;
    this.arrayMessages = [];
    this.messagestatusResult = '';
    this.classmessage = '';
    this.callFinish();
  }

}
