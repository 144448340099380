import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'app-dynamic-modal',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './dynamic-modal.component.html',
  styleUrls: ['./dynamic-modal.component.scss']
})
export class DynamicModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
}
