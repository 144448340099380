<div class="pagination-container">
  <table>
    <thead>
      <tr>
        <th *ngFor="let col of columnNames">{{ col }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of currentPageData">
        <td *ngFor="let col of columnNames; let i = index" [ngClass]="getClassForColumn(i)">
          <div class="producto-container"  *ngIf="col === 'Producto'">
              <img src={{row.productNamImage}} alt="icon">
              <div>{{ row[col] }}</div>
          </div>

          <div class="producto-container"  *ngIf="col === 'Cliente'">
            <span [innerHTML]="row[col]"></span>
          </div>

          <div class="producto-container" *ngIf="col === 'Estado'">
            <ng-container *ngIf="row[col] === 'APROBADO'; else otherStatus">
              <img src="assets/estatusAprobado.svg" alt="icon Aprobado">
              <div>{{ row[col] }}</div>
            </ng-container>
            <ng-template #otherStatus>
              <ng-container *ngIf="row[col] === 'ANULADO'; else finalizado">
                <img src="assets/estatusRechazado.svg" alt="icon Rechazado">
                <div>{{ row[col] }}</div>
              </ng-container>
              <ng-template #finalizado>
                <ng-container *ngIf="row[col] === 'FINALIZADO'; else defaultStatus">
                  <img src="assets/estatusFinalizado.svg" alt="icon Finalizado">
                  <div>{{ row[col] }}</div>
                </ng-container>
          
                <!-- Plantilla por defecto si no se cumple ninguno de los anteriores -->
                <ng-template #defaultStatus>
                  <img src="assets/estatusPendienteAprobado.svg" alt="icon Finalizado">
                  <div>{{ row[col] }}</div>
                </ng-template>
              </ng-template>
            </ng-template>
          </div>
          
        
          <span *ngIf="col === 'Acciones'">
            <button class="button-as-link" (click)="navigateTo(row)"><strong>{{ actionButtonText }}</strong></button>
          </span>

          <span *ngIf="col === 'Editar'">
            <button class="button-as-link" (click)="onSelectRow(row)">{{ actionButtonText }}</button>
          </span>
          
          <span *ngIf="col !== 'Producto' && col !== 'Estado' && col !== 'Acciones' && col !== 'Cliente' && col !== 'Editar'">{{ row[col] }}</span>
        </td>
      </tr>
      
      
    </tbody>
  </table>

  <div class="pagination">
    <button (click)="paginate(currentPage - 1)" [disabled]="currentPage === 1">Anterior</button>
    <ng-container *ngFor="let page of paginationNumbers">
      <button *ngIf="page !== '...'" (click)="onPageClick(page)" [class.active]="currentPage === page">{{ page }}</button>
      <span *ngIf="page === '...'" class="dots">...</span>
    </ng-container>
    <button (click)="paginate(currentPage + 1)" [disabled]="currentPage * itemsPerPage >= totalItems">Siguiente</button>
  </div>
</div>



  