import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule],
  selector: 'app-custom-input-autocomplete',
  templateUrl: './custom-input-autocomplete.component.html',
  styleUrls: ['./custom-input-autocomplete.component.scss']
})
export class CustomInputAutocompleteComponent {
  @Input() placeholderText: string = '';
  @Input() type: string = 'text';
  @Input() maxlength: number = 100;
  @Input() label: string = '';
  @Input() width: string = '100%';
  @Input() value: string = '';
  @Output() valueChange = new EventEmitter<string>();
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() isError: boolean = false;
  @Input() borderColor: string = '';
  @Input() validateNumber: boolean = false;
  @Input() validateEmail: boolean = false;
  @Input() maxLength: number | null = null;
  @Input() options: string[] = [];

  @ViewChild('myInput') myInput!: ElementRef<HTMLInputElement>;

  showOptions: boolean = false;
  filteredOptions: string[] = [];
  selectedIndex: number = -1;

  @HostBinding('style.width') get styleWidth() {
    return this.width;
  }

  onValueChange(value: string): void {
    this.isError = false;
    this.filterOptions(value);
    this.valueChange.emit(value);
  }

  onInputChange(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    this.filterOptions(inputValue);
    this.showOptions = this.filteredOptions.length > 0;
  }

  onKeydown(event: KeyboardEvent): void {
    switch (event.key) {
      case 'ArrowUp':
        this.navigateOptions(-1);
        break;
      case 'ArrowDown':
        this.navigateOptions(1);
        break;
      case 'Enter':
        this.selectOption(this.filteredOptions[this.selectedIndex]);
        break;
      case 'Escape':
        this.showOptions = false;
        break;
    }
  }

  onBlur(): void {
    this.showOptions = false;
  }

  filterOptions(value: string): void { 
    this.filteredOptions = this.options.filter(option =>
      option.toLowerCase().includes(value.toLowerCase())
    );
    this.selectedIndex = -1;
  }

  navigateOptions(direction: number): void {
    this.selectedIndex = (this.selectedIndex + direction + this.filteredOptions.length) % this.filteredOptions.length;
  }

  selectOption(option: string): void {
    this.value = option;
    this.showOptions = false;
    this.valueChange.emit(option);
  }

  focusInput() {
    if (this.myInput && this.myInput.nativeElement) {
      this.myInput.nativeElement.focus();
    }
  }
}