import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  private showSidenav = new BehaviorSubject<boolean>(true);

  setShowSidenav(show: boolean) {
    this.showSidenav.next(show);
  }

  getShowSidenav() {
    return this.showSidenav.asObservable();
  }
}
