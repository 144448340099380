import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = "application";
  private BASE_URL: string = environment.applicationApiEndpoint;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  returnApplication(model: any) {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/retrieve`, model, { observe: 'response' });
  }

  getApplication(code: string) {
    return this.httpClient.get<any>(`${this.REQUEST_URL}/${code}`);
  }

  getApplications(model: string) {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/getApplicationsOnTray`, model);
  }

  postRegisterApplication(model: any) {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/creation`, model);
  }
  
  postUpdationApplication(model: any) {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/updation`, model);
  }

  postRegisterDisbursementMode(model: any) {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/disbursementMode/creation`, model);
  }

  getOptionalInsurances(code: string) {
    return this.httpClient.get<any>(`${this.REQUEST_URL}/optionalInsurances/${code}`);
  }

  postUpdationStatusApplication(model: any) {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/updation/status`, model);
  }

  getCheckStatusApplication(applicationId: string) {
    return this.httpClient.get<any>(`${this.REQUEST_URL}/checkStatus/${applicationId}`);
  }

  postFinishApplication(model: any) {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/updation/finish`, model);
  }

  postFaceAuthentication(model: any) {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/zytrus/auth/face`, model);
  }

  getBiometricsNotification(applicationId: string) {
    return this.httpClient.get<any>(`${this.REQUEST_URL}/notification/biometrics/face/retrieve/${applicationId}`);
  }

  postCheckStatusAuthentication(model: any) {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/biometrics/face/validate`, model);
  }

  getEmailNotification(applicationId: string) {
    return this.httpClient.get<any>(`${this.REQUEST_URL}/checkOtpSend/${applicationId}`);
  }

  postSendEmailVerification(model: any) {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/notification/email`, model);
  }

  postEmailVerify(model: any) {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/email/verify`, model);
  }

  getCheckValidatedEmail(applicationId: string) {
    return this.httpClient.get<any>(`${this.REQUEST_URL}/email/validated/${applicationId}`);
  }

  postUploadFile(model: any) {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/file/upload`, model);
  }

  getFilesApplication(code: string) {
    return this.httpClient.get<any>(`${this.REQUEST_URL}/files/retrieve/${code}`);
  }

  downloadFile(keyId: string): Observable<Blob> {
    return this.httpClient.post<Blob>(`${this.REQUEST_URL}/file/download/${keyId}`, keyId,
      {responseType: 'blob' as 'json'})
      .pipe(catchError(this.parseErrorBlob));
  }

  parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    return new Observable((observer) => {
      const reader = new FileReader();
  
      reader.onload = () => {
        try {
          observer.error(JSON.parse(reader.result as string));
        } catch (error) {
          observer.error(error);
        }
        observer.complete();
      };
  
      reader.onerror = (e) => {
        observer.error(e);
        observer.complete();
      };
  
      reader.readAsText(err.error);
    });
  }

  postDeleteFile(model: any) {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/file/delete`, model);
  }

  postCancellationApplication(model: any) {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/updation/status/cancellation`, model);
  }

  postDeclineApplication(model: any) {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/updation/status/decline`, model);
  }

  getApplicationComments(code: string) {
    return this.httpClient.get<any>(`${this.REQUEST_URL}/comments/retrieve/${code}`);
  }

  postSendApprovalApplication(model: any) {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/updation/status/send/approval`, model);
  }

  postSendAddComentApplication(model: any) {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/comment/add`, model);
  }

  downloadReportApplication(model: string): Observable<Blob> {
    return this.httpClient.post<Blob>(`${this.REQUEST_URL}/reports/app/retrieve`, model,
      {responseType: 'blob' as 'json'})
      .pipe(catchError(this.parseErrorBlob));
  }

  downloadReportTramaDatalake(model: string): Observable<Blob> {
    return this.httpClient.post<Blob>(`${this.REQUEST_URL}/reports/bi/retrieve`, model,
      {responseType: 'blob' as 'json'})
      .pipe(catchError(this.parseErrorBlob));
  }

  downloadReportOptionalInsurances(model: string): Observable<Blob> {
    return this.httpClient.post<Blob>(`${this.REQUEST_URL}/reports/optInsurances/retrieve`, model,
      {responseType: 'blob' as 'json'})
      .pipe(catchError(this.parseErrorBlob));
  }

  postRegisterApplicationSavings(model: any) {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/savings/creation`, model);
  }

  postRegisterSavingAccountType(model: any) {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/saving-account/type/creation`, model);
  }

  getApplicationSavingAccount(code: string) {
    return this.httpClient.get<any>(`${this.REQUEST_URL}/saving-account/retrieve/${code}`);
  }

  retrieveCustomerInformation(model: any) {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/customer/personal-information/retrieve`, model, { observe: 'response' });
  }

  postValidatePromisoryNote(model: any) {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/loans/confirmation/execution`, model);
  }

  downloadFileAutoComplete(model: string): Observable<Blob> {
    return this.httpClient.post<Blob>(`${this.REQUEST_URL}/file/download/document/filler/execution`, model,
      {responseType: 'blob' as 'json'})
      .pipe(catchError(this.parseErrorBlob));
  }

}
