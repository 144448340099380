import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { AppConstants } from '../shared/constants/app-constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Obtén el token usando el AuthService
    const authToken = sessionStorage.getItem(AppConstants.Session.ACCESS_TOKEN);

    // Clona la solicitud para añadir el nuevo encabezado de autorización.
    const authReq = req.clone({
      setHeaders: { Authorization: `Bearer ${authToken}` }
    });

    // Envía la solicitud clonada con el encabezado de autorización a la siguiente cadena de manejo.
    return next.handle(authReq);
  }
}
