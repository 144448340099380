import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule],
  selector: 'app-custom-tab',
  templateUrl: './custom-tab.component.html',
  styleUrls: ['./custom-tab.component.scss']
})
export class CustomTabComponent {
  @Input() tabs: any[] = [];

  selectTab(tab: any) {
    this.tabs.forEach(t => t.active = false);
    tab.active = true;
  }

  markAsCompleted(tab: any) {
    tab.completed = true; 
  }
}
