import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, HostListener, Output } from '@angular/core';
import { ClienteSession, ProductoSession } from 'app/models/sessionModel';
import { ApplicationService } from 'app/services/application.service';
import { ParameterService } from 'app/services/parameter.service';
import { AppConstants } from 'app/shared/constants/app-constants';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';
import { HelperService } from 'app/shared/services/helper.service';
import * as FileSaver from 'file-saver';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-datos-generales',
  templateUrl: './datos-generales.component.html',
  styleUrls: ['./datos-generales.component.scss']
})
export class DatosGeneralesComponent {
  
  applicationId: string = sessionStorage.getItem(AppConstants.Session.APPLICATION_ID) ?? '';
  workflowId: string = sessionStorage.getItem(AppConstants.Session.WORKFLOW_ID) ?? '';  
  usuario: string = sessionStorage.getItem(AppConstants.Session.USERID) ?? '';
  userName: string = sessionStorage.getItem(AppConstants.Session.USERNAME) ?? '';

  simulation: any = JSON.parse(sessionStorage.getItem(AppConstants.Session.SIMULACION) ?? '{}');
  client: ClienteSession = JSON.parse(sessionStorage.getItem(AppConstants.Session.CLIENT) ?? '{}');

  application: any = {};


  showModal = false;
  showDatosConyugue = false;
  esMovil: boolean = window.innerWidth <= 768;


  //variables formulario
  tipoDocumento: string = this.client.documentType;
  nroDocInput: string = this.client.documentNumber;
  primerNombre: string = this.FirstName(this.client.fullName);
  segundoNombre: string = '';
  apellidoPaterno: string = '';
  apellidoMaterno: string = '';
  fechaNacimiento: string = '';
  nacionalidad: string = '';
  genero: string ='';
  countryDomicile: string = '';
  housingType: string = '';
  employmentRelationship: string = '';
  estadoCivil: string = '';
  cellphone: string = '';
  conyugueDocumentType: string = '';
  conyugueDocumentNumber: string = '';
  conyugueNames: string = '';
  conyugueLastName: string = '';
  conyugueSecondLastName: string = '';
  email: string = '';
 

  //Validators
  isErrorTipoDocumento: boolean = false;
  isErrorNroDocInput: boolean = false;
  isErrorPrimerNombre: boolean = false;  
  isErrorSegundoNombre: boolean = false;  
  isErrorApellidoPaterno: boolean = false;
  isErrorApellidoMaterno: boolean = false;
  isErrorFechaNacimiento: boolean = false;
  isErrorNationality: boolean = false;
  isErrorGenero: boolean = false;
  isErrorEstadoCivil: boolean = false;
  isErrorCountryDomicile: boolean = false;
  isErrorHousingType: boolean = false;
  isErrorEmploymentRelationship: boolean = false;
  isErrorCellphone: boolean = false;
  isErrorEmail: boolean = false;

  isErrorDocumenTypeSpouse: boolean = false;
  isErrorDocumenNumberSpouse: boolean = false;
  isErrorSpouseNames: boolean = false;
  isErrorSpouseLastName: boolean = false;
  isErrorSpouseSecondLastName: boolean = false;

  opcionesGenero = [
    { label: 'MASCULINO', value: 'M' },
    { label: 'FEMENINO', value: 'F' }
  ];

  opcionesEstadoCivil: any = [];
  opcionesPaisDomicilio: any = [];
  opcionesRelacionLaboral: any = [];
  opcionesTipoVienda: any = [];


  loading: boolean = false;
  documents: any = [];

  elementsReadOnly: any = [];
  typeInputNumber: string = 'tel';
  max: number = 9;

  filesList: any = [];
  sizeFile: any = [];
  fileTemp!: File;
  comment: string = '';

  primerNombreValMin: number = 3;
  primerNombreValMax: number = 30;
  segundoNombreValMin: number = 0;
  segundoNombreValMax: number = 30;
  apellidoPaternoValMin: number = 3;
  apellidoPaternoValMax: number = 30;
  apellidoMaternoValMin: number = 3;
  apellidoMaternoValMax: number = 30;
  emailValMax: number = 40;

  clientPep: boolean = false;

  @Output() cambiarComponente = new EventEmitter<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private parameterService: ParameterService,
    private alertService: AlertService,
    private applicationService: ApplicationService,
    private helperService: HelperService
  ) {}

  async ngOnInit () {
    await this.getParameters([
      AppConstants.ParameterCode.DOCUMENT_TYPE,
      AppConstants.ParameterCode.NACIONALITY,
      AppConstants.ParameterCode.MARITAL_STATUS,
      AppConstants.ParameterCode.EMPLOYMENT_RELATIONSHIP,
      AppConstants.ParameterCode.ELEMENTS_BLOCKED,
      AppConstants.ParameterCode.HOUSING_SITUATION,
      AppConstants.ParameterCode.SIZE_FILES
    ]);
    await this.getApplication(this.applicationId);
    await this.getAttachmentFiles(this.applicationId);
    // this.dataMockup();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.esMovil = window.innerWidth <= 768;
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  async openAttachmentModal() {
    this.showModal = true;
    await this.retrieveApplicationComments(this.applicationId);
  }

  handleDateChange(): void {
    // console.log("Nueva fecha de nacimiento:", this.fechaNacimiento);
  }

  validarYCambiarComponente() {

    //Validaciones
    this.isErrorTipoDocumento = !this.tipoDocumento;
    this.isErrorNroDocInput = !this.nroDocInput;
    this.isErrorPrimerNombre = this.primerNombre == null ? true : (this.primerNombre.length >= this.primerNombreValMin ? false : true);
    this.isErrorSegundoNombre = this.segundoNombreValMin === 0 ? false : (this.segundoNombre.length >= this.segundoNombreValMin ? false : true);
    this.isErrorApellidoPaterno = this.apellidoPaterno == null ? true : (this.apellidoPaterno.length >= this.apellidoPaternoValMin ? false : true);
    this.isErrorApellidoMaterno = this.apellidoMaterno == null ? true : (this.apellidoMaterno.length >= this.apellidoMaternoValMin ? false : true);
    this.isErrorFechaNacimiento = !this.validateYearAndAge(this.fechaNacimiento);
    this.isErrorNationality = !this.nacionalidad;
    this.isErrorGenero = !this.genero;
    this.isErrorEstadoCivil = !this.estadoCivil;
    this.isErrorCountryDomicile = !this.countryDomicile;
    this.isErrorHousingType = !this.housingType;
    this.isErrorEmploymentRelationship = !this.employmentRelationship;
    this.isErrorCellphone = this.cellphone == null ? true : (this.cellphone.length === this.max ? !this.cellphone : true);
    this.isErrorEmail = !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.email);

    if(this.showDatosConyugue) {
      this.isErrorDocumenTypeSpouse = !this.conyugueDocumentType;
      this.isErrorDocumenNumberSpouse = !this.conyugueDocumentNumber;
      this.isErrorSpouseNames = !this.conyugueNames;
      this.isErrorSpouseLastName = !this.conyugueLastName;
      this.isErrorSpouseSecondLastName = !this.conyugueSecondLastName;
    } 
    else {
      this.isErrorDocumenTypeSpouse = false;
      this.isErrorDocumenNumberSpouse = false;
      this.isErrorSpouseNames = false;
      this.isErrorSpouseLastName = false;
      this.isErrorSpouseSecondLastName = false;
    }    
  
    // Comprobamos si algún campo tiene error
    const requiredElements = [
      this.isErrorTipoDocumento,
      this.isErrorNroDocInput,
      this.isErrorPrimerNombre,
      this.isErrorSegundoNombre,
      this.isErrorApellidoPaterno,
      this.isErrorApellidoMaterno,
      this.isErrorFechaNacimiento,
      this.isErrorNationality,
      this.isErrorGenero,
      this.isErrorEstadoCivil,
      this.isErrorCountryDomicile,
      this.isErrorHousingType,
      this.isErrorEmploymentRelationship,
      this.isErrorCellphone,
      this.isErrorEmail
    ];

    const requiredElementsConditional = [
      this.isErrorDocumenTypeSpouse,
      this.isErrorDocumenNumberSpouse,
      this.isErrorSpouseNames,
      this.isErrorSpouseLastName,
      this.isErrorSpouseSecondLastName
    ];

    if (requiredElements.every(element => !element) 
      && (this.showDatosConyugue ? requiredElementsConditional.every(element => !element) : true)) {
    
      let model = {
        documentType: this.tipoDocumento,
        documentNumber: this.nroDocInput,
        name: this.primerNombre,
        middlename: this.segundoNombre,
        lastname: this.apellidoPaterno,
        secondLastname: this.apellidoMaterno,
        birthDate: this.fechaNacimiento,
        citizenship: this.nacionalidad,
        gender: this.genero,
        countryDomicile: this.countryDomicile,
        housingType: this.housingType,
        employmentRelationShip: this.employmentRelationship,
        maritalStatus: this.estadoCivil,
        email: this.email,
        cellphone: this.cellphone,
        documentTypeSpouse : this.showDatosConyugue === true ? this.conyugueDocumentType : null,
        documentNumberSpouse : this.showDatosConyugue === true ? this.conyugueDocumentNumber : null,
        spouseNames : this.showDatosConyugue === true ? this.conyugueNames : null,
        spouseLastName : this.showDatosConyugue === true ? this.conyugueLastName : null,
        spouseSecondLastName : this.showDatosConyugue === true ? this.conyugueSecondLastName : null,
        Pep: this.clientPep
      };

      // console.log(JSON.stringify(model));
      this.updateApplication({ 
        applicationId: this.applicationId,
        workflowInstanceId : this.workflowId,
        taskId: AppConstants.WorkflowTasks.PRODUCT_EVALUATION,
        updatedBy: this.usuario,
        updatedByFullName: this.userName,
        personalInformation: model 
      });

      
    } else {
      this.alertService.showMessage("", AppConstants.MessageAlert.ERROR_FORM_REQUIRED, MessageSeverity.error);
    }

  }

  resetErrorState(inputType: string) {
    if (inputType === 'nroDocInput') {
      this.isErrorNroDocInput = this.nroDocInput.length === 0;  
    }else   if (inputType === 'primerNombre') {
      this.isErrorPrimerNombre = this.primerNombre === undefined || this.primerNombre === null  ? true : (this.primerNombre.length >= this.primerNombreValMin ? false : true); 
    }else   if (inputType === 'segundoNombre') {
      if(this.segundoNombre != undefined || this.segundoNombre != null) {
        this.segundoNombre.length > 0 ? this.segundoNombreValMin = 3 : this.segundoNombreValMin = 0;        
      }
      this.isErrorSegundoNombre = this.segundoNombreValMin === 0 ? false : (this.segundoNombre.length >= this.segundoNombreValMin ? false : true);
    }else   if (inputType === 'apellidoPaterno') {
      this.isErrorApellidoPaterno = this.apellidoPaterno === undefined || this.apellidoPaterno === null  ? true : (this.apellidoPaterno.length >= this.apellidoPaternoValMin ? false : true);
    }else   if (inputType === 'apellidoMaterno') {
      this.isErrorApellidoMaterno = this.apellidoMaterno === undefined || this.apellidoMaterno === null  ? true : (this.apellidoMaterno.length >= this.apellidoMaternoValMin ? false : true);
    }else   if (inputType === 'nacionalidad') {
      this.isErrorNationality = this.nacionalidad.length === 0;  
    }else if (inputType === 'fechaNacimiento') {
      this.isErrorFechaNacimiento = !this.validateYearAndAge(this.fechaNacimiento);
    }
  }

  errorStates: { [key: string]: boolean } = {
    isErrorEstadoCivil: false
  };

  handleSelectionChange(value: string, errorField: string) {
    if (value && this.errorStates.hasOwnProperty(errorField)) {
      this.errorStates[errorField] = false; // Actualiza el estado de error específico a false si hay un valor seleccionado.
    }

    this.showDatosConyugue = value === AppConstants.MaritalStatus.MARRIED;
    this.estadoCivil = value;
  }

  async getDocuments() {
    this.loading = true;
    let model = [AppConstants.ParameterCode.DOCUMENT_TYPE];
    this.parameterService.getParametersList(model).subscribe({
      next: async (response: any) => {
        let parameters = (response.filter((f: any) => f.state == AppConstants.StateFlag.Active)).sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1));
        parameters.forEach((element: any) => {
          this.documents.push({ label: element.parameterDetCode002, value: element.parameterDetCode001, length: element.parameterDetCode003 });
        });
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        console.log(error);
      }
    });
  }

  async getParameters(array: Array<number>) {
    this.loading = true;
    await lastValueFrom(this.parameterService.getParametersList(array)).then((response: any) => {
      this.setVariablesParameters(response);
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  setVariablesParameters(listParameters: any[]) {

    this.documents = listParameters
        .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.DOCUMENT_TYPE && f.state === AppConstants.StateFlag.Active)
        .sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1))
        .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001, length: element.parameterDetCode003 }));
  

    let nationality = listParameters
        .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.NACIONALITY && f.state === AppConstants.StateFlag.Active)
        .sort((a: any, b: any) => {
          if (a.parameterDetCode004 !== null && b.parameterDetCode004 !== null) {
            if (a.parameterDetCode004 < b.parameterDetCode004) return -1;
            if (a.parameterDetCode004 > b.parameterDetCode004) return 1;
          } else if (a.parameterDetCode004 === null && b.parameterDetCode004 !== null) {
            return 1;
          } else if (a.parameterDetCode004 !== null && b.parameterDetCode004 === null) {
            return -1;
          }
          // Si ambos son nulos o iguales en parameterDetCode004, ordenar por parameterDetCode002
          if (a.parameterDetCode002 < b.parameterDetCode002) return -1;
          if (a.parameterDetCode002 > b.parameterDetCode002) return 1;
          return 0;
        });

    nationality.forEach((element: any) => {
      this.opcionesPaisDomicilio.push({ label: element.parameterDetCode002, value: element.parameterDetCode001 });
    });

    this.opcionesEstadoCivil = listParameters
      .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.MARITAL_STATUS && f.state === AppConstants.StateFlag.Active)
      .sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1))
      .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 }));

    this.opcionesRelacionLaboral = listParameters
      .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.EMPLOYMENT_RELATIONSHIP && f.state === AppConstants.StateFlag.Active)
      .sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1))
      .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 }));

    this.elementsReadOnly = listParameters
    .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.ELEMENTS_BLOCKED && f.state === AppConstants.StateFlag.Active && f.parameterDetCode003 === AppConstants.StateFlag.Active)
    .map((element: any) => element.parameterDetCode002);
    
    this.opcionesTipoVienda = listParameters
      .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.HOUSING_SITUATION && f.state === AppConstants.StateFlag.Active)
      .sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1))
      .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 }));

    this.sizeFile = listParameters
      .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.SIZE_FILES && f.state === AppConstants.StateFlag.Active)
      .sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1));
  }

  FirstName(input: string) {
    var output = '';
    let arrayCompuesto = ['DA', 'DE', 'DEL', 'LA', 'LAS', 'LOS', 'MAC', 'MC', 'VAN', 'VON', 'Y', 'I', 'SAN', 'SANTA'];
    var array = input.trim().split(' ');
    if (array.length == 2) {
      output = array[0];
    } else if (array.length > 2 && arrayCompuesto.filter(f => f == array[0]).length > 0 && arrayCompuesto.filter(f => f == array[1]).length == 0) {
      output = array[0] + ' ' + array[1];
    } else if (array.length > 2 && arrayCompuesto.filter(f => f == array[1]).length == 0) {
      output = array[0];
    } else {
      output = array[0];
  
      if (arrayCompuesto.filter(f => f == array[0]).length > 0) {
        if (array.length >= 2 && arrayCompuesto.filter(f => f == array[1]).length > 0 && arrayCompuesto.filter(f => f == array[2]).length == 0) {
          output = output + ' ' + array[1] + ' ' + array[2];
        }
        if (array.length >= 3 && arrayCompuesto.filter(f => f == array[0]).length > 0 && arrayCompuesto.filter(f => f == array[1]).length > 0 && arrayCompuesto.filter(f => array[2]).length == 0) {
          output = output + array[1] + ' ' + array[2];
        }
      }
    }
    return output.trim();
  }

  updateApplication(model: any) {
    this.loading = true;
    this.applicationService.postUpdationApplication(model).subscribe({
      next: async (response: any) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.APPLICATION_UPDATE_TITLE, AppConstants.MessageAlert.UPDATE_APPLICATION_SUCCESS, MessageSeverity.success);
        this.cambiarComponente.emit();
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.APPLICATION_UPDATE_TITLE, AppConstants.MessageAlert.UPDATE_APPLICATION_ERROR, MessageSeverity.error);
        console.error(error);
      }
    });
  }

  async getApplication(model: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getApplication(model)).then((response: any) => {
      this.application = response;
      this.setApplicationForm(response);
      this.loading = false;
      this.callAutoCompleteDataInformation(); 
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  setApplicationForm(model: any) {
    this.tipoDocumento = model.documentType == null ? '' : model.documentType;
    this.nroDocInput = model.documentNumber;
    this.primerNombre = model.name;
    this.segundoNombre = model.middleName;
    this.apellidoPaterno = model.lastname;
    this.apellidoMaterno = model.secondLastname;
    this.fechaNacimiento = model.birthDate == null || model.birthDate == '' ? '' : model.birthDate.slice(0, 10);
    this.nacionalidad = model.citizenship == null ? '' : model.citizenship;
    this.genero = model.sex == null ? '' : model.sex;
    this.countryDomicile = model.countryCode == null ? '' : model.countryCode;
    this.housingType = model.typehousingCode == null ? '' : model.typehousingCode;
    this.employmentRelationship = model.jobTypeCode == null ? '' : model.jobTypeCode;
    this.estadoCivil = model.maritalStatus == null ? '' : model.maritalStatus;
    this.cellphone = model.personalCellPhoneNumber;
    this.conyugueDocumentType = model.documentTypeSpouse == null ? '' : model.documentTypeSpouse;
    this.conyugueDocumentNumber = model.documentNumberSpouse;
    this.conyugueNames = model.spouseNames;
    this.conyugueLastName = model.spouseLastname;
    this.conyugueSecondLastName = model.spouseSecondLastname;
    this.email = model.emailAddress;

    if(this.estadoCivil != null && this.estadoCivil != ''){
      this.handleSelectionChange(this.estadoCivil, 'isErrorEstadoCivil');
    }
  }

  dataMockup () {
    this.tipoDocumento = this.client.documentType;
    this.nroDocInput = this.client.documentNumber;
    this.primerNombre = this.FirstName(this.client.fullName);
    this.segundoNombre = 'Ursula';
    this.apellidoPaterno = 'Gamero';
    this.apellidoMaterno = 'Rodriguez';
    this.fechaNacimiento = '1993-02-09';
    this.nacionalidad = 'PE';
    this.genero ='F';
    this.countryDomicile = 'PE';
    this.housingType = 'FAMILIAR';
    this.employmentRelationship = '0010';
    this.estadoCivil = '1';
    this.cellphone = '999888777';
    this.conyugueDocumentType = '1';
    this.conyugueDocumentNumber = '40506070';
    this.conyugueNames = 'Jose Manuel';
    this.conyugueLastName = 'Ramirez';
    this.conyugueSecondLastName = 'Hinostroza';
    this.email = 'jgamreodriguez@gmail.com';
  }

  handleClose() {
    this.showModal = false;
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
  }

  async readFile(event: any) {
    const file = event.file.target.files[0];
    if (!file) {
        return;
    }
    return await new Response(file).arrayBuffer();
  }

  base64ArrayBuffer(arrayBuffer: any) {
    var base64 = '';
    var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

    var bytes = new Uint8Array(arrayBuffer);
    var byteLength = bytes.byteLength;
    var byteRemainder = byteLength % 3;
    var mainLength = byteLength - byteRemainder;

    var a, b, c, d;
    var chunk;

    // Main loop deals with bytes in chunks of 3
    for (var i = 0; i < mainLength; i = i + 3) {
        // Combine the three bytes into a single integer
        chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

        // Use bitmasks to extract 6-bit segments from the triplet
        a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
        b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
        c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
        d = chunk & 63;               // 63       = 2^6 - 1

        // Convert the raw binary segments to the appropriate ASCII encoding
        base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
    }

    // Deal with the remaining bytes and padding
    if (byteRemainder == 1) {
        chunk = bytes[mainLength];

        a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

        // Set the 4 least significant bits to zero
        b = (chunk & 3) << 4; // 3   = 2^2 - 1

        base64 += encodings[a] + encodings[b] + '==';
    } else if (byteRemainder == 2) {
        chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

        a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
        b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

        // Set the 2 least significant bits to zero
        c = (chunk & 15) << 2; // 15    = 2^4 - 1

        base64 += encodings[a] + encodings[b] + encodings[c] + '=';
    }

    return base64;
}

  async handleSelectFileAction(event: any) {
    try {
      const files = event.file.target.files;
      const file = files[0];

      if (this.helperService.isValidFileType(file.type)) {
        if (files && file) {
          const exist = this.filesList.filter((f: any) => f.name === file.name);
          if (exist.length > 0) {
            this.alertService.showMessage(AppConstants.TitleAlert.UPLOAD_FILES, AppConstants.MessageAlert.UPLOAD_FILE_EXIST, MessageSeverity.error);
            return;
          }

          const sizeParameterBytes = (this.sizeFile == null || this.sizeFile === undefined ||
          this.sizeFile[0] == null || this.sizeFile[0] === undefined) ? null : this.sizeFile[0];

          if (sizeParameterBytes != null) {
            let pesoMaximo = sizeParameterBytes.parameterDetCode001;
            if (file.size > sizeParameterBytes.parameterDetCode001 * 1000000) {
                this.alertService.showMessage('Validar Archivo', `Solo puede adjuntar archivos de tamaño menor o igual a ${pesoMaximo} MB.`, MessageSeverity.warn);
                return;
            }
          }

          this.fileTemp = <File>event.file.target.files[0];
          var extension = this.fileTemp.name.split('.');
          var keyValue = this.uuidv4() + '.' + extension[extension.length - 1];

          let model = {
            applicationId: this.applicationId,
            file: {
              name: this.fileTemp.name,
              type: AppConstants.AttachmentMode.Customer,
              fileId: keyValue,
              fileBase64: this.base64ArrayBuffer(await this.readFile(event)),
              contenType: this.fileTemp.type
            }
          };
          this.uploadFile(model);
        }

      } else {
        this.alertService.showMessage(AppConstants.TitleAlert.UPLOAD_FILES, AppConstants.MessageAlert.UPLOAD_FILE_INCORRECT, MessageSeverity.error);
      }

    } catch (error) {
      console.error(error);
    }    
  }

  uploadFile(model: any) {
    this.loading = true;
    this.applicationService.postUploadFile(model).subscribe({
      next: async (response: any) => {
        this.loading = false;
        this.filesList.push({name: this.fileTemp.name, key: model.file.fileId});
        this.alertService.showMessage(AppConstants.TitleAlert.UPLOAD_FILES, AppConstants.MessageAlert.UPLOAD_FILES_MESSAGE_OK, MessageSeverity.success);
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.UPLOAD_FILES, AppConstants.MessageAlert.UPLOAD_FILES_MESSAGE_ERROR, MessageSeverity.error);
        console.error(error);
      }
    });
  }

  async getAttachmentFiles(model: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getFilesApplication(model)).then((response: any) => {
      this.filesList = response;
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  handleFileDownload(event: any) {
    this.downloadFile(event.file.key, event.file.name);
  }

  handleFileDelete(event: any) {
    const keyIndex = this.filesList.findIndex((item: any) => item.key === event.file.key);
    let model = {
      applicationId: this.applicationId,
      fileId: event.file.key,
      index: keyIndex
    };
    this.deleteFile(model);
  }

  downloadFile(fileId: string, nameFile: string): void {
    this.loading = true;
    this.applicationService.downloadFile(fileId).subscribe({
      next: (response: any) => {
        const data: Blob = new Blob([response], {
            type: response.type
        });

        let extension = fileId.split('.');
        FileSaver.saveAs(data, nameFile);
      },
      error: (error: any) => {
        this.alertService.showMessage(AppConstants.TitleAlert.DOWNLOAD_FILE, AppConstants.MessageAlert.DOWNLOAD_FILES_MESSAGE_ERROR, MessageSeverity.error);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  deleteFile(model : any): void {
    this.loading = true;
    this.applicationService.postDeleteFile(model).subscribe({
      next: async (response: any) => {
        this.filesList.splice(model.index, 1);
      },
      error: (error) => {
        this.alertService.showMessage(AppConstants.TitleAlert.DELETE_FILE, AppConstants.MessageAlert.DELETE_FILES_MESSAGE_ERROR, MessageSeverity.error);
        console.error(error);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  sendCommentHandler() {
    if(this.comment == '') {
      this.alertService.showMessage("", AppConstants.MessageAlert.ADD_COMMENT_ERROR_EMPY_CREDENTIALS, MessageSeverity.warn);
    }
    else {
      let model = {
        applicationId: this.applicationId,
        commentKey: AppConstants.ApplicationComment.COMMENT_ATTACHMENT_CODE,
        comment: this.comment,
        reason: '',
        updatedBy: this.usuario,
        updatedByFullName: this.userName
      };
  
      this.sendAddComentApplication(model);
    }
  }

  sendAddComentApplication(model : any): void {
    this.loading = true;
    this.applicationService.postSendAddComentApplication(model).subscribe({
      next: async (response: any) => {
        this.showModal = false;
        this.comment = '';
        this.alertService.showMessage("", AppConstants.MessageAlert.ADD_COMMENT_SUCCES_APPLICATION, MessageSeverity.success);
      },
      error: (error) => {
        this.alertService.showMessage("", AppConstants.MessageAlert.ADD_COMMENT_ERROR_APPLICATION, MessageSeverity.error);
        console.error(error);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  async retrieveApplicationComments(model: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getApplicationComments(model)).then((response: any) => {
      const comments = response.filter((f: any) => f.id === AppConstants.ApplicationComment.COMMENT_ATTACHMENT_CODE);
      if(comments.length > 0){
        this.comment = comments[0].message;
      }
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  validateYearAndAge(date: string): boolean {
    try {
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      if (!regex.test(date)) {
        return false;
      }

      const [year, month, day] = date.split('-').map(num => parseInt(num, 10));

      if (year <= 1900) {
        return false;
      }

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      const currentDay = currentDate.getDate();

      let age = currentYear - year;

      if (currentMonth < month || (currentMonth === month && currentDay < day)) {
        age--;
      }

      if (age < 18) {
        return false;
      }

      return true;
  
    } catch (error) {
      console.error("Error al validar la fecha:", error);
      return false;
    }
  }

  retrieveCustomerPersonalInformation(documentType: string, documentNumber: string) {
    this.loading = true;

    let model = {
      documentType: documentType,
      documentNumber: documentNumber
    };
    
    this.applicationService.retrieveCustomerInformation(model).subscribe({
      next: (response: HttpResponse<any>) => {
        if (response.status === 200) {
          sessionStorage.setItem(AppConstants.Session.CUSTOMER_INFORMATION, JSON.stringify(response.body));
          this.setCustomerPersonalInformation();
        }
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.loading = false;
      },
      complete: () => {
      }
    });
  }

  validateCallCustomerInformation() {
    const validation = 
    this.application && (
        this.application.name == null || 
        this.application.lastname == null || 
        this.application.secondLastname == null || 
        this.application.birthDate == null || 
        this.application.citizenship == null || 
        this.application.sex == null || 
        this.application.countryCode == null || 
        this.application.educationLevel == null || 
        this.application.profession == null || 
        this.application.occupation == null ||
        this.application.email == null || 
        this.application.cellphone == null
    );

    return validation;
  }
  
  setCustomerPersonalInformation() {
    try {
      const customer = JSON.parse(sessionStorage.getItem(AppConstants.Session.CUSTOMER_INFORMATION) ?? '{}');

      this.primerNombre = customer?.name;
      this.segundoNombre = customer?.middleName;
      this.apellidoPaterno = customer?.lastname;
      this.apellidoMaterno = customer?.secondLastname;
      this.fechaNacimiento = customer?.birthDate.slice(0, 10);
      this.nacionalidad = customer?.citizenship;
      this.genero = customer?.sex;
      this.countryDomicile = customer?.countryCode;
      this.employmentRelationship = customer?.occupation;
      this.estadoCivil = customer?.maritalStatus;
      this.cellphone = customer?.personalCellPhoneNumber;
      this.email = customer?.emailAddress;    
      
      this.handleSelectionChange(customer?.maritalStatus, 'isErrorEstadoCivil');

      if(customer?.maritalStatus === AppConstants.MaritalStatus.MARRIED) {
        this.conyugueDocumentType = this.client.documentTypeSpouse != undefined || this.client.documentTypeSpouse != null ? this.client.documentTypeSpouse : this.application.documentTypeSpouse;
        this.conyugueDocumentNumber = this.client.documentNumberSpouse != undefined || this.client.documentNumberSpouse != null ? this.client.documentNumberSpouse : this.application.documentNumberSpouse;
        const blockedElements = ['conyugueDocumentType','conyugueDocumentNumber', 'estadoCivil'];
        this.elementsReadOnly.push(...blockedElements);
      }
      
    } catch (error) {
      console.error(error)
    }
  }

  callAutoCompleteDataInformation() {
    try {
      if(this.validateCallCustomerInformation()) {
        let session = sessionStorage.getItem(AppConstants.Session.CUSTOMER_INFORMATION);
        if(session != null) {
          const customer = JSON.parse(sessionStorage.getItem(AppConstants.Session.CUSTOMER_INFORMATION) ?? '{}');
          if(customer?.documentType === this.application.documentType && customer?.documentNumber === this.application.documentNumber)
          {
            this.setCustomerPersonalInformation();
          } else {
            this.retrieveCustomerPersonalInformation(this.application.documentType, this.application.documentNumber);  
          }          
        } else {
          this.retrieveCustomerPersonalInformation(this.application.documentType, this.application.documentNumber);
        }
      }      
    } catch (error) {
      console.error(error);
    }
  }  

}
