<!-- Componente de Cabecera -->
<app-header [headerText]="'2. Simulando Oferta'" headerImageSrc="assets/simular.svg"></app-header>

<div class="contenedor-informacion">
    <div class="informacion-producto">
        <div class="caja-con-borde">
            <div class="caja-titulo-producto">
                <img [src]="product.image" alt="Producto">
                <div class="nombre-producto">{{ product.name }}</div>
            </div>
           
            <div class="monto">{{product.currencySymbol}} {{ product.amount | number: '1.2-2' }}</div>
            <div class="caja-titulo-vigencia">
                <strong class="texto-subrayado">Vigencia Oferta:</strong>
                <div class="fechas-container">
                    {{ product.validityStart }} al {{ product.validityEnd }}
                </div> 
            </div>
        </div>
        <div class="caja-sin-borde">
            <div class="fila datos-dni">
                <img src="assets/usuario.svg" alt="Icono">
                <strong class="texto-subrayado">{{ client.documentTypeDesciption }}:</strong>{{ client.documentNumber }}
            </div>
        
            <div class="fila datos-cliente">
                <img src="assets/tarjetaid.svg" alt="Icono">
                <div class="nombre-cliente"><strong class="texto-subrayado">CLIENTE:</strong>{{ client.fullName }}</div>
            </div>
        </div>
       
    </div>

</div>
<!--<app-line-divider></app-line-divider>

<div class="contenedor-informacion">
    <div class="informacion-seguros">
        <div class="linea-con-punto">
            <span class="punto"></span><strong>Seguros Optativos:</strong> 
        </div>
        <div class="producto-seguros">
            <div *ngFor="let elemento of arraySegurosOptativos" class="form-group productos-seguros-informacion">
                <label>{{ elemento.label }}</label>
                <app-custom-inpunt-select [options]="elemento.options" [(selectedValue)]="elemento.selectedValue" (change)="onElementoChange(elemento)"  placeholder="Seleccionar"></app-custom-inpunt-select>
            </div>
        </div>
        <div *ngIf="arraySegurosOptativos.length > 0" class="producto-seguros-filters">
            <div class="form-group productos-seguros-informacion">
                <label>Edad Cliente</label>
                <app-custom-input 
                    [(value)]="clientAge"
                    [validateNumber]="true"
                    [type]="typeInputNumber"
                    [maxlength]="2"
                    (input)="clearSimulation()">
                </app-custom-input>
            </div>
        </div>
    </div>
</div>

<app-line-divider></app-line-divider>-->

<div class="contenedor-informacion">
    <div class="informacion-producto-pago">
        <div class="linea-con-punto-pago">
            <span class="punto"></span><strong class="titulo-indicacion">Llena los campos y presiona
                <img src="assets/simulador.svg"/>
                para empezar a simular:</strong> 
        </div>
        <div class="fecha-pago">
            <img src="assets/calendario.svg" alt="Imagen"> 
            <strong class="texto-subrayado">PRIMER PAGO:</strong> <span>{{ firstExpiration }}</span> 
        </div>
    </div>
</div>

<div class="contenedor-informacion">
    <div class="formulario-producto">
        <div class="group-input-monto">
            <div class="form-group monto-input">
                <label>Monto</label>
                <app-custom-input 
                    [(value)]="montoValue"
                    [validateNumber]="true"
                    [type]="typeInputNumber"
                    (input)="clearSimulation()">
                </app-custom-input>
            </div>
            <div class="form-group dias-pago">
                <label>Días de pago</label>
                <app-custom-inpunt-select [options]="diaPagoOptions"
                    [(selectedValue)]="paymentDay" 
                    placeholder="Seleccionar"
                    (change)="clearSimulation()">
                </app-custom-inpunt-select>
            </div>
        </div>
        <div class="group-input-pago">

            <div class="form-group numero-cuotas">
                <label>Número de Cuotas</label>                
                <!-- <app-custom-inpunt-select [options]="numeroCuotasOptions"
                    [(selectedValue)]="quotas" 
                    placeholder="Seleccionar">
                </app-custom-inpunt-select> -->

                <app-custom-input-autocomplete
                    [placeholderText]="'Buscar'"
                    [type]="'tel'"
                    [maxlength]="100"
                    [label]="'Buscar'"
                    [width]="'100%'"
                    [(value)]="quotas"
                    [isRequired]="false"
                    [isDisabled]="false"
                    [isError]="false"
                    [borderColor]="''"
                    [validateNumber]="false"
                    [validateEmail]="false"
                    [maxLength]="null"
                    [options]="options"
                    (valueChange)="onValueChange($event)">
                </app-custom-input-autocomplete>
            </div>
            <div class="form-group tipo-desgravamen">
                <label>Tipo de Desgravamen</label>
                <app-custom-inpunt-select [options]="tipoDesgravameOptions"
                    [(selectedValue)]="desgravamen" 
                    placeholder="Seleccionar"
                    (change)="clearSimulation()">
                </app-custom-inpunt-select>
            </div>
        </div>
        <div class="group-input-desgravamen">
            <div class="form-group fecha-nacimiento">
                <label>Fecha de Desembolso</label>
                <app-custom-datepicker [(value)]="disburmentDate" (change)="clearSimulation()"></app-custom-datepicker>
            </div>
        </div>
        <div class="group-input-desgravamen">
            <div class="boton-generar">
                <app-custom-button iconUrl="assets/img/simuladorBlanco.svg" text="Simular" (action)="fillNextColumn()"></app-custom-button>
            </div>
        </div>
    </div>
</div>

<div class="lista-desplegable">
    <div class="encabezado-lista" (click)="toggleLista()">
        <span>Seguros aplicados a oferta</span>
        <span class="icono-desplegable" [ngClass]="{'abierto': listaAbierta}">
          <i class="fa-solid fa-chevron-down"></i>
        </span>
      </div>
  
    <div class="contenido-lista" *ngIf="listaAbierta">
        <div class="contenido-lista" @fade>
            <div class="seguro-item" *ngFor="let elemento of arraySegurosOptativos">
                <span class="seguro-titulo">{{ elemento.label }}</span>
                <div class="seguro-detalle">    
                    <div class="form-group tipo-seguros">
                        <label>Plan</label>
                        <app-custom-inpunt-select [options]="elemento.options"
                            [(selectedValue)]="elemento.selectedValue" 
                            placeholder="Seleccionar"
                            (change)="onPlanChangeOptionalInsurance(elemento);hiddenSimulationTable();">
                        </app-custom-inpunt-select>
                    </div>
    
                    <div class="form-group tipo-seguros">
                        <label>Plazo</label>
                        <app-custom-inpunt-select [options]="elemento.subOptions"
                            [(selectedValue)]="elemento.selectecSubOption" 
                            placeholder="Seleccionar"
                            (change)="onPlazoChangeOptionalInsurance(elemento);hiddenSimulationTable();"
                            [isReadonly]="elemento.disabled">
                        </app-custom-inpunt-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="Simulado" class="tabla-responsive" @fade>
    <app-custom-table-simulator #myTable [simulationCount]="simulationCount" (componentReady)="onComponentReady()"></app-custom-table-simulator>
</div>



<div class="contenedor-link-back">
    <app-custom-button-color text="Atrás" (action)="returnSearchOffers()"></app-custom-button-color>
</div>
<app-footer></app-footer>
<loading *ngIf="loading"></loading>
<!-- <app-custom-datepicker placeholderText="Seleccione la fecha" width="250px"></app-custom-datepicker> -->