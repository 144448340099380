import { Component, Input,EventEmitter,Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  standalone: true,
  imports: [CommonModule,FormsModule],
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() headerText: string = '';
  @Input() headerImageSrc: string = 'assets/registrate.png'; // La ruta de imagen por defecto
  @Input() showImage: boolean = true; 
}
