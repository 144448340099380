import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ClienteSession, ProductoSession } from 'app/models/sessionModel';
import { ApplicationService } from 'app/services/application.service';
import { AppConstants } from 'app/shared/constants/app-constants';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-autenticacion',
  templateUrl: './autenticacion.component.html',
  styleUrls: ['./autenticacion.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class AutenticacionComponent implements OnInit{

  client: ClienteSession = JSON.parse(sessionStorage.getItem(AppConstants.Session.CLIENT) ?? '{}');
  product: ProductoSession = JSON.parse(sessionStorage.getItem(AppConstants.Session.PRODUCT) ?? '{}');
  applicationId: string = sessionStorage.getItem(AppConstants.Session.APPLICATION_ID) ?? '';
  workflowId: string = sessionStorage.getItem(AppConstants.Session.WORKFLOW_ID) ?? '';
  rolName: string = sessionStorage.getItem(AppConstants.Session.USERROLNAME) ?? '';
  usuario: string = sessionStorage.getItem(AppConstants.Session.USERID) ?? '';
  userName: string = sessionStorage.getItem(AppConstants.Session.USERNAME) ?? '';
  agenciaName: string = sessionStorage.getItem(AppConstants.Session.AGENCYNAME) ?? '';
  agenciaCode: string = sessionStorage.getItem(AppConstants.Session.AGENCYCODE) ?? '';

  botonDeshabilitado: boolean = true;
  columnData: any;
  currentTab: string = 'facial';
  showModalResumen = false;
  tituloModal='PROCESO DE ALTA';

  myTabs = [
    { title: 'Autenticación Facial', active: true, completed: false },
    { title: 'Validación de Correo', active: false, completed: false }
  ];

  selectTab(tab: string) {
    this.currentTab = tab;
  }

  showComponente = true;
  loading: boolean = false;
  customer: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private applicationService: ApplicationService,
    private alertService: AlertService) 
  {
    
  }

  async ngOnInit () {
    this.route.queryParams.subscribe(params => {
      if (params['data']) {
        this.columnData = JSON.parse(params['data']);
      }
    });
    await this.getApplication(this.applicationId);
  }

  datosPersonales(){
    this.router.navigate(['datos-personales']);
  }

  
  markTabAsCompleted(index: number) {
    if (index >= 0 && index < this.myTabs.length) {
      this.myTabs[index].completed = true;
    }


    if (this.myTabs.every(tab => tab.completed)) {
      this.botonDeshabilitado = false;  
    }
  }

  handleCloseResumen() {
    this.showModalResumen = false;
  }

  redireccionarFinalizacion(){
    this.showModalResumen = true;
  }

  async getApplication(model: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getApplication(model)).then((response: any) => {
      this.customer = response;
      sessionStorage.setItem(AppConstants.Session.CUSTOMER, JSON.stringify(this.customer));     
      this.loading = false;      
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  nextStepApplication() {

    let model = {
      applicationId: this.applicationId,
      workflowInstanceId: this.workflowId,
      status: AppConstants.ApplicationStates.PendienteAutorizacion,
      processId: AppConstants.Process.PLD_DRACMA_APROBACION,
      taskId: AppConstants.WorkflowTasks.CUSTOMER_VERIFICATION,
      channelId: AppConstants.Channels.DesktopPVA,
      taskInstanceRole: this.rolName,
      updatedBy: this.usuario,
      updatedByFullName: this.userName,
      agencyCode: this.agenciaCode,
      agencyName: this.agenciaName
    };

    this.updateStautsApplication(model);

  }

  updateStautsApplication(model: any) {
    this.loading = true;
    this.applicationService.postUpdationStatusApplication(model).subscribe({
      next: async (response: any) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.APPLICATION_UPDATE_TITLE, AppConstants.MessageAlert.UPDATE_APPLICATION_SUCCESS, MessageSeverity.success);
        this.router.navigate(['finalizacion']);
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.APPLICATION_UPDATE_TITLE, AppConstants.MessageAlert.UPDATE_APPLICATION_ERROR, MessageSeverity.error);
        console.error(error);
      }
    });
  }

}
