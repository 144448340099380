import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnterpriseService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = "application/companies";
  private BASE_URL: string = environment.applicationApiEndpoint;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  retrieveEnterprises(model : any) {
    return this.httpClient.post<any[]>(`${this.REQUEST_URL}/retrieve`, model);
  }

  postCreateEnterprise(model : any) {
    return this.httpClient.post<any[]>(`${this.REQUEST_URL}/creation`, model);
  }

  postUpdateEnterprise(model : any) {
    return this.httpClient.post<any[]>(`${this.REQUEST_URL}/updation`, model);
  }
}
