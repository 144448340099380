export const environment = {
    production: true,
    identityApiEndpoint: 'https://digitalchannels-apig.qa.banbif.com.pe/pva/api/identity/connect/token',
    loanSimulationApiEndpoint: 'https://digitalchannels-apig.qa.banbif.com.pe/pva/api',
    customerApiEndpoint: 'https://digitalchannels-apig.qa.banbif.com.pe/pva/api',
    optionalInsurancesApiEndpoint: 'https://digitalchannels-apig.qa.banbif.com.pe/pva/api',
    parametersApiEndpoint: 'https://digitalchannels-apig.qa.banbif.com.pe/pva/api',
    applicationApiEndpoint: 'https://digitalchannels-apig.qa.banbif.com.pe/pva/api',
    offersApiEndpoint: 'https://digitalchannels-apig.qa.banbif.com.pe/pva/api',
    customerAccountApiEndpoint: 'https://digitalchannels-apig.qa.banbif.com.pe/pva/api',
    reCaptchaKey: "6LcKZIoqAAAAAF3WiJq9ysR1B8v-_xVmYNdLdd5h",
    acrEndpoint:  'https://bif3acr11.dombif.peru/ACR'
};