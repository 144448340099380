<app-header [headerText]="'Inicio de autenticación'"></app-header>
<div class="contenedor-padre">
  <div class="contenedor-solicitud">
    <span class="solicitud-texto">N° SOLICITUD: </span>
    <span class="solicitud-numero"> {{applicationId}} </span>
  </div>
</div>
<div class="contenedor-informacion">
  <div class="informacion-producto">
    <img src="assets/img/validacion_facial.svg" alt="Descripción de la imagen" class="container-imagen">
    <app-custom-button iconUrl="assets/img/face-id.svg" text="BIOMETRÍA FACIAL&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"  (action)="faceIdAuthentication()"></app-custom-button>
    <!-- <app-custom-button iconUrl="assets/img/finger-print.svg" text="BIOMETRÍA DACTILAR" (action)="fingerprintAuthentication()"></app-custom-button> -->
    <app-custom-button-color text="NOTIFICAR A SUPERVISOR" (action)="sendApprovalHandler()"></app-custom-button-color>
  </div>
</div>
<app-footer></app-footer>

<loading *ngIf="loading"></loading>