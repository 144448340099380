import { Component, OnInit, HostListener, ChangeDetectorRef, inject, DestroyRef } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule, Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CustomButtonCircleComponent } from '../custom-button-circle/custom-button-circle.component';
import { InfoModalComponent } from '../info-modal';
import { InfoUsuarioModalComponent } from '../info-usuario-modal';
import { HttpErrorResponse } from '@angular/common/http';
import { MenuService } from 'app/services/menu.service';
import { AppConstants } from 'app/shared/constants/app-constants';
import { SharedModule } from 'app/shared/modules/shared/shared.module';
import { AppComponent } from 'app/app.component';
import { AuthService } from 'app/services/auth.service';
import { ModalComponent } from '../modal';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';
import { ApplicationService } from 'app/services/application.service';

@Component({
  standalone: true,
  imports: [MatToolbarModule, MatButtonModule, MatIconModule, RouterModule,FormsModule,CommonModule,CustomButtonCircleComponent,InfoModalComponent,InfoUsuarioModalComponent, SharedModule, ModalComponent],
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  
  userRolId: string = sessionStorage.getItem(AppConstants.Session.USERROLID) ?? '';

  rolName: string = sessionStorage.getItem(AppConstants.Session.USERROLNAME) ?? '';
  rolCode: string = sessionStorage.getItem(AppConstants.Session.USERROLID) ?? '';
  usuario: string = sessionStorage.getItem(AppConstants.Session.USERID) ?? '';
  userName: string = sessionStorage.getItem(AppConstants.Session.USERNAME) ?? '';
  agenciaName: string = sessionStorage.getItem(AppConstants.Session.AGENCYNAME) ?? '';
  agenciaCode: string = sessionStorage.getItem(AppConstants.Session.AGENCYCODE) ?? '';

  esAdmin: boolean = false;
  activeRoute: string = "";
  isMenuOpen = false;
  activeMenuItem: string | null = null;
  showModal = false;
  modalWidth: number = 550; 
  modalHeight: number = 450;  
  esMovil: boolean = false;


  loading: boolean = false;
  arrayMenu: any = [];
  currentRoute: string = '';

  loginPath: string = "login";  

  showModalAnular = false;
  tituloModal='';
  subTituloModal='';
  parrafo='';
  textoEnlaceModal='';

  applicationId: string = '';
  workflowId: string = '';

  onResize() {
    this.esMovil = window.innerWidth <= 1310;
  }


  constructor(
    private router: Router, 
    private cdr: ChangeDetectorRef,
    private menuService: MenuService,
    private authService: AuthService,
    private appComponent: AppComponent,
    private alertService: AlertService,
    private applicationService: ApplicationService
  ) {
    const usuario = localStorage.getItem('usuario');
    this.esAdmin = usuario === 'admin';
    this.esMovil = window.innerWidth <= 1310;
  }
  ngAfterViewInit() {
    this.cdr.detectChanges();
    
  }

  ngOnInit(): void {
    this.createMenu();
  }

  setActiveMenuItem(item: string) {
    this.activeMenuItem = item;
  }

  setActiveRoute(route: string) {
    this.activeRoute = route;
    this.isMenuOpen = false; // Cierra el menú lateral al seleccionar una ruta
  }

  isRouteActive(route: string): boolean {
    return this.activeRoute === route || this.router.url.startsWith(route);
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: MouseEvent) {
    if (!this.isMenuOpen) return;
    const menuElement = document.querySelector('.navigation-menu') as HTMLElement;
    if (menuElement && !menuElement.contains(event.target as Node)) {
      this.isMenuOpen = false;
    }
  }

  logout() {
    this.authService.logout();
    this.appComponent.navigateTo('', this.loginPath);
    this.clearAllCachedRoutes();
  }

  handleClose() {
    this.showModal = false;
    this.showModalAnular = false;
  }

  openModal() {
    this.showModal = true;
    if(this.esMovil) 
    {
      this.modalWidth = 340; 
      this.modalHeight = 360;
    } 
    else  this.modalWidth = 650;
  }

  createMenu() {
    this.loading = true;
    this.menuService.getMenu().subscribe({
      next: async (response: any) => {
        this.arrayMenu = response;
        this.loading = false;    
      },
      error: (error) => {
        this.loading = false;
      }
    });
  }

  goToRoute(route: string) {    
    route == "/salir" ? this.logout() :
    route == "/anulaciones" ? this.invalidate() : this.navigateLogic(route);
  }

  private navigateLogic(route: string){
    this.setActiveRoute(route);
    // this.deleteStoredRoute(route);
    this.currentRoute = route;
    this.router.navigateByUrl(route)
  }

  openModalAnulaciones() {

  }

  invalidate() {
    const applicationId: string = sessionStorage.getItem(AppConstants.Session.APPLICATION_ID) ?? '';
    const workflowId: string = sessionStorage.getItem(AppConstants.Session.WORKFLOW_ID) ?? '';

    if(applicationId === '') {
      this.alertService.showMessage(AppConstants.TitleAlert.CANCELLATION_APPLICATION, AppConstants.MessageAlert.CANCELLATION_APPLICATION_NOT_EXIST, MessageSeverity.error, 6000);
      return;
    }
    else {
      if ((applicationId == '' || applicationId == null) && (workflowId == '' || workflowId == null)) {
        this.alertService.showMessage(AppConstants.TitleAlert.CANCELLATION_APPLICATION, AppConstants.MessageAlert.CANCELLATION_APPLICATION_NOT_EXIST, MessageSeverity.error, 6000);
        return;
      }
    }

    this.applicationId = applicationId;
    this.workflowId = workflowId;

    this.openModalAnular('Anulación de la oferta', `¿Estás seguro de anular la solicitud : Nº: ${applicationId}`, '', '');
  }

  clearAllCachedRoutes(): void {
    //this.router.resetConfig([]);
  }

  openModalAnular(title: string, subtitle: string, description: string, labelButton: string) {
    this.showModalAnular = true;
    this.tituloModal= title;
    this.subTituloModal= subtitle;
    this.parrafo = description;
    this.textoEnlaceModal= labelButton;
  }

  cerrarModalAnular() {
    this.showModalAnular = false;
  }

  handleSaveCancellationApplication() {

    let model = {
      applicationId: this.applicationId,
      workflowInstanceId: this.workflowId,
      processId: AppConstants.Process.PLD_DRACMA_APROBACION,
      taskId: AppConstants.WorkflowTasks.APPROVAL,
      channelId: AppConstants.Channels.DesktopPVA,
      TaskInstanceRole: this.rolName,
      UpdatedBy: this.usuario,
      UpdatedByFullName: this.userName,
      AgencyCode: this.agenciaCode,
      AgencyName: this.agenciaName,
    };

    this.cancellationApplication(model);
  }

  cancellationApplication(model : any): void {
    this.loading = true;
    this.showModalAnular = false;
    this.applicationService.postCancellationApplication(model).subscribe({
      next: async (response: any) => {
        this.alertService.showMessage(AppConstants.TitleAlert.CANCELLATION_APPLICATION, AppConstants.MessageAlert.CANCELLATION_APPLICATION_SUCCES, MessageSeverity.success);
        this.router.navigate(['home']);
      },
      error: (error) => {
        this.alertService.showMessage(AppConstants.TitleAlert.CANCELLATION_APPLICATION, error.error.message, MessageSeverity.error);
        console.error(error);
        this.loading = false;
        this.router.navigate(['home']);
      },
      complete: () => {
        this.loading = false;
      }
    });
  }
  

}
