<div class="custom-input-container">
    <input type="password" class="custom-input" 
    [placeholder]="placeholderText" 
     [(ngModel)]="value"
     [ngClass]="{'error': isErrorCustomInputLogin}"  
     (ngModelChange)="onValueChange($event)"   

     >
    <div class="input-icon-container">
        <!-- <img src="assets/ojo.svg" alt="Icono" class="input-icon"> -->
    </div>
    <!-- <p *ngIf="isErrorCustomInputLogin">{{ errorMessage }}</p> -->
  </div>
  