import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule],
  selector: 'app-custom-inpunt-select',
  templateUrl: './custom-inpunt-select.component.html',
  styleUrls: ['./custom-inpunt-select.component.scss']
})
export class CustomInpuntSelectComponent {
  @Input() options: { label: string, value: string }[] = [];
  @Input() selectedValue: string = '';
  @Input() placeholder: string = 'Seleccione una opción';
  @Input() width?: string; // Puede ser opcional y definido externamente
  @Input() isError: boolean = false;
  @Input() isReadonly: boolean = false;
  @Output() selectedValueChange = new EventEmitter<string>();

  onValueChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const newValue = selectElement.value;
    if (newValue) {
      this.isError = false;
    }
    this.selectedValue = newValue;
    this.selectedValueChange.emit(this.selectedValue);
  }
}
