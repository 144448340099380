export class LoanSimulationRequest {
    applicationDate!: number;
    applicationAmmount!: string;
    applicationQuota!: number;
    disbursementDate!: number;
    paymentDay!: number;
    gracePeriod!: number;
    desgravamenCode!: string;
    currency!: string;
    interes!: string;
    lifeInsurance!: number;
    unemploymentInsurance!: number;
    productCode!: string;
    subProductCode!: string;
    healthInsurance!: number;
}
  