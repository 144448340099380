<div class="modal-overlay" (click)="close()">
  <div class="modal-content" (click)="$event.stopPropagation()">
    <div class="modal-header">
      <h2 class="modal-title">{{ titleText }}</h2>
      <button class="close-button" (click)="close()">×</button>
    </div>
    <div class="modal-body">
      <div class="elemento" *ngFor="let item of arrayMessages">
        <div class="texto-izquierda">{{ item.message }}</div>
        <div class="texto-derecha">{{ item.status }}</div>
      </div>
    </div>
    <div class="footer">
      <div *ngIf="isLoadingProcess">
        <!-- Indicador de carga -->
        <img src="../../../assets/3.gif">
      </div>
      <h3>{{ messagestatusResult }}</h3>
    </div>
    <div class="footer-botones">
      <app-custom-button-color text="Reintentar" (action)="retryFinish()" *ngIf="errorProcess && !maxAttempts"></app-custom-button-color>
      <app-custom-button text="Continuar" (action)="returnTray()" *ngIf="(!isLoadingProcess && !errorProcess) || maxAttempts"></app-custom-button>
    </div>
  </div>
</div>
