import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-custom-table-paginator',
  templateUrl: './custom-table-paginator.component.html',
  styleUrls: ['./custom-table-paginator.component.scss']
})
export class CustomTablePaginatorComponent implements OnChanges {

  constructor(private router: Router) { }

  @Input() columnNames!: string[];
  @Input() data!: any[];
  @Input() actionButtonText: string = 'VER';
  @Input() navigate!: (row: any) => void;
  @Output() onEditRow = new EventEmitter<any>();

  
  currentPageData: any[] = [];
  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;
  totalPages = 0;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      const change = changes['data'];
      if (change.currentValue) {
        this.totalItems = change.currentValue.length;
        this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
        this.paginate(this.currentPage);
      }
    }
  }
  
  paginate(page: number = 1) {
    this.currentPage = page;
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    this.currentPageData = this.data.slice(startIndex, startIndex + this.itemsPerPage);
  }
  
  get paginationNumbers(): (number | string)[] {
    const pages: (number | string)[] = [];
    for (let i = 1; i <= this.totalPages; i++) {
      if (i === 1 || i === this.totalPages || (i >= this.currentPage - 1 && i <= this.currentPage + 1)) {
        pages.push(i);
      } else if ((i === this.currentPage - 2 || i === this.currentPage + 2) && i > 1 && i < this.totalPages) {
        if (!pages.includes('...')) {
          pages.push('...');
        }
      }
    }
    return pages;
  }

  isNumber(value: string | number): value is number {
    return typeof value === 'number';
  }

  onPageClick(page: string | number): void {
    if (this.isNumber(page)) {
      this.paginate(page);
    }
  }

  getClassForColumn(index: number): string {
    const classes = ['Fecha de Solicitud', 'Producto', 'Cliente','Estado','N° Solicitud','Asesor','Acciones'];
    return classes[index] || '';
  }

  navigateTo(row: any) {
    if (this.navigate) {
      this.navigate(row);
    }
  }
  // navigateTo(row: any) {
  //  this.router.navigate(['bandeja-solicitudes-producto']);
  // }

  onSelectRow(row: any): void {
    this.onEditRow.emit(row);
  }

}
