<div class="modal-header"> 
    <mat-icon>{{ data.icon }}</mat-icon>
  </div>
  <div class="modal-body">
    <h2>{{ data.title }}</h2>
    <h3>{{ data.message }}</h3>
    <p>Cliente: {{ data.cliente }}</p>
    <p>DNI: {{ data.dni }}</p>
  </div>
  <div class="modal-footer">
    <a href="#" mat-dialog-close>Cerrar</a>
  </div>
  