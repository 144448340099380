<table class="custom-table">
  <tr>
    <th class="header-column" *ngFor="let header of tableData.header">{{ header }}</th>
  </tr>
  <tr *ngFor="let row of tableData.rows">
    <td *ngFor="let cell of row">{{ cell }}</td>
  </tr>

  <!-- Fila para los botones, empezando desde la columna después de 'Cuotas' -->
  <tr class="botones-fila">
    <td *ngIf="showDownloadButton">
      <app-custom-button-color  iconUrl="assets/img/cronograma.svg"  text="Descargar Cronograma" (action)="descargarCronograma()"></app-custom-button-color>
    </td>

    <td *ngIf="!showDownloadButton"></td>

    <td *ngFor="let show of showButton; let columnIndex = index">
      <ng-container *ngIf="show">
        <app-custom-button text="Elegir Resultado" (action)="redireccionarAutenticacion(columnIndex + 1)"></app-custom-button>
      </ng-container>
    </td>
  </tr>
</table>
<loading *ngIf="loading"></loading>
