<!-- custom-datepicker.component.html -->
<div class="custom-input-container">
  <input type="date" 
  class="custom-input text-input" 
  [(ngModel)]="value" 
  [disabled]="isDisabled"
  [readonly]="isReadonly"
  (ngModelChange)="onValueChange($event)"
  [ngClass]="{'error': isError, 'readonly': isDisabled}" >
</div>
