
<app-header [headerText]="textoCabecera"  [showImage]="false"></app-header>

<div class="layout-container">

  <div class="formulario-container">
    <div class="subtitle">
      <img src="assets/svg/dot-green.svg" alt="dot" />
      <h2>Completar</h2>
    </div>
  </div>

  <div  class="formulario-container">
      <div class="form-row">
          <div class="form-group">
            <label>Usuario</label>
            <app-custom-input [(value)]="userId" [isError]="isErrorUserId" [isReadonly]="elementsReadOnly.includes('userId')"
            (keyup)="resetErrorState('userId')" [minlength]="valueMinInput" [maxlength]="40"></app-custom-input>
          </div>

          <div class="form-group">
            <label>Contraseña</label>
            <app-custom-input [type]="'password'" [(value)]="password" [isError]="isErrorPassword" [isReadonly]="elementsReadOnly.includes('password')"
            (keyup)="resetErrorState('password')" [minlength]="valueMinInput" [maxlength]="30"></app-custom-input>
            <!-- <app-custom-input-password [(value)]="password" (keyup)="resetErrorState('password')"></app-custom-input-password> -->
          </div>

      </div>
      <div class="form-row">
        <div class="form-group">
          <label>Nombre Completo</label>
          <app-custom-input [(value)]="fullname" [isError]="isErrorFullname" [isReadonly]="elementsReadOnly.includes('fullname')"
          (keyup)="resetErrorState('fullname')" [minlength]="valueMinInput" [maxlength]="60"></app-custom-input>
        </div>
      </div>
  </div>  


  <div  class="formulario-container">
    <div class="form-row">
        <div class="form-group">
          <label>Tipo de Documento</label>
          <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="documents" [isReadonly]="elementsReadOnly.includes('documentType')"
            [(selectedValue)]="documentType"  (selectedValueChange)="documentType=$event" [isError]="isErrorDocumentType">
          </app-custom-inpunt-select>
        </div>

        <div class="form-group">
          <label>N° de Documento</label>
          <app-custom-input [(value)]="documentNumber" [isError]="isErrorDocumentNumber" [isReadonly]="elementsReadOnly.includes('documentNumber')"
          (keyup)="resetErrorState('documentNumber')" [minlength]="valueMinInput" [maxlength]="12"></app-custom-input>
        </div>

    </div>
    <div class="form-row">
      <div class="form-group">
        <label>Usuario IBS</label>
        <app-custom-input [(value)]="userIdCore" [isError]="isErrorUserIdCore" [isReadonly]="elementsReadOnly.includes('userIdCore')"
        (keyup)="resetErrorState('userIdCore')" [maxlength]="30"></app-custom-input>
      </div>
      <div class="form-group">
        <label>Código Vendedor</label>
        <app-custom-input [(value)]="sellerCode" [isError]="isErrorSellerCode" [isReadonly]="elementsReadOnly.includes('sellerCode')"
        (keyup)="resetErrorState('sellerCode')" [maxlength]="30"></app-custom-input>
      </div>
    </div>
</div>  


  <div  class="formulario-container">
    <div class="form-row">
      <div class="form-group">
        <label>Email</label>
        <app-custom-input [(value)]="email" [isError]="isErrorEmail" [isReadonly]="elementsReadOnly.includes('email')"
        (keyup)="resetErrorState('email')" [minlength]="valueMinInput" [maxlength]="100" [validateEmail]="true"></app-custom-input>
      </div>

    </div>
    <div class="form-row">
      <div class="form-group">
        <label>Empresa</label>
        <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="enterprises" [isReadonly]="elementsReadOnly.includes('enterpriseId')"
          [(selectedValue)]="enterpriseId"  (selectedValueChange)="enterpriseId=$event" [isError]="isErrorEnterpriseId">
        </app-custom-inpunt-select>
      </div>
    </div>
    
  </div>  

  

  <div  class="formulario-container">
    <div class="form-row">
        <div class="form-group">
          <label>Rol</label>
          <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="roles" [isReadonly]="elementsReadOnly.includes('roleId')"
            [(selectedValue)]="roleId"  (selectedValueChange)="roleId=$event" [isError]="isErrorRoleId">
          </app-custom-inpunt-select>
        </div>

        <div class="form-group">
          <label>País</label>
          <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="countries" [isReadonly]="elementsReadOnly.includes('countryCode')"
            [(selectedValue)]="countryCode"  (selectedValueChange)="countryCode=$event" [isError]="isErrorCountryCode">
          </app-custom-inpunt-select>
        </div>

    </div>
    <div class="form-row">
      <div class="form-group">
        <label>Estado</label>
        <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="states" [isReadonly]="elementsReadOnly.includes('stateCode')"
          [(selectedValue)]="stateCode"  (selectedValueChange)="stateCode=$event" [isError]="isErrorStateCode">
        </app-custom-inpunt-select>
      </div>

      <div class="form-group">
        <label>Canal de Venta</label>
        <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="salesChanels" [isReadonly]="elementsReadOnly.includes('salesChannelId')"
          [(selectedValue)]="salesChannelId"  (selectedValueChange)="salesChannelId=$event" [isError]="isErrorSalesChannelId">
        </app-custom-inpunt-select>
      </div>      
    </div>
  </div>  

  <div  class="formulario-container">
    <div class="form-row">        
        <div class="form-group">
          <label>Agencia</label>
          <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="agencies" [isReadonly]="elementsReadOnly.includes('agencyCode')"
            [(selectedValue)]="agencyCode"  (selectedValueChange)="agencyCode=$event" [isError]="isErrorAgencyCode">
          </app-custom-inpunt-select>
        </div>
    </div>
  </div>  


<div  class="footer-switch">
   <!-- <app-custom-input-switch></app-custom-input-switch>
   <label>Anular Solicitud - Reporte Solicitud</label> -->
</div>  

<div class="footer-buttons">
  <app-custom-button-color text="CANCELAR" (action)="cancel()"></app-custom-button-color>
  <app-custom-button text="CONFIRMAR"  (action)="save()"></app-custom-button>
</div>

</div>
<app-footer></app-footer>

<loading *ngIf="loading"></loading>