
<app-header [headerText]="'Empresas'"  [showImage]="false"></app-header>

<div class="layout-container">
  <div class="contenedor-filtros">
    <div class="form-group filtro-3">
      <label class="titulo-filtro">Nombre de Empresa</label>
      <app-custom-input [(value)]="companyName"></app-custom-input>
    </div>
    <app-custom-button-color 
      text="limpiar" (action)="clear()"></app-custom-button-color>  
      <app-custom-button
      text="buscar" (action)="seach()"></app-custom-button>
    <div class="filtro-inputs">
      
    </div>
    <div class="filtro-botones">
      
    </div>
  </div>

  <div class="contenedor-filtros">
    <div class="filtro-inputs">
      <div class="resultados">
        <!-- <label for="" class="label-resultado">Resultado de Búsqueda</label> -->
      </div> 
    </div>
    <div class="filtro-botones">
      <div class="btn-add-usuario">
        <app-custom-button text="AGREGAR EMPRESA"  iconUrl="assets/svg/add-square.svg"  (action)="handleCreateCompany()"></app-custom-button>
      </div>
    </div>    
  </div>

  

  <div class="tabla-responsive">
    <app-custom-table-paginator [columnNames]="columnNames" [data]="companies" (onEditRow)="editEnterprise($event)"></app-custom-table-paginator>
  </div>

</div>

<app-footer></app-footer>

<app-company-modal *ngIf="showModal" 
  [titulo]="titleModal"
  (onClose)="handleClose()" 
  (onSave)="sendCommentHandler()"
  (onChangeState)="updateSelectState($event)"
  (onChangeCountry)="updateSelectCountry($event)"
  (onChangeCompanyName)="updateNameEnterprise($event)"
  [isErrorCountry]="isErrorCountry"
  [isErrorState]="isErrorState"
  [isErrorCompanyName]="isErrorCompanyName"
  [companyName]="nameCompanyModel"
  [country]="countrySelect"
  [state]="stateSelect">
</app-company-modal>

<loading *ngIf="loading"></loading>



